import { Configuration, PasswordChangeRequest, UserAccountDomainApi } from "../../../openapi";

const apiBaseUrl = process.env.REACT_APP_API_URL || "";
const token = localStorage.getItem("token");

const configuration = new Configuration({ basePath: apiBaseUrl });
const api = new UserAccountDomainApi(configuration);

export const resetPassword = async (passwordChangeRequest: PasswordChangeRequest) => {
  return await api.userResetPassword(passwordChangeRequest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
