import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { fetchMnaListService } from "./services/mnaListService";
import mnaIcon from "../../assets/svg/blue-mna.svg";
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
import Table from "../Fundamental/components/MnaFundamentalTable";
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../components/Loader";

const MnaList: React.FC<HandleMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const columnsInstance = new Columns();

  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: any) => {
      return await fetchMnaListService(
        pageIndex,
        pageSize,
        sortBy,
        search,
        setLoading
      );
    },
    [search]
  );

  return (
    <React.Fragment>
      <div
        className={`py-[2rem] pr-[2rem] flex flex-col gap-[29px] ${
          isMenuOpen ? "pl-[316px]" : "pl-[92px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["MNAs"]}
          icon={mnaIcon}
          addItem={() => navigate("/add-mna")}
        />
        <Table
          columns={columnsInstance.MnaList(navigate)}
          fetchData={fetchMnaList}
          search={search}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
};

export default MnaList;
