import { AxiosError } from "axios";

// Function redirect you when the token is expired
export function expiredTokenValidation(error: unknown) {
  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.replace("");
    }
  }
}
