import React from "react";
import { GlobalSearchFiltersEnum } from "../../../openapi";
import { FundamentalIcon, MnaIcon, NotesIcon } from "./Icons";

interface Props {
    setFilters: React.Dispatch<React.SetStateAction<Set<GlobalSearchFiltersEnum>>>
    filters: Set<GlobalSearchFiltersEnum>
}

const FilterByDomainsList = ({ setFilters, filters }: Props) => {
    const handlerChange = (value: string) => {
        const newFilters = new Set(filters);

        newFilters.delete(GlobalSearchFiltersEnum.All);
        switch (value) {
            case 'MNA':
                if (newFilters.has(GlobalSearchFiltersEnum.Mna)) {
                    newFilters.delete(GlobalSearchFiltersEnum.Mna);
                } else {
                    newFilters.add(GlobalSearchFiltersEnum.Mna);
                }
                break;
            case 'FUNDAMENTAL':
                if (newFilters.has(GlobalSearchFiltersEnum.Fundamental)) {
                    newFilters.delete(GlobalSearchFiltersEnum.Fundamental);
                } else {
                    newFilters.add(GlobalSearchFiltersEnum.Fundamental);
                }
                break;
            case 'NOTES':
                if (newFilters.has(GlobalSearchFiltersEnum.Notes)) {
                    newFilters.delete(GlobalSearchFiltersEnum.Notes);
                } else {
                    newFilters.add(GlobalSearchFiltersEnum.Notes);
                }
                break;
            default:
                newFilters.clear();
                newFilters.add(GlobalSearchFiltersEnum.All);
        }

        if (newFilters.size === 0) {
            newFilters.add(GlobalSearchFiltersEnum.All);
        }

        setFilters(newFilters);
    }


    const domains = [
        {
            name: 'MNAs',
            value: 'MNA',
            Icon: <MnaIcon />
        },
        {
            name: 'Fundamentals',
            value: 'FUNDAMENTAL',
            Icon: <FundamentalIcon />
        },
        {
            name: 'Notes',
            value:
                'NOTES',
            Icon: <NotesIcon />

        },
    ]

    return (
        <div className={"flex flex-col gap-4 mt-5"}>
            {domains.map((domain) =>
                <div
                    key={`domain-${domain.value}`}
                    className={"flex gap-3 cursor-pointer"}
                    onClick={() => handlerChange(domain.value)}
                >
                    <input
                        type={"checkbox"}
                        value={domain.value}
                        checked={filters.has(domain.value as GlobalSearchFiltersEnum)}
                        onChange={() => { }}
                    />
                    <div className={"flex gap-2"}>
                        {domain.Icon}
                        <p className="text-[12px] text-b-secondary-dark  dark:text-b-secondary-light">
                            {domain.name}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FilterByDomainsList;
