// OpenApi
import {
  ConditionCategoryDomainApi,
  ConditionStatusDomainApi,
  Configuration,
  LinkCategoryDomainApi,
  MnaCategoryDomainApi,
  MnaDomainApi,
  TxDocSummaryTypeDomainApi,
} from "../../../openapi";

// Props
type FetchAllListProps = {
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setConditionCategoryList: (value: any) => void;
  setConditionStatusList: (value: any) => void;
  setMnaCategoryList: (value: any) => void;
  setMnaSubCategoryList: (value: any) => void;
  setLinkCategoryList: (value: any) => void;
  setTxDocSummariesList: (value: any) => void;
};

type FetchMnaDataProps = {
  id: string | undefined;
  fetchFeatures: (inputValue?: string) => Promise<
    | {
        value: any;
        label: any;
      }[]
    | []
  >;
  getFetchCompanyList?: () => Promise<void>;
  formik: any;
  setChangedValues: React.Dispatch<any>;
};

// Api Config
const configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
});

let headers: any = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

configuration.baseOptions = {
  headers: headers,
};

// Function to fetch note source type options
const callFetchAllList = async (props: FetchAllListProps) => {
  const {
    setConditionCategoryList,
    setConditionStatusList,
    setLinkCategoryList,
    setLoading,
    setMnaCategoryList,
    setMnaSubCategoryList,
    setTxDocSummariesList,
  } = props;
  setLoading(true);

  if (configuration?.baseOptions?.headers?.Authorization?.includes("null")) {
    headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    configuration.baseOptions = {
      headers: headers,
    };
  }
  try {
    const mnaCategory = new MnaCategoryDomainApi(configuration);
    const mnaSubCategory = new MnaCategoryDomainApi(configuration);
    const linkCategory = new LinkCategoryDomainApi(configuration);
    const conditionCategory = new ConditionCategoryDomainApi(configuration);
    const conditionStatus = new ConditionStatusDomainApi(configuration);
    const summariesType = new TxDocSummaryTypeDomainApi(configuration);

    const response1 = await mnaCategory.getMnaCategories("", 0, 20, undefined);
    const response2 = await mnaSubCategory.getMnaCategories("", 0, 20, undefined);
    const response3 = await linkCategory.getLinkCategories("", 0, 20, undefined);
    const response4 = await conditionCategory.getConditionCategories("", 0, undefined);
    const response5 = await conditionStatus.getConditionStatuses("", 0, 20, undefined);
    const response6 = await summariesType.getAllTxDocSummaryTypes(0, 20, undefined);

    setConditionCategoryList(
      response4.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setConditionStatusList(
      response5.data.content?.map((item: any) => {
        return { id: item.id, status: item.status };
      })
    );
    setMnaCategoryList(
      response1.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setMnaSubCategoryList(
      response2.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setLinkCategoryList(
      response3.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setTxDocSummariesList(
      response6.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
  } catch (error) {
    console.error(error);
  }
  setLoading(false);
};

// fetchMnaData
const fetchMnaData = async (props: FetchMnaDataProps) => {
  const { fetchFeatures, id, formik, setChangedValues } = props;
  const api = new MnaDomainApi(configuration);
  // const response = await api.getMnaById(Number(id));
     const response = await api.getMnaById(String(id));

  const featuresOptions = await fetchFeatures();
  // const CompanysOptions = await getFetchCompanyList();

  if (response.data) {
    const data: any = response.data;

  const outDates: Event[] = [];
  const brokerClosingDates: Event[] = [];
  const financingDates: Event[] = [];


    data.dateEvents?.forEach((event:any) => {
      if (event.dateEventTypeId === 1) {
         outDates.push(event);
      } else if (event.dateEventTypeId === 2) {
         brokerClosingDates.push(event);
      } else if (event.dateEventTypeId === 3) {
         financingDates.push(event);
      }
    });

    data.outDates = outDates;
    data.brokerClosingDates = brokerClosingDates;
    data.financingDates = financingDates;
    data.tags =
      data.tags?.map((tag: any) => {
        return { value: tag.id, label: tag.tag };
      }) || null;
    if (data.target) data.target = { label: data?.target, value: data?.target, id: data?.target };

    if (data.acquirer) data.acquirer = { label: data?.acquirer, value: data?.acquirer, id: data?.acquirer };
    delete data.category;

    delete data.subCategory;

    delete data.dateCreated;
    delete data.dateModified;

    if (data.conditions?.length) {
      data.conditions = data.conditions.map((obj: any) => {
        delete obj.lastModifiedBy;
        delete obj.dateCreated;
        delete obj.dateModified;
        delete obj.createdBy;
        return obj;
      });
    }

    if (data.features?.length > 0) {
      data.features = data.features.map((element: any) => ({
        ...element,
        name: featuresOptions.find((feature) => feature.value === element.featureId),
      }));
    }

    if (!data.tags?.length) {
      data.tags = [];
    }

    delete data.mnaOutDates;
    delete data.outDate;

    formik.setValues(data);
    setChangedValues(data);
  }
};

export { callFetchAllList, fetchMnaData };
