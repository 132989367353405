// FilesList.tsx
import React from "react";
import moment from "moment";
import DownloadIcon from "../../../assets/svg/download-icon.svg";
import { getFileSize } from "../../../utils";

interface File {
  fileId: string;
  filename: string;
  size: number;
  dateModified: string;
}

interface FilesListProps {
  files: File[];
  onFileDownload: (id: string, filename: string) => void;
}

const FilesList: React.FC<FilesListProps> = ({ files, onFileDownload }) => {
  return (
    <div className="mt-6 border-t border-[#DDDBEE]">
      <div className="px-4 py-6">
        <div className="text-sm font-medium leading-6 text-gray-900 mb-3">
          Files
        </div>
        <ul className="divide-y divide-[#DDDBEE] rounded-md border border-[#DDDBEE]">
          {files?.map((file: any, index: number) => (
            <li
              key={index}
              className="flex items-center justify-between py-4 pl-4 pr-5 leading-6"
            >
              <div className="flex items-center w-full">
                <img src={DownloadIcon} alt="Download File" />
                <div className="ml-4 flex items-center justify-between gap-5 w-full">
                  <div className="float-start">
                    <button
                      className="truncate font-medium w-full"
                      onClick={() =>
                        onFileDownload(
                          file.fileId,
                          file.filename
                        )
                      }
                    >
                      {file.filename}
                    </button>
                  </div>
                  <div className="flex-shrink-0 text-gray-400 w-1/3">
                    {getFileSize(file.size)}
                  </div>
                  <div className="w-1/3">
                    {moment(file?.dateModified).format(
                      "MM/DD/YYYY hh:mm:ss"
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilesList;
