// React
import React, { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import CompanyInformation from "./CompanyInformation";
import PriceTarget from "./PriceTarget";
import CapitalizationSummary from "./CapitalizationSummary";
import ValuationSummary from "./ValuationSummary";
import AttachedFiles from "./AttachedFiles";
import AboutFundamental from "./AboutFundamental";
import TradingData from "./TradingData";
import KeyDates from "./KeyDates";
import Management from "./Management";
import KeyHolders from "./KeyHolders";
import FinancialSummary from "./FinancialSummary";
import Notes from "./Notes";
import Loader from "../../../../components/Loader";
import EditableFieldFundamental from "../../../../components/EditableFields/EditableFieldFundamental";
// Images
import fundamentalIcon from "../../../../assets/svg/blue-fundamental.svg";
// Types
import { HandleMenuProps } from "../../../../interfaces/pages/variedInterfaces";
// Services
import { fundamentalServices } from "../../services/fundamentalServices";
// Hooks
import useFileDownload from "../../../../hooks/useFileDownload";

const FundamentalDetails: React.FC<HandleMenuProps> = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleFileDownload } = useFileDownload();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectorList, setSectorList] = useState<any>([]);

  useEffect(() => {
    fetchSectorList();
  }, []);

  useEffect(() => {
    fetchFundamentalData();
  }, [id]);

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const data = await fundamentalServices.fetchFundamentalData(String(id));
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchSectorList = async () => {
    try {
      const options = await fundamentalServices.fetchSectorList(String(id));
      setSectorList(options);
    } catch (error) {}
  };

  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 dark:bg-black text-title dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              What Matters
            </div>
            <div className="px-3.5 pb-2 ">
              {data.whatMattersId === null ? (
                <EditableFieldFundamental // For Add If whatMattersId is null
                  data={data.whatMatters}
                  fieldtype="textEditor"
                  fieldName="whatMatters"
                  fetchFundamentalData={fetchFundamentalData}
                  whatMatterId={null}
                />
              ) : (
                <EditableFieldFundamental // For Edit If whatMattersId is not null
                  data={data.whatMatters}
                  fieldtype="textEditor"
                  fieldName="whatMatters"
                  fetchFundamentalData={fetchFundamentalData}
                  whatMatterId={data.whatMattersId}
                />
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Business Description",
      content: (
        <div className="border border-[#E4E3ED] rounded-md h-full">
          <div className="px-3.5 pt-4  text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
            Business Description
          </div>
          <div className="px-3.5 pb-2">
            <EditableFieldFundamental
              data={data?.businessDesc}
              fieldtype="textEditor"
              fieldName="businessDesc"
              fetchFundamentalData={fetchFundamentalData}
            />
          </div>
        </div>
      ),
    },
    {
      header: "Company History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 pt-4 text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Company History
            </div>
            <div className="px-3.5 pb-2">
              <EditableFieldFundamental
                data={data?.companyHistory}
                fieldtype="textEditor"
                fieldName="companyHistory"
                fetchFundamentalData={fetchFundamentalData}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Company Basics",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 pt-4 dark:bg-black text-title  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Company Basics
            </div>
            <div className="px-3.5 pb-2">
              <EditableFieldFundamental
                data={data?.companyBasics}
                fieldtype="textEditor"
                fieldName="companyBasics"
                fetchFundamentalData={fetchFundamentalData}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Drivers / Valuation",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 pt-4 text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Drivers / Valuation
            </div>
            <div className="px-3.5 pb-2">
              <EditableFieldFundamental
                data={data?.driversValuation}
                fieldtype="textEditor"
                fieldName="driversValuation"
                fetchFundamentalData={fetchFundamentalData}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Valuation History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md ">
            <div className=" px-3.5 pt-4 dark:bg-black border-[#E5E4FB] text-title  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Valuation History
            </div>
            <div className="px-3.5 pb-2">
              <EditableFieldFundamental
                data={data?.valuationHistory}
                fieldtype="textEditor"
                fieldName="valuationHistory"
                fetchFundamentalData={fetchFundamentalData}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div
        className={`py-[30px] pr-[24px] bg-[#F6F7FA] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Fundamentals", `${data?.name || ""}`]}
          icon={fundamentalIcon}
          updateItem={() => navigate(`/update-fundamental/${id}`)}
          addItem={() => navigate("/add-fundamental")}
        />
        <div className="pt-[24px] rounded-md">
          <div className="flex flex-col md:flex-row gap-4 ">
            <div className="w-full flex flex-col gap-4 h-full">
              {/* Company Information */}
              <CompanyInformation
                fetchFundamentalData={fetchFundamentalData}
                data={data}
                sectorList={sectorList}
              />
              {/* Price Target */}
              <PriceTarget />
              {/* Capitalization Summary */}
              <CapitalizationSummary />
              {/* Valuation Summary */}
              <ValuationSummary />
              {/* Attached Files */}
              <AttachedFiles
                data={data}
                handleFileDownload={handleFileDownload}
              />
              {/* About Fundamental */}
              <AboutFundamental />
            </div>
            <div className="w-full h-full flex flex-col gap-4 dark:bg-primary-dark">
              {/* BUTTONS */}
              <div className="flex gap-3 mb-4 flex-wrap">
                {tabsContent.map((tab, index) => (
                  <button
                    className={`min-w-[130px] h-[36px] px-2 ${
                      activeTab === index && "bg-[#E8F2FD] font-semibold"
                    } text-blue-primary border border-blue-primary  text-sm  rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              {/* <div className="overflow-x-auto mt-[16px]"></div> */}
              {/* CONTENT */}
              <div className="flex flex-col bg-white rounded-md h-full dark:bg-table-dark">
                {tabsContent[activeTab].content}
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                {/* Trading Data */}
                <TradingData />
                {/* Key Dates */}
                <KeyDates />
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                {/* Management */}
                <Management />
                {/* Key Holders */}
                <KeyHolders />
              </div>
              {/* Financial Summary */}
              <FinancialSummary />
            </div>
          </div>
        </div>
        <div className="min-w-full inline-block align-middle bg-table-light mt-4 dark:bg-table-dark">
          {/* NOTES */}
          <Notes data={data} openModal={openModal} />
        </div>
      </div>
    </>
  );
};

export default FundamentalDetails;
