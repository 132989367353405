import React from "react";

const ValuationSummary = () => {
  return (
    <div className="overflow-x-auto">
      <div className="min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <tr>
                <th className="pl-4 pr-3.5 pb-2 pt-4 text-title dark:text-white heading-bold font-normal text-left">
                  Valuation Summary
                </th>
                <th className="px-3.5 pb-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  2023
                </th>
                <th className="px-3.5 pb-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  2024
                </th>
                <th className="px-3.5 pb-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  2025
                </th>
                <th className="pl-3.5 pr-4 pb-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  2026
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pl-4 pr-3.5 py-2 heading-regular-grey">EV / EBITDA:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  13.8x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  8.8x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  13.8x
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  13.8x
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 heading-regular-grey">EV / EBIT:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  16.7x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  6.7x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  1.7x
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  7.5x
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 heading-regular-grey">P / E:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  120.0x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  160.0x
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  180.0x
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  180.0x
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 heading-regular-grey">FCF Yield:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.6%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  7.6%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  10.6%
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  10.9%
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 pt-2 pb-4 heading-regular-grey">Dividend yield:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.0%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.0%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.0%
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.0%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ValuationSummary;
