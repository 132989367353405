// React
import React, { useEffect, useState } from "react";
// Third party
import { Link, useLocation } from "react-router-dom";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
} from "react-table";
import moment from "moment";
// Components
import { Pagination } from "../../../components/Pagination/Pagination";
// Image
import Arrow from "../../../assets/svg/arrow-down-file-list-table.svg";
import SortIconAsc from "../../../assets/svg/sort-icon-asc.svg";
import SortIconDesc from "../../../assets/svg/sort-icon-desc.svg";
// Types
import {
  Cell,
  ColumnTable,
  HeaderGroup,
  RowTable,
} from "../../../interfaces/pages/variedInterfaces";
// Api
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import Loader from "../../../components/Loader";

interface TableProps {
  columns: Column[];
  caption?: string;
  search?: any;
  loading?: boolean;
  additionalDataKey?: string; // Key for the additional data in each row
  openModal?: ((value: any) => void) | undefined;
  alerts?: boolean;

  fetchData: (
    pageIndex: number,
    pageSize: number,
    sortBy: any,
    globalFilter: string
  ) => Promise<any>;
}

const Table: React.FC<TableProps> = ({
  columns,
  caption,
  fetchData,
  search,
  loading,
  additionalDataKey,
  openModal,
  alerts,
}) => {
  const { pathname } = useLocation();
  const [data, setData] = useState<[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);

  const {
    headerGroups,
    page,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
      totalElement: totalElement,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageIndex, pageSize, sortBy } = state;
  const [activeSortColumn, setActiveSortColumn] = useState(null);

  const handleColumnClick = (column: any) => {
    if (
      column.id === "dealSourceCategory" ||
      column.id === "Links" ||
      column.id === "username" ||
      column.id === "requestUri" ||
      column.id === "requestMethod" ||
      column.id === "action"
    ) {
      return;
    } else {
      setActiveSortColumn(column.id);
      handleSortToggle(column);
    }
  };

  useEffect(() => {
    gotoPage(0);
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const result = await fetchData(
          pageIndex,
          pageSize,
          sortBy,
          globalFilter
        );
        setData(result.content);
        setControlledPageCount(Math.ceil(result.page.totalPages));
        setTotalElement(Math.ceil(result.page.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, search]);

  const handleSortToggle = (column: ColumnTable) => {
    const { canSort, toggleSortBy, isSortedDesc } = column;

    if (canSort) {
      toggleSortBy(!isSortedDesc, false);
    }
  };
  return (
    <div>
      {/* Table */}

      <div className="flex flex-col">
        <div className=" overflow-x-auto">
          <div className=" min-w-full inline-block align-middle max-w-full border border-[#E0DFE5] bg-[#FFFFFF] rounded-lg overflow-hidden p-5">
            <div className="border border-[#F0EFF5] rounded-lg overflow-hidden">
              <div className="min-w-full dark:bg-table-dark bg-white">
                {caption && (
                  <div className="text-left heading-bold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                    {caption}
                  </div>
                )}
                {!alerts && (
                  <div>
                    {headerGroups.map((headerGroup: HeaderGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className="flex"
                      >
                        {headerGroup.headers.map(
                          (column: any, index: number) => (
                            <div
                              key={index}
                              {...column.getHeaderProps()}
                              style={{
                                width: `${column.width}%`,
                                textAlign: `${column.align}`,
                                cursor:
                                  column.canSort &&
                                  column.id !== "action" &&
                                  column.id !== "username"
                                    ? "pointer"
                                    : "!text",
                              }}
                              onClick={() => handleColumnClick(column)}
                              className={`text-xs h-[44px] bg-[#FBFBFB] flex items-center justify-start ml-4 font-normal  ${
                                ["/dashboard"].includes(pathname)
                                  ? "bg-table-sub-light dark:bg-black dark:text-white text-primary"
                                  : "bg-white dark:bg-black text-[#191919]"
                              }`}
                            >
                              <div className="flex items-center space-x-2">
                                <span className="text-small">
                                  {column.render("Header")}
                                </span>

                                {column.canSort &&
                                  activeSortColumn === column.id &&
                                  column.id !== "action" &&
                                  column.id !== "username" && (
                                    <img
                                      src={
                                        column.isSortedDesc
                                          ? SortIconDesc
                                          : SortIconAsc
                                      }
                                      alt="sort icon"
                                      className={`w-4 h-4 transition-transform`}
                                    />
                                  )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {loading ? (
                  <Loader loading={loading} />
                ) : (
                  <div
                    className={`mt-[10px] mb-[10px] !text-sm    ${
                      additionalDataKey
                        ? ""
                        : " bg-table-light dark:bg-table-dark"
                    }`}
                    data-testid="table-body"
                  >
                    {data.length > 0
                      ? page.map((row: RowTable, index: number) => {
                          prepareRow(row);
                          return (
                            <React.Fragment key={index}>
                              {row.values?.domainType === "Note" ||
                              pathname.includes("notes") ? (
                                testRow(row, pathname)
                              ) : (
                                <div
                                  {...row.getRowProps()}
                                  key={index}
                                  className="flex w-full !text-sm  "
                                >
                                  {row.cells.map((cell: Cell) => {
                                    return (
                                      <div
                                        {...cell.getCellProps()}
                                        style={{
                                          width: `${cell.column?.width}%`,
                                        }}
                                        className={`flex items-center whitespace-nowrap
                                         truncate   text-[#393C51] h-[32px] ml-4 dark:text-white !text-sm`}
                                      >
                                        {cell.render("Cell")}{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })
                      : !loading && (
                          <div className="text-center">
                            <div className="text-primary font-bold text-sm p-4">
                              No data found
                            </div>
                          </div>
                        )}
                  </div>
                )}
              </div>
            </div>
            {/* Pagination */}
            <div
              className="flex flex-col md:flex-row justify-between mt-6 px-[29px]"
              data-testid="pagination-container"
            >
              <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
                <div className="mb-2 md:mb-0">
                  <span className="text-primary text-lg font-medium border-r border-primary pr-5">
                    Showing {data.length !== 0 && pageIndex * pageSize + 1}{" "}
                    {data.length !== 0 && "-"}{" "}
                    {pageIndex === pageCount - 1
                      ? Math.min((pageIndex + 1) * pageSize, totalElement)
                      : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                    of {totalElement}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="text-primary text-sm font-normal md:ml-5">
                    Show Entries
                  </div>
                  <div className="relative">
                    <select
                      style={{
                        appearance: "none",
                        backgroundColor: "white",
                        paddingRight: "35px",
                        fontSize: "14px",
                        width: "84px",
                        height: "34px",
                        outline: "none",
                        border: "1px solid #E5E5EA",
                        borderRadius: "8px",
                        transition: "border-color 0.3s ease-in-out",
                        backgroundImage: `url(${Arrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "calc(100% - 15px) center",
                        backgroundSize: "20px 20px",
                      }}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border border-[#E5E5EA] rounded-[8px] px-[20px] py-[5px] text-primary   appearance-none outline-none cursor-pointer w-[84px] h-[34px]"
                    >
                      {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center gap-2"
                data-testid="pagination-buttons"
              >
                <Pagination
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                />
              </div>
              <div className="text-secondary flex items-center mt-4 md:mt-0">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    data-testid="previous-button"
                    className={`flex items-center px-3 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
    hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
                  >
                    <span className="mr-1">←</span> Previous
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    data-testid="next-button"
                    className={`flex items-center px-3 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
    hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
                  >
                    Next <span className="ml-1">→</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function testRow(row: RowTable, pathname: string): JSX.Element {
  const rowOriginal = row.original.response
    ? row.original.response
    : row.original;
  const domainsToShow = rowOriginal?.domains?.map((domain: any) => {
    return { name: domain?.name, id: domain?.entityId, typeId: domain.typeId };
  });
  const tagsToShow = rowOriginal.tags?.map((tag: any) => tag.tag);

  return (
    <table className="flex flex-col gap-2">
      <thead className=" mb-3 ">
        <tr className="flex justify-between px-3.5 mb-4 py-3 bg-table-sub-light dark:bg-button-dark dark:text-white">
          <th>{rowOriginal?.title}</th>
          <th>
            {moment(rowOriginal?.datePublished).format("MM/DD/YYYY hh:mm:ss")}
          </th>
        </tr>
        <tr className="flex justify-between px-3.5">
          <th>{rowOriginal?.sourceType}</th>
          <th>{rowOriginal?.sourceDetail}</th>
          <th>{rowOriginal?.author1}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="flex">
          <td className="relative w-full flex">
            <div
              className="pl-7 pr-7 pb-7"
              dangerouslySetInnerHTML={{
                __html: truncateContent(rowOriginal?.content, 80),
              }}
            ></div>
            <Link to={`/notes/${rowOriginal.id}`}>
              <button
                className={`text-danger absolute bottom-2 ${
                  pathname.includes("notes") ? "right-6" : "right-5"
                } `}
              >
                Read more
              </button>
            </Link>
          </td>
          {pathname.includes("notes") && (
            <td>
              <Link to={`/notes/${rowOriginal.id}/edit`}>
                <button className="pr-7">
                  <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="dark:brightness-[3] brightness-0"
                    >
                      <g clipPath="url(#clip0_1000_6286)">
                        <path
                          d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                          fill="#4B49AC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1000_6286">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </button>
              </Link>
            </td>
          )}
        </tr>
      </tbody>
      <tfoot className="flex justify-center w-full pb-3 border-b-[0.3px] border-[#DCDBED]">
        <tr className="flex justify-between w-4/5">
          <td>{tagsToShow?.length > 0 ? tagsToShow.join(", ") : ""}</td>
          <td>
            {domainsToShow?.map((domain: any, index: number) => (
              <Link
                key={`domainToShow-${domain.id}-${index}`}
                to={`/${domain.typeId === 1 ? "mna" : "fundamental"}/${
                  domain.id
                }`}
              >
                {" "}
                {domain?.name}
                {index === domainsToShow?.length - 1 ? "" : ","}{" "}
              </Link>
            ))}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function truncateContent(
  content: string | undefined,
  maxLength: number
): string {
  if (!content) return ""; // if there's not content, returns an empty string

  // divide the content in single words
  const words = content.split(" ");
  // Verify the length of the content is within the max length
  if (words.length > maxLength) {
    //  if it's the case, just return the firsts words
    const truncatedContent = words.slice(0, maxLength).join(" ");

    return `${truncatedContent}... `;
  } else {
    // if not, returns the original content
    return content;
  }
}

export default Table;
