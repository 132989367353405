// React
import React from "react";
interface DeleteTooltipProrps {
  tooltip: boolean;
  setTooltip: (value: any) => void;
  onDeleteItem: () => void;
  top?: boolean;
  className?: string;
}

const DeleteTooltip: React.FC<DeleteTooltipProrps> = ({
  tooltip,
  setTooltip,
  onDeleteItem,
  top,
  className,
}) => {
  return (
    <div
      className={`${className} ${
        tooltip ? "opacity-100" : "opacity-0 hidden"
      } ${
        top
          ? "xl:bottom-full xl:left-1/2  xl:mb-3 xl:-translate-x-1/2"
          : " left-24 -translate-y-1/2 top-1/2 ml-3"
      } shadow-lg absolute z-20 whitespace-nowrap rounded-lg border border-[#C4C1DE] bg-white p-4  text-sm font-semibold text-body-color transition-transform duration-300 `}
    >
      <span
        className={`absolute  -z-10  h-4 w-4  rounded-r-sm border-[#C4C1DE] bg-white   rotate-45  border-b ${
          top
            ? "left-1/2 -translate-x-1/2  rounded-l-sm  border-r -bottom-[8.9px]"
            : "-left-[8.4px] top-1/2  -translate-y-1/2  border-l -bottom-1"
        }`}
      ></span>
      <div className="flex justify-between items-center gap-2 mb-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          className="dark:brightness-[4]"
        >
          <path
            d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
            fill="#1E87F0"
          />
          <path
            d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
            fill="#1E87F0"
          />
        </svg>{" "}
        <p className="text-base  text-[#393C51]  dark:text-white  font-semibold">
          Are you sure you want to delete this item?
        </p>
      </div>
      <div className="flex justify-end items-center space-x-4 mt-10">
        <button
          onClick={() => setTooltip(false)}
          type="button"
          className="px-6 py-1 border border-secondary text-secondary text-base font-medium rounded"
        >
          No
        </button>
        <button
          type="button"
          onClick={onDeleteItem}
          className="px-6 py-1 border border-secondary text-[#fff] bg-secondary text-base font-medium rounded"
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default DeleteTooltip;
