import toast from "react-hot-toast";
import errorIcon from "../../../assets/svg/error-icon.svg";

export const CustomToast = (message: string, type: "error" | "success") => {

  const getStyles = (type: "error" | "success") => {
    const baseStyles = "max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-opacity-5";
    const typeStyles = {
      error: "bg-danger ring-black text-white",
      success: "bg-success ring-green-600 text-white",
    };
    return `${baseStyles} ${typeStyles[type]}`;
  };

  toast.custom(
    (t) => (
      <div className={`${t.visible ? "animate-enter" : "animate-leave"} ${getStyles(type)}`}>
        <div className="flex-1 w-0 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5">
              <img src={errorIcon} alt={type === "error" ? "Error" : "Success"} />
            </div>
            <div className="ml-3 flex-1">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    { duration: 500 }
  );
};
