import React from "react";
import DeleteIcon from "../../assets/svg/delete.svg";

interface ModalProps {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}
const ConditionsDeleteModal: React.FC<ModalProps> = ({
  title,
  onClose,
  onConfirm,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white rounded-[12.27px] w-[253px] h-[177px] ">
        <div className="flex justify-center flex-col mt-[29px] h-[68.8px]">
          <div className=" flex justify-center rounded-full">
            <img src={DeleteIcon} alt={"deleteIcon"} />
          </div>
          <div className="text-center ml-[32.5px] mr-[32.5px] mt-[8px] h-[32px] p-0 overflow-hidden">
            <p
              className="font-medium leading-[15.6px]"
              style={{ fontSize: "13px", margin: 0 }}
            >
              {title}
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="flex justify-center mt-[18.4px] mb-4 gap-[6.13px]">
          <button
            onClick={onClose}
            className="w-[94.44px] h-[31.89px] px-4 py-2 font-extrabold text-[12.27px] bg-[#5EC1F92E] rounded-lg hover:bg-blue-primary text-[#191919] hover:text-[#FFFFFF]"
          >
            No,Cancel
          </button>
          <button
            onClick={onConfirm}
            className="w-[94.44px] h-[31.89px] px-4 py-2 font-extrabold text-[12.27px] bg-[#5EC1F92E] rounded-lg hover:bg-blue-primary text-[#191919] hover:text-[#FFFFFF]"
          >
            Yes,Delete
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConditionsDeleteModal;
