// Components
import { Steps } from "../components/MnaForm";

type Pages = {
  [key in Steps]: { propertyNames: string[] };
};

export function structureFormData(formData: any, activeStep: Steps) {
  const pages: Pages = {
    "0": {
      propertyNames: [
        "target",
        "tickerTarget",
        "sectorTarget",
        "incorpTarget",
        "hqTarget",
        "geoExposureTarget",
        "terminationFeeTarget",
        "financialAdvisorForTarget",
        "legalCounselForTarget",
        "acquirer",
        "tickerAcquirer",
        "sectorAcquirer",
        "incorpAcquirer",
        "hqAcquirer",
        "geoExposureAcquirer",
        "terminationFeeAcquirer",
        "financialAdvisorForAcquirer",
        "legalCounselForAcquirer",
      ],
    },
    "1": {
      propertyNames: [
        "name",
        "dateAnnounced",
        "categoryId",
        "subCategoryId",
        "description",
        "competitors",
        "customers",
        "tags",
      ],
    },
    "2": {
      propertyNames: ["whatMatters"],
    },
    "3": {
      propertyNames: [
        "terms",
        "size",
        "premium",
        "closingLanguage",
        "financingDates",
        "appraisalRights",
        "specificPerformance",
        "brokerClosingDates",
        "requiredToLitigate",
        "requiredToLitigateComment",
        "outDates",
      ],
    },
    "4": {
      propertyNames: ["features"],
    },
    "5": {
      propertyNames: ["links"],
    },
    "6": {
      propertyNames: ["conditions"],
    },
    "7": {
      propertyNames: ["txDocSummaries"],
    },
  };

  const activeStepId = activeStep as Steps;
  const activePage = pages[activeStepId];

  if (!activePage) {
    throw new Error("Paso activo no válido.");
  }

  const pageData: any = {};

  activePage.propertyNames.forEach((propertyName: any) => {
    pageData[propertyName] = formData[propertyName];
  });

  return {
    data: pageData,
  };
}
