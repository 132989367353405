import React from "react";

interface CancelButtonProps {
  onClick: () => void;
}

const CancelButtonMna: React.FC<CancelButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-[#5EC1F92E] rounded-md h-12 flex items-center justify-center w-1/6 font-extrabold text-sm"
    >
      Cancel
    </button>
  );
};

export default CancelButtonMna;
