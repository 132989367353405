// Updated NotesList.tsx
// React
import React, { useState, useEffect, useCallback } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table from "../../components/Tables/NoteTable";
// openAPI
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Images
import NoteIcon from "../../assets/svg/blue-note.svg";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
import { NoteServices } from "./services/noteServices";
import ContentModal from "./components/ContentModal";
import Loader from "../../components/Loader";

const NotesList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<any>("datePublished");
  const [content, setContent] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>({
    label: "Date Published",
    value: "datePublished",
  });

  const options = [
    { label: "Date Published", value: "datePublished" },
    { label: "Date Created", value: "dateCreated" },
    { label: "Date Modified", value: "dateModified" },
  ];

  const handleItemClick = (option: any) => {
    setSelectedOption(option);
    setSort(option.value);
  };

  const columnsInstance = new Columns();

  const fetchNoteList = useCallback(async (pageIndex: number, pageSize: number) => {
      setLoading(true);
      try {
          const sortBy = [
              {
                  id: sort,
                  desc: true,
              },
          ] as any;
  
          return await NoteServices(search, pageIndex, pageSize, sortBy);
      } catch (error) {
          expiredTokenValidation(error);
      } finally {
          setLoading(false);
      }
  }, [sort, search]); 
  
  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />} 
      <div
        className={`py-2.5 pr-2.5 bg-transparent ${
          isMenuOpen ? "pl-[296px]" : "pl-[70px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Notes"]}
          icon={NoteIcon}
          addItem={() => navigate("/notes/add")}
          isSort={true}
          options={options}
          selectedOption={selectedOption}
          handleItemClick={handleItemClick}
        />
        <div className="mt-2.5">
          <Table
            columns={columnsInstance.NotesList(navigate)}
            fetchData={fetchNoteList}
            additionalDataKey="content"
            openModal={openModal}
            loading={loading}
          />
        </div>
      </div>
      <ContentModal
        isOpen={isModalOpen}
        content={content}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default NotesList;
