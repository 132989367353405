// OpenAPI
import {apiConfig} from "../../../components/ConfigurationApi/Configuration";
import {DashboardRestControllerApi, DateEventDomainApi} from "../../../openapi";
import {expiredTokenValidation} from "../../../api/expiredTokenValidation";

export const dashboardServices = {
    dashboardList: async () => {
        const config = apiConfig();
        try {
            const api = new DashboardRestControllerApi(config);
            const response = await api.getDashboard({
                headers: config.baseOptions.headers,
            });
            return response.data;
        } catch (error) {
            expiredTokenValidation(error);
            throw error;
        }
    },
    upcomingDateEvents: async () => {
        const config = apiConfig();
        try {
            const api = new DateEventDomainApi(config);
            const response = await api.getUpcomingDateEvents1({
                headers: config.baseOptions.header,
                params: {
                    size: 15,
                    page: 0,
                },
            });
            return response.data;
        } catch (error) {
            expiredTokenValidation(error);
            throw error;
        }
    },
};
