import React, {useEffect, useState} from 'react'
import EditableFieldMna from '../../../components/EditableFields/EditableField'
import moment from 'moment'
import {fetchCategorylist} from "../../MnaAddEditMiniForm/services/MiniFormServices";

interface MnaBasicsProps {
    data: any;
    fetchMnaData: () => void;
    setData: React.Dispatch<React.SetStateAction<any>>;
    setFetchRequired: React.Dispatch<React.SetStateAction<any>>;
}


export default function MnaBasics({data, fetchMnaData, setData, setFetchRequired}: MnaBasicsProps) {

  const [categoryList, setCategoryList] = useState<any>([]);
  useEffect(() => {
    fetchCategorylist(setCategoryList);
  }, []);


  const handleFetchMnaData = async () => {
        fetchMnaData();
    }
  return (
    <div className="">
    <div className="min-w-full inline-block align-middle">
      <div className="overflow-hidden px-4 pb-2 pt-4">
        <table className="min-w-full dark:bg-table-dark bg-table-light rounded-md">
          <caption className="text-left heading-bold text-title pr-4 bg-table-light rounded-md dark:bg-table-dark">
            MNA Basics
          </caption>
          <thead>
            <tr>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white ">
            <tr>
              <td className="pr-3.5 pb-2.5 pt-2.5 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
                Date Announced:
              </td>
              <td className=" h-full pl-2">
                <div className="flex flex-row">
                  <div className="flex w-full justify-start">
                    <div className="w-fit">
                      <EditableFieldMna
                        data={
                          data?.dateAnnounced
                            ? moment(data.dateAnnounced).isValid()
                              ? moment(data.dateAnnounced).format(
                                  "MM/DD/YYYY"
                                )
                              : ""
                            : ""
                        }
                        fieldtype="date"
                        fullData={data}
                        dateItemValue={data.dateAnnounced}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fieldName="dateAnnounced"
                        fetchMnaData={handleFetchMnaData}
                    />
                  </div>
                </div>
              </div>
            </td>
            <td className="w-[35%] h-full"></td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2.5 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
              Date Closing:
            </td>
            <td className=" h-full pl-2">
              <div className="flex flex-row">
                <div className="flex w-full justify-start">
                  <div className="w-fit">
                    <EditableFieldMna
                        data={
                          data?.dateClosing !== null && data?.dateClosing !== undefined
                              ? moment(data.dateClosing).isValid()
                                  ? moment(data.dateClosing).format("MM/DD/YYYY")
                                  : ""
                              : ""
                        }
                        fieldtype="date"
                        fullData={data}
                        dateItemValue={data?.dateClosing ?? ""}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fieldName="dateClosing"
                        fetchMnaData={handleFetchMnaData}
                    />
                  </div>
                </div>
              </div>
            </td>
            <td className="w-[35%] h-full"></td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Terms:
            </td>
            <td className="w-[60%] h-full ">
              <EditableFieldMna
                  data={data?.terms}
                  fieldtype="text"
                  fieldName="terms"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              MNA value:
            </td>
            <td className="w-[60%] h-full ">
              <EditableFieldMna
                  data={data?.size}
                  fieldtype="text"
                  fieldName="size"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Premium:
            </td>
            <td className="w-[60%] h-full ">
              <EditableFieldMna
                  data={data?.premium}
                  fieldtype="text"
                  fieldName="premium"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Closing Language:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.closingLanguage}
                  fieldtype="text"
                  fieldName="closingLanguage"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
              Termination Fee:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.terminationFeeTarget}
                  fieldtype="text"
                  fieldName="terminationFeeTarget"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
              Reverse Termination Fee:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.terminationFeeAcquirer}
                  fieldtype="text"
                  fieldName="terminationFeeAcquirer"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
              Required to Litigate:
            </td>
            <td className="w-[60%] h-full ">
              <EditableFieldMna
                  data={data?.requiredToLitigate}
                  fieldtype="booleanSelect"
                  fieldName="requiredToLitigate"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-normal w-[40%] h-full">
              Category:
            </td>
            <td className="w-[60%] h-full ">
              <EditableFieldMna

                  data={data?.categoryName}
                  fieldtype="select"
                  fieldName="categoryId"
                  sectorList={categoryList}
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Required to Litigate Comment:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.requiredToLitigateComment}
                  fieldtype="text"
                  fieldName="requiredToLitigateComment"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>

          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Appraisal Rights:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.appraisalRights}
                  fieldtype="text"
                  fieldName="appraisalRights"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          <tr>
            <td className="pr-3.5 pb-2.5 pt-2 text-[#696868] dark:text-white text-sm font-norma w-[40%] h-full">
              Specific Performance:
            </td>
            <td className="w-[60%] h-full">
              <EditableFieldMna
                  data={data?.specificPerformance}
                  fieldtype="text"
                  fieldName="specificPerformance"
                  fetchMnaData={handleFetchMnaData}
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}
