// React
import React, { useEffect, useState } from "react";
// Third party
import { Link, useLocation, useParams } from "react-router-dom";
import { useTable, usePagination, useSortBy, useGlobalFilter, Column } from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Types
import { Cell, ColumnTable, HeaderGroup, RowTable } from "../../interfaces/pages/variedInterfaces";
// Images
import Arrow from "../../assets/svg/arrow-down-file-list-table.svg";
import {Pagination} from "../Pagination/Pagination";


interface FundamentalData {
  id: string;
  sourceType: string;
  sourceTypeId: number;
  sourceDetail: string;
  datePublished: string;
  title: string;
  content: string;
  sentiment: number;
  author1: string;
  author2: string;
  author3: string;
}

interface TableProps {
  columns: Column[];
  caption?: string;
  additionalDataKey?: string;
  openModal?: (value: any) => void;
  fetchData?: (pageIndex: number, pageSize: number, sortBy: any, globalFilter: string) => Promise<any>;
  dataFundamental?: FundamentalData[];
}


const Table: React.FC<TableProps> = ({
  columns,
  caption,
  fetchData,
  additionalDataKey,
  dataFundamental
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [inputPage, setInputPage] = useState<number | string>("");
  const [totalElement, setTotalElement] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, pageSize: 10, sortBy: pathname === `/mna/${id}` ? [{ "id": "dateModified", "desc": true }] : []
      },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        if (fetchData) {
          const result = await fetchData(pageIndex, pageSize, sortBy, globalFilter);
          setData(result.content);
          setControlledPageCount(Math.ceil(result.page.totalPages));
          setTotalElement(result.page.totalElements);
        } else if (dataFundamental) {
          setData(dataFundamental);
          setControlledPageCount(1);
          setTotalElement(dataFundamental.length);
        }
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, dataFundamental]);

  const handleSortToggle = (column: ColumnTable) => {
    const { canSort, toggleSortBy, isSortedDesc } = column;

    if (canSort) {
      toggleSortBy(!isSortedDesc, false);
    }
  };

  return (
    <div>
      {/* Table */}
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
              <table className="min-w-full dark:bg-primary-dark" {...getTableProps()}>
                {caption && (
                  <caption className="text-left heading-bold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px] mb-5">
                    {caption}
                  </caption>
                )}
                <thead
                  className={`${"/dashboard" === pathname
                    ? "bg-table-sub-light dark:bg-black dark:text-white"
                    : pathname === `/mna/${id}`
                      ? "bg-table-sub-light"
                      : "bg-primary-default dark:bg-black"
                    }`}
                >
                  {headerGroups.map((headerGroup: HeaderGroup, index: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column: any, index: number) => (
                        <th
                          key={index}
                          onClick={() => handleSortToggle(column)}
                          scope="col"
                          className={`px-[29px] py-3 text-start font-semibold text-lg ${"/dashboard" === pathname
                            ? "bg-table-sub-light dark:bg-black dark:text-white text-primary py-3"
                            : pathname === `/mna/${id}`
                              ? "bg-table-sub-light text-sub-table-light dark:bg-black dark:text-white"
                              : "bg-primary-default dark:bg-black text-[#fff] py-[19px]"
                            }`}
                        >
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : null}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {data.length > 0 ? (
                    page.map((row: RowTable, index: number) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={index}>
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: Cell) => (
                              <td
                                {...cell.getCellProps()}
                                className="align-top px-[30px] py-[17px] text-[#393C51] dark:text-white font-normal text-base"
                              >
                                {cell.render("Cell")}{" "}
                              </td>
                            ))}
                          </tr>
                          {additionalDataKey && (
                            <tr
                              key={`additional-${index}`}
                              className={`${index === page.length - 1 ? "" : "border-b border-[#D3D3E6] "}`}
                            >
                              <td
                                colSpan={columns.length}
                                className="px-[30px] text-[#696868] dark:text-white font-normal text-base truncate"
                              >
                                <Link to={`/notes/${row.original.id}`}>
                                  <button
                                    type="button"
                                    className="text-secondary pb-[26px] font-bold underline float-right dark:text-white"
                                  >
                                    Read more
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={columns?.length} className="text-primary font-bold text-base p-4">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="flex flex-col md:flex-row justify-between mt-6 px-[29px]"
                 data-testid="pagination-container">
              <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
                <div className="mb-2 md:mb-0">
                  <span className="text-primary text-lg font-medium border-r border-primary pr-5">
                    Showing {data.length !== 0 && pageIndex * pageSize + 1} {data.length !== 0 && "-"}{" "}
                    {pageIndex === pageCount - 1
                        ? Math.min((pageIndex + 1) * pageSize, totalElement)
                        : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                    of {totalElement}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="text-primary text-sm font-normal md:ml-5">Items per Page</div>
                  <div className="relative">
                    <select
                        style={{
                          appearance: "none",
                          backgroundColor: "white",
                          paddingRight: "35px",
                          fontSize: "14px",
                          width: "84px",
                          height: "34px",
                          outline: "none",
                          border: '1px solid #E5E5EA',
                          borderRadius: "8px",
                          transition: "border-color 0.3s ease-in-out",
                          backgroundImage: `url(${Arrow})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "calc(100% - 15px) center",
                          backgroundSize: "20px 20px",
                        }}
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                        className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border  border-[#E5E5EA] rounded-[8px] px-[20px] py-[5px]   text-primary   appearance-none outline-none cursor-pointer w-[84px] h-[34px]"
                    >
                      {[10, 20, 30, 40, 50].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center" data-testid="pagination-buttons">
                <Pagination pageCount={pageCount} pageIndex={pageIndex} gotoPage={gotoPage}/>
              </div>
              <div className="text-secondary flex items-center mt-4 md:mt-0">
                <div className="flex items-center space-x-4">
                  <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                      data-testid="previous-button"
                      className={`flex items-center px-3 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
    hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
                  >
                    <span className="mr-1">←</span> Previous
                  </button>
                  <button
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                      data-testid="next-button"
                      className={`flex items-center px-3 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
    hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
                  >
                    Next <span className="ml-1">→</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
