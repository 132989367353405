import React, { useState } from "react";
import { format } from "date-fns";

interface Props {
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  dateFilter: string;
}

const lastUpdatedButtons = [
  { name: "Today", value: "Today" },
  { name: "Yesterday", value: "Yesterday" },
  { name: "Past 7 Days", value: "7 days" },
  { name: "Past Year", value: "Past Year" },
  { name: "Any Time", value: "" },
];

const calculateDate = (value: string): string => {
  const today = new Date();

  switch (value) {
    case "Today":
      return format(today, "yyyy-MM-dd");
    case "Yesterday":
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return format(yesterday, "yyyy-MM-dd");
    case "7 days":
      const pastWeek = new Date(today);
      pastWeek.setDate(today.getDate() - 7);
      return format(pastWeek, "yyyy-MM-dd");
    case "Past Year":
      const pastYear = new Date(today);
      pastYear.setFullYear(today.getFullYear() - 1);
      return format(pastYear, "yyyy-MM-dd");
    default:
      return "";
  }
};

const LastUpdatedButtonsList = ({ setDateFilter, dateFilter }: Props) => {
  const [date, setDate] = useState("");
  const handleChange = (value: string) => {
    const formattedDate = calculateDate(value);
    setDate(value);
    setDateFilter(formattedDate);
  };

  return (
    <div className={"flex flex-wrap gap-3 mt-4"}>
      {lastUpdatedButtons.map((button) => (
        <button
          onClick={() => handleChange(button.value)}
          key={`updated-button-${button.value}`}
          className={`${
            date === button.value
              ? "bg-[#001DFF26] text-[#3248F2] dark:text-white dark:bg-[#ffffff26]"
              : "text-black"
          } rounded-md py-2 px-4 bg-[#F5F5F5]  text-[12px]`}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
};

export default LastUpdatedButtonsList;
