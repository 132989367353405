import React from "react";

const PriceTarget = () => {
  return (
    <div className="overflow-x-auto">
      <div className="min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 pt-4 text-title dark:text-white heading-bold text-left font-normal">
                  Price Target
                </th>
                <th className="px-4 py-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  Downside
                </th>
                <th className="px-4 py-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  Base
                </th>
                <th className="px-4 py-2 pt-4 text-title dark:text-white text-base font-normal text-left">
                  Upside
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 heading-regular-grey">Simple Target:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 heading-regular-grey">Dividend/Other:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 heading-regular-grey">Total Return:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 heading-regular-grey">Nominal % Return:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  -10.00%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  -16.00%
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  -180.00%
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 heading-regular-grey">Probability:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-[#696868] dark:text-white text-base font-normal">
                  Risk/Reward:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-[#696868] dark:text-white text-base font-normal">
                  Annualized Return:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pl-3.5 pr-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PriceTarget;
