import React from "react";

interface InputFieldProps {
  id: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  error?: string;
  placeholder: string;
  type?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  type = "text",
}) => {
  return (
    <div
      className={` rounded-md overflow-hidden h-12 ${
        error && "border border-[#ff6464]"
      }`}
    >
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="dark:text-white dark:placeholder:text-white dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12 outline-none"
        placeholder={placeholder}
        autoComplete="off"
      />
      
    </div>
  );
};

export default InputField;
