import { NoteDomainApi, NoteSourceTypeDomainApi } from "../../../openapi";
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";

const noteApi = new NoteDomainApi(apiConfig());

export const fetchNoteByIdApi = async (id: string) => {
  try {
    const response = await noteApi.getNoteById(id);
    return response;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const createNoteApi = async (data: any) => {
  try {
    const response = await noteApi.createNote(data);
    return response;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const updateNoteApi = async (id: string, data: any) => {
  try {
    await noteApi.updateNote(id, data);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const fetchNoteSourceTypeApi = async () => {
  try {
    const noteSourceTypeApi = new NoteSourceTypeDomainApi(apiConfig());
    const response = await noteSourceTypeApi.getNoteSourceType();
    return response;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};