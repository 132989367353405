import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { Sort } from "../../../interfaces/pages/variedInterfaces";
import { RegulatoryDomainApi } from "../../../openapi";


export const fetchRegulatoryListService = async (
  pageIndex: number,
  pageSize: number,
  sortBy: Sort[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  try {
    const api = new RegulatoryDomainApi(apiConfig());
    const sort: any = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const response = await api.getRegulatory(pageIndex, pageSize, sort);
    return response.data;
  } catch (error) {
    expiredTokenValidation(error);
  } finally {
    setLoading(false);
  }
};