import React from 'react'
import EditableField from '../../../components/EditableFields/EditableField'

interface RegulatoryInformationProps {
    data: any
    fetchRegulatoryData:any;
}
export default function RegulatoryInformation({data, fetchRegulatoryData} : RegulatoryInformationProps) {
  return (
    <table className="min-w-full dark:bg-primary-dark">
    <caption className="text-left text-[18px] font-semibold  dark:bg-black text-title p-4">
      Regulatory Information
    </caption>
    <tbody className="">
      <tr>
        <td className="w-1/2 px-4 text-[#696868] dark:text-white   text-base font-normal">
          <p>Description:</p>
        </td>
        <td className="w-1/2">
          <div className="w-full px-4">
            <EditableField
              data={data?.description}
              fieldtype="text"
              fieldName="description"
              fetchMnaData={fetchRegulatoryData}
              isRegulatory={true}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className="w-1/2 px-4  text-[#696868] dark:text-white   text-base font-normal">
          Purpose:
        </td>
        <td className="w-1/2">
          <div className="w-full px-4 ">
            <EditableField
              data={data?.purpose}
              fieldtype="text"
              fieldName="purpose"
              fetchMnaData={fetchRegulatoryData}
              isRegulatory={true}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className="w-1/2 px-4  text-[#696868] dark:text-white   text-base font-normal">
          Mandate:
        </td>
        <td className="w-1/2">
          <div className="w-full px-4 ">
            <EditableField
              data={data?.mandate}
              fieldtype="text"
              fieldName="mandate"
              fetchMnaData={fetchRegulatoryData}
              isRegulatory={true}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className="w-1/2 px-4  text-[#696868] dark:text-white   text-base font-normal">
          Timing:
        </td>
        <td className="w-1/2">
          <div className="w-full px-4 ">
            <EditableField
              data={data?.timing}
              fieldtype="text"
              fieldName="timing"
              fetchMnaData={fetchRegulatoryData}
              isRegulatory={true}
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  )
}
