export const msToMinutes = (ms: number) => Math.round(ms / 60000000);

export const toJSON = (value: string | undefined) => {
  if (!value) return;
  try {
    const data = value.replace(/data:/, "");
    return JSON.parse(data);
  } catch (err) {
    console.error("Error parsing response:", err);
  }
};
