import React from "react";
import moment from "moment/moment";
import EditableFieldFundamental from "../../../../components/EditableFields/EditableFieldFundamental";

interface CompanyInformationProps {
  data: any;
  sectorList: any[];
  fetchFundamentalData: () => Promise<void>;
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  data,
  sectorList,
  fetchFundamentalData,
}) => {
  return (
    <div>
      <div className=" min-w-full inline-block align-middle">
        <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
          <table className="min-w-full dark:bg-primary-dark bg-white ">
            <caption className="text-left heading-bold text-title dark:bg-black bg-white pb-2 pt-4 px-4">
              Company Information
            </caption>
            <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white">
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Ticker:
                </td>
                <td className="px-4  text-[#000817] dark:text-white text-base font-medium text-left">
                  <EditableFieldFundamental
                    data={data?.ticker}
                    fieldtype="text"
                    fieldName="ticker"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Sector:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  <EditableFieldFundamental
                    data={data?.sectorId}
                    fieldtype="select"
                    fieldName="sectorId"
                    sectorList={sectorList}
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Incorporation:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  <EditableFieldFundamental
                    data={data?.incorp}
                    fieldtype="text"
                    fieldName="incorp"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Headquarters:
                </td>
                <td className="px-4 text-[#000817] dark:text-white   text-base font-medium text-left w-[37.5%] h-full">
                  <EditableFieldFundamental
                    data={data?.hq}
                    fieldtype="text"
                    fieldName="hq"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Geo Exposure:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  <EditableFieldFundamental
                    data={data?.geoExposure}
                    fieldtype="text"
                    fieldName="geoExposure"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  heading-regular-grey">
                  Date IPO:
                </td>
                <td
                  className={`px-[${
                    data?.dateIpo ? "24px" : "16px"
                  }] text-[#000817] dark:text-white text-base font-medium text-left w-[37.5%] h-full`}
                >
                  <EditableFieldFundamental
                    data={
                      data?.dateIpo
                        ? moment(data?.dateIpo).format("MM/DD/YYYY")
                        : ""
                    }
                    fieldtype="date"
                    fieldName="dateIpo"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2   heading-regular-grey h-full">
                  Address:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left w-[37.5%] h-full">
                  <EditableFieldFundamental
                    data={data?.address}
                    fieldtype="text"
                    fieldName="address"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr className="mt-5">
                <td className="px-4 pt-2 pb-4 heading-regular-grey">
                  {data?.description}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
