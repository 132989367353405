// function to convert images to base 64
export const convertImagesToBase64 = async (content: string) => {
  const doc = new DOMParser().parseFromString(content, "text/html");
  const imgElements = Array.from(doc.querySelectorAll("img"));

  for (const imgElement of imgElements) {
    const imageUrl = imgElement.getAttribute("src");

    if (imageUrl) {
      try {
        const response = await fetch(imageUrl, { mode: "no-cors" });
        const blob = await response.blob();
        const base64: string = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        if (!response.url) return doc.body.innerHTML;

        imgElement.setAttribute("src", base64);
      } catch (error) {
        console.error(`Error converting image to base64: ${error}`);
      }
    }
  }

  return doc.body.innerHTML;
};
