import React from "react";
import EditableFieldMna from "../../../components/EditableFields/EditableField";

interface CompanyInformationProp {
  data: any;
  fetchMnaData: () => void;
  sectorList: any;
}
export default function CompanyInformation({
  data,
  fetchMnaData,
  sectorList,
}: CompanyInformationProp) {
  const handleFetchMnaData = async () => {
    fetchMnaData();
  };
  return (
    <div className=" min-w-full inline-block align-middle">
      <div className="border border-[#DCDBEE] rounded-md overflow-visible bg-table-light dark:bg-table-dark pb-[8px] pr-2">
        <table className="min-w-full  dark:bg-table-dark bg-table-light">
          <caption className="text-left heading-bold dark:bg-table-dark text-title pt-4 pb-2 px-4 ">
            Company Information
          </caption>
          <thead className="bg-table-light dark:bg-table-dark dark:text-white">
            <tr></tr>
          </thead>
          <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white ">
            <tr>
              <td className="px-3.5 pb-2.5 pt-2  text-[#696868] dark:text-white   text-sm font-norma w-[25%] h-full">
                Ticker:
              </td>
              <td className="w-[37.5%] h-full ">
                <p className="pl-2"> {data?.tickerTarget}</p>
              </td>
              <td className="w-[37.5%] h-full">
                <p className="pl-2"> {data?.tickerAcquirer}</p>
              </td>
            </tr>
            <tr>
              <td className="px-4 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Name:
              </td>
              <td className="w-[37.5%] h-full ">
                <EditableFieldMna
                  data={data?.target}
                  fieldtype="text"
                  fieldName="target"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.acquirer}
                  fieldtype="text"
                  fieldName="acquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Sector:
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.sectorTarget}
                  fieldtype="select"
                  fieldName="sectorTarget"
                  sectorList={sectorList}
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.sectorAcquirer}
                  fieldtype="select"
                  fieldName="sectorAcquirer"
                  sectorList={sectorList}
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Incorp:
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.incorpTarget}
                  fieldtype="text"
                  fieldName="incorpTarget"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.incorpAcquirer}
                  fieldtype="text"
                  fieldName="incorpAcquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                HQ:
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.hqTarget}
                  fieldtype="text"
                  fieldName="hqTarget"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.hqAcquirer}
                  fieldtype="text"
                  fieldName="hqAcquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Geographic Exposure:
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.geoExposureTarget}
                  fieldtype="text"
                  fieldName="geoExposureTarget"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.geoExposureAcquirer}
                  fieldtype="text"
                  fieldName="geoExposureAcquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Financial Advisor:
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.financialAdvisorForTarget}
                  fieldtype="text"
                  fieldName="financialAdvisorForTarget"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                <EditableFieldMna
                  data={data?.financialAdvisorForAcquirer}
                  fieldtype="text"
                  fieldName="financialAdvisorForAcquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 text-[#696868] dark:text-white text-sm font-normal w-[25%] h-full">
                Legal Counsel:
              </td>
              <td className="w-[37.5%] h-full">
                {" "}
                <EditableFieldMna
                  data={data?.legalCounselForTarget}
                  fieldtype="text"
                  fieldName="legalCounselForTarget"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
              <td className="w-[37.5%] h-full">
                {" "}
                <EditableFieldMna
                  data={data?.legalCounselForAcquirer}
                  fieldtype="text"
                  fieldName="legalCounselForAcquirer"
                  fetchMnaData={handleFetchMnaData}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
