type CustomSuccessModalProps = {
  visible: boolean;
  id?: string;
  message?:string
};

type CustomErrorModalProps = {
  visible: boolean;
  error?: any;
};

export function CustomSuccessModal({ visible, id , message }: CustomSuccessModalProps) {
  return (
    <div
      className={`${
        visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-success shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48" className="dark:brightness-[4]">
              <path
                fill="#c8e6c9"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              ></path>
              <path
                fill="#4caf50"
                d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
              ></path>
            </svg>
          </div>
          <div className="ml-3 flex-1 text-white">
            <p>{message ? message :`Record ${id ? "update" : "insert"} successfully`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CustomErrorModal({ visible, error }: CustomErrorModalProps) {
  return (
    <div
      className={`${
        visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48" className="dark:brightness-[4]">
              <path
                fill="#F78F8F"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              ></path>
              <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
              <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
            </svg>
          </div>
          <div className="ml-3 flex-1 text-white">
            <p>{error?.response?.data?.exception}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
