// Third party
import { Navigate, useLocation } from "react-router-dom";
// Components
import Navbar from "../layouts/Navbar";
import VersionModal from "../components/VersionModel";
// Theme
import { useTheme } from "../App";
// Package
import packageJson from "../../package.json";
import React from "react";
// Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";


interface PrivateAuthenticatedProps {
  children: React.ReactNode;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrivateAuthenticated: React.FC<PrivateAuthenticatedProps> = ({ children, isMenuOpen, setIsMenuOpen }) => {
  const location = useLocation();
  const version = packageJson.version;
  const token = localStorage.getItem("token");
  const theme = useTheme();


  if (!token) {
    localStorage.setItem("redirectUrl", location.pathname);
    if (location.pathname === "/error" || location.pathname === "/data-migration") {
      localStorage.clear();
    }
    return <Navigate to="/" replace={true} />;
  }
  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement<HandleMenuProps>(child)) {
      return React.cloneElement(child, { isMenuOpen, setIsMenuOpen });
    }
    return child;
  });

  return (
    <div className={`${theme.theme}`}>
      <div className={`min-h-screen dark:bg-primary-dark dark:text-white`}>
        {version !== "0.1.0" && <VersionModal  isMenuOpen={isMenuOpen}/>}
        <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <div className="relative">
          <div className="absolute top-0 w-full">{clonedChildren}</div>
        </div>
      </div>
    </div>
  );
};

const PublicAuthenticated = ({ children }: any) => {
  const token = localStorage.getItem("token");
  if (token) return <Navigate to="/dashboard" />;
  return children;
};

export { PrivateAuthenticated, PublicAuthenticated };
