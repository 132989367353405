import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { PasswordChangeRequest } from "../../openapi";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";
import { resetPassword } from "./services/resetpasswordServices";
import PasswordInput from "./components/PasswordInput";

type PasswordField = "currentPassword" | "newPassword" | "confirmPassword";

const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

const PasswordReset: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const [passwordTypes, setPasswordTypes] = useState<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    currentPassword: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const handleToggle = (field: PasswordField) => {
    setPasswordTypes((prevTypes) => ({
      ...prevTypes,
      [field]: prevTypes[field] === "password" ? "text" : "password",
    }));
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setErrors("");

        await resetPassword({
          currentPassword: values.currentPassword,
          password: values.newPassword,
          passwordConfirm: values.confirmPassword,
        } as PasswordChangeRequest);

        toast.success(`Password updated successfully!`);
        formik.resetForm();
      } catch (error: any) {
        setErrors(
          toast.error(
            error.response?.data?.message ||
              "An error occurred. Please try again."
          )
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCancel = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  const fieldLabels: { [key in PasswordField]: string } = {
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
  };

  return (
    <React.Fragment>
      <div
        className={`py-[2rem] pr-[2rem] bg-[#E7E7E780] h-[calc(100vh-70px)] ${
          isMenuOpen ? "pl-[316px]" : "pl-[92px]"
        } duration-500`}
      >
        <Breadcrumbs breadcrumbs={["Password Reset"]} />
        <div className="w-full bg-white px-[26px] py-[33px] flex flex-col gap-5">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
            {/* {["currentPassword", "newPassword", "confirmPassword"].map(
              (field, index) => (
                <div key={index} className="flex flex-col">
                  <label
                    className="block font-medium text-black-3 text-base"
                    htmlFor={field}
                  >
                    {field === "currentPassword"
                      ? "Current Password"
                      : field === "newPassword"
                        ? "New Password"
                        : "Confirm New Password"}
                  </label>
                  <div className="relative">
                    <input
                      id={field}
                      name={field}
                      type={passwordTypes[field as PasswordField]}
                      className="placeholder:text-placeholder text-placeholder w-full rounded-[30px] bg-transparent py-[18px] px-[16px] pr-12 outline-none focus:border-primary focus-visible:shadow-none"
                      placeholder="Enter Password"
                      value={formik.values[field as PasswordField]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      data-testid={`${field}-input`}
                      style={{ border: "1px solid rgba(75, 73, 172, 0.30)" }}
                    />
                    <span
                      className="absolute right-7 top-5 cursor-pointer"
                      onClick={() => handleToggle(field as PasswordField)}
                      data-testid={`${field}-toggle`}
                    >
                      {passwordTypes[field as PasswordField] === "password" ? (
                        <img src={EyeClose} alt="eyeClose" />
                      ) : (
                        <img src={EyeOpen} alt="eyeOpen" />
                      )}
                    </span>
                  </div>
                  {formik.errors[field as PasswordField] &&
                    formik.touched[field as PasswordField] ? (
                    <div
                      className="py-1 text-sm font-medium text-danger"
                      data-testid={`${field}-error`}
                    >
                      {formik.errors[field as PasswordField]}
                    </div>
                  ) : null}
                </div>
              )
            )} */}
            {(
              [
                "currentPassword",
                "newPassword",
                "confirmPassword",
              ] as PasswordField[]
            ).map((field) => (
              <PasswordInput
                key={field}
                id={field}
                label={fieldLabels[field]}
                placeholder="Enter Password"
                type={passwordTypes[field]}
                value={formik.values[field]}
                error={formik.errors[field]}
                touched={formik.touched[field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onToggle={() => handleToggle(field)}
              />
            ))}
            <div className="w-full flex items-end gap-3 mt-[10px] justify-end">
              <button
                type="button"
                onClick={handleCancel} // Use the new cancel functionality
                className="font-medium py-2 px-4 w-[104px]"
                data-testid="cancel-button"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!(formik.isValid && formik.dirty)} // Disable button when form is invalid or untouched
                className={`font-medium rounded-md py-2 px-4 w-[184px] bg-blue-primary text-white
    ${
      formik.isValid && formik.dirty
        ? "cursor-pointer"
        : "bg-gray-400 cursor-not-allowed"
    }`}
                data-testid="submit-button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default PasswordReset;
