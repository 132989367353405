import { useState } from "react";
import {FileDomainApi} from "../openapi";
import {apiConfig} from "../components/ConfigurationApi/Configuration";
import {ResponseFile} from "../interfaces/pages/variedInterfaces";

const useFileDownload = () => {
    const [loading, setLoading] = useState(false);

    const handleFileDownload = async (id: string, type: string) => {
        setLoading(true);
        try {
            // Set Authorization header
            const headers: {} = {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            const api = new FileDomainApi(apiConfig());
            const response = await api.downloadFile(id);
            const imageUrl = (response as ResponseFile).config.url;

            // Getting the image URL
            const imageResponse = await fetch(imageUrl, { headers });

            // If the image response is ok, create a Blob
            if (imageResponse.ok) {
                const imageBlob = await imageResponse.blob();

                const downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(imageBlob);

                // Configuring file name
                const parts = type.split(".");
                const fileName = parts[0];
                downloadLink.download = `${fileName}.${parts[1] || "png"}`;

                // Adding the link to the document body and simulating click to download
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setLoading(false);
        }
    };

    return { handleFileDownload, loading };
};

export default useFileDownload;
