/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Request details for marking an alert as read. This is used to track which user has read specific data in our system. Domains data include MNA, Note, Fundamentals, etc. 
 * @export
 * @interface AlertReadRequest
 */
export interface AlertReadRequest {
    /**
     * ID of the alert to be marked as read
     * @type {string}
     * @memberof AlertReadRequest
     */
    'alertId': string;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDConditionRequest
 */
export interface AssociationActionsUUIDConditionRequest {
    /**
     * 
     * @type {Array<ConditionRequest>}
     * @memberof AssociationActionsUUIDConditionRequest
     */
    'add'?: Array<ConditionRequest>;
    /**
     * 
     * @type {{ [key: string]: ConditionRequest; }}
     * @memberof AssociationActionsUUIDConditionRequest
     */
    'edit'?: { [key: string]: ConditionRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDConditionRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDDateEventRequest
 */
export interface AssociationActionsUUIDDateEventRequest {
    /**
     * 
     * @type {Array<DateEventRequest>}
     * @memberof AssociationActionsUUIDDateEventRequest
     */
    'add'?: Array<DateEventRequest>;
    /**
     * 
     * @type {{ [key: string]: DateEventRequest; }}
     * @memberof AssociationActionsUUIDDateEventRequest
     */
    'edit'?: { [key: string]: DateEventRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDDateEventRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDDomainAssociationRequest
 */
export interface AssociationActionsUUIDDomainAssociationRequest {
    /**
     * 
     * @type {Array<DomainAssociationRequest>}
     * @memberof AssociationActionsUUIDDomainAssociationRequest
     */
    'add'?: Array<DomainAssociationRequest>;
    /**
     * 
     * @type {{ [key: string]: DomainAssociationRequest; }}
     * @memberof AssociationActionsUUIDDomainAssociationRequest
     */
    'edit'?: { [key: string]: DomainAssociationRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDDomainAssociationRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDFeatureAssociationRequest
 */
export interface AssociationActionsUUIDFeatureAssociationRequest {
    /**
     * 
     * @type {Array<FeatureAssociationRequest>}
     * @memberof AssociationActionsUUIDFeatureAssociationRequest
     */
    'add'?: Array<FeatureAssociationRequest>;
    /**
     * 
     * @type {{ [key: string]: FeatureAssociationRequest; }}
     * @memberof AssociationActionsUUIDFeatureAssociationRequest
     */
    'edit'?: { [key: string]: FeatureAssociationRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDFeatureAssociationRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDLinkRequest
 */
export interface AssociationActionsUUIDLinkRequest {
    /**
     * 
     * @type {Array<LinkRequest>}
     * @memberof AssociationActionsUUIDLinkRequest
     */
    'add'?: Array<LinkRequest>;
    /**
     * 
     * @type {{ [key: string]: LinkRequest; }}
     * @memberof AssociationActionsUUIDLinkRequest
     */
    'edit'?: { [key: string]: LinkRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDLinkRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDTagRequest
 */
export interface AssociationActionsUUIDTagRequest {
    /**
     * 
     * @type {Array<TagRequest>}
     * @memberof AssociationActionsUUIDTagRequest
     */
    'add'?: Array<TagRequest>;
    /**
     * 
     * @type {{ [key: string]: TagRequest; }}
     * @memberof AssociationActionsUUIDTagRequest
     */
    'edit'?: { [key: string]: TagRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDTagRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDTxDocSummaryRequest
 */
export interface AssociationActionsUUIDTxDocSummaryRequest {
    /**
     * 
     * @type {Array<TxDocSummaryRequest>}
     * @memberof AssociationActionsUUIDTxDocSummaryRequest
     */
    'add'?: Array<TxDocSummaryRequest>;
    /**
     * 
     * @type {{ [key: string]: TxDocSummaryRequest; }}
     * @memberof AssociationActionsUUIDTxDocSummaryRequest
     */
    'edit'?: { [key: string]: TxDocSummaryRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDTxDocSummaryRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDUUID
 */
export interface AssociationActionsUUIDUUID {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDUUID
     */
    'add'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AssociationActionsUUIDUUID
     */
    'edit'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDUUID
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssociationActionsUUIDWhatMattersRequest
 */
export interface AssociationActionsUUIDWhatMattersRequest {
    /**
     * 
     * @type {Array<WhatMattersRequest>}
     * @memberof AssociationActionsUUIDWhatMattersRequest
     */
    'add'?: Array<WhatMattersRequest>;
    /**
     * 
     * @type {{ [key: string]: WhatMattersRequest; }}
     * @memberof AssociationActionsUUIDWhatMattersRequest
     */
    'edit'?: { [key: string]: WhatMattersRequest; };
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociationActionsUUIDWhatMattersRequest
     */
    'delete'?: Array<string>;
}
/**
 * The response object that contains the information for the corresponding domain entity. It might be a MnaResponse, a NoteResponse, a FundamentalResponse, etc. 
 * @export
 * @interface Audited
 */
export interface Audited {
    /**
     * 
     * @type {number}
     * @memberof Audited
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof Audited
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audited
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof Audited
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof Audited
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    'domainTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'domainName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'name'?: string;
}
/**
 * Request object used to create or update a category for conditions.
 * @export
 * @interface ConditionCategoryRequest
 */
export interface ConditionCategoryRequest {
    /**
     * Name of the condition category
     * @type {string}
     * @memberof ConditionCategoryRequest
     */
    'name': string;
}
/**
 * Response object containing details of a condition category.
 * @export
 * @interface ConditionCategoryResponse
 */
export interface ConditionCategoryResponse {
    /**
     * Name of the condition category
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionCategoryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionCategoryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * Request object used to create or update a condition associated with merger and acquisition (MNA) activities.
 * @export
 * @interface ConditionRequest
 */
export interface ConditionRequest {
    /**
     * 
     * @type {number}
     * @memberof ConditionRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof ConditionRequest
     */
    'mnaId': string;
    /**
     * Primary key of the category
     * @type {number}
     * @memberof ConditionRequest
     */
    'conditionCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ConditionRequest
     */
    'categoryOtherComment'?: string;
    /**
     * Primary key of the status
     * @type {number}
     * @memberof ConditionRequest
     */
    'statusId': number;
    /**
     * Date when the Condition was filed
     * @type {string}
     * @memberof ConditionRequest
     */
    'filed'?: string;
    /**
     * Expected confirmation date for the Condition
     * @type {string}
     * @memberof ConditionRequest
     */
    'expectedConfirmed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionRequest
     */
    'commentary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionRequest
     */
    'expectedConfirmedFlag': boolean;
}
/**
 * Response object providing details about a condition related to merger and acquisition (MNA) activities.
 * @export
 * @interface ConditionResponse
 */
export interface ConditionResponse {
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'mnaId': string;
    /**
     * Primary key of the category
     * @type {number}
     * @memberof ConditionResponse
     */
    'conditionCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'categoryOtherComment'?: string;
    /**
     * Primary key of the status
     * @type {number}
     * @memberof ConditionResponse
     */
    'statusId': number;
    /**
     * Date when the Condition was filed
     * @type {string}
     * @memberof ConditionResponse
     */
    'filed'?: string;
    /**
     * Expected confirmation date for the Condition
     * @type {string}
     * @memberof ConditionResponse
     */
    'expectedConfirmed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionResponse
     */
    'expectedConfirmedFlag': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {ConditionCategoryResponse}
     * @memberof ConditionResponse
     */
    'category'?: ConditionCategoryResponse;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * Request object for creating a condition status. The status can be either \'pending\' or \'complete\'.
 * @export
 * @interface ConditionStatusRequest
 */
export interface ConditionStatusRequest {
    /**
     * Status of the condition
     * @type {string}
     * @memberof ConditionStatusRequest
     */
    'status': string;
}
/**
 * Response object for condition status. Provides details about the status of a condition.
 * @export
 * @interface ConditionStatusResponse
 */
export interface ConditionStatusResponse {
    /**
     * Status of the condition
     * @type {string}
     * @memberof ConditionStatusResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionStatusResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionStatusResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * Response object for associating contacts with other domains.
 * @export
 * @interface ContactAssociationResponse
 */
export interface ContactAssociationResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAssociationResponse
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ContactAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof ContactAssociationResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ContactAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface ContactRequest
 */
export interface ContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    'email': string;
    /**
     * 
     * @type {DomainAssociationRequest}
     * @memberof ContactRequest
     */
    'domain'?: DomainAssociationRequest;
}
/**
 * 
 * @export
 * @interface ContactResponse
 */
export interface ContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ContactResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ContactResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<ContactAssociationResponse>}
     * @memberof ContactResponse
     */
    'domains'?: Array<ContactAssociationResponse>;
}
/**
 * 
 * @export
 * @interface DashboardResp
 */
export interface DashboardResp {
    /**
     * 
     * @type {Array<MnaPortlet>}
     * @memberof DashboardResp
     */
    'mnas'?: Array<MnaPortlet>;
    /**
     * 
     * @type {Array<NotePortlet>}
     * @memberof DashboardResp
     */
    'notes'?: Array<NotePortlet>;
}
/**
 * Request object for creating or updating a Date Event. Date Events represent significant occurrences related to mergers and acquisitions (M&A) activities. 
 * @export
 * @interface DateEventRequest
 */
export interface DateEventRequest {
    /**
     * 
     * @type {number}
     * @memberof DateEventRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof DateEventRequest
     */
    'mnaId': string;
    /**
     * The identifier of the type of date event. This field indicates the specific type of event, such as \'Out Date\', \'Broker Closing Estimate\', or \'Financing\'. 
     * @type {number}
     * @memberof DateEventRequest
     */
    'dateEventTypeId'?: number;
    /**
     * The date of the event. This field represents the date when the event is scheduled to occur.
     * @type {string}
     * @memberof DateEventRequest
     */
    'dateEvent': string;
    /**
     * Additional comments or notes regarding the date event. Users can provide additional information or context about the event using this field.
     * @type {string}
     * @memberof DateEventRequest
     */
    'comment'?: string;
}
/**
 * Response object containing details about a Date Event. Date Events capture significant milestones and events in mergers and acquisitions (M&A) processes.  This response provides information about the Date Event, including the associated M&A entity, event type, date, and any additional comments.   It also includes audit information such as creation and modification dates, as well as the user responsible for the last modification. 
 * @export
 * @interface DateEventResponse
 */
export interface DateEventResponse {
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'mnaId': string;
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'dateEventTypeId'?: number;
    /**
     * The date of the event. This field represents the date when the event is scheduled to occur.
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateEvent': string;
    /**
     * Additional comments or notes regarding the date event. Users can provide additional information or context about the event using this field.
     * @type {string}
     * @memberof DateEventResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateEventTypeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'mnaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof DateEventResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof DateEventResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface DomainAssociationRequest
 */
export interface DomainAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof DomainAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof DomainAssociationRequest
     */
    'entityId': string;
}
/**
 * 
 * @export
 * @interface DomainChangeByProperty
 */
export interface DomainChangeByProperty {
    /**
     * 
     * @type {string}
     * @memberof DomainChangeByProperty
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<DomainPropertyChange>; }}
     * @memberof DomainChangeByProperty
     */
    'propertyMap'?: { [key: string]: Array<DomainPropertyChange>; };
}
/**
 * 
 * @export
 * @interface DomainPropertyChange
 */
export interface DomainPropertyChange {
    /**
     * 
     * @type {string}
     * @memberof DomainPropertyChange
     */
    'propertyName'?: string;
    /**
     * 
     * @type {object}
     * @memberof DomainPropertyChange
     */
    'left'?: object;
    /**
     * 
     * @type {object}
     * @memberof DomainPropertyChange
     */
    'right'?: object;
    /**
     * 
     * @type {number}
     * @memberof DomainPropertyChange
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainPropertyChange
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainPropertyChange
     */
    'dateModified'?: string;
}
/**
 * Contains structured error information for efficient troubleshooting and resolution.  Includes user details, request specifics, stack trace, and timestamps. Essential for error handling and debugging.\" 
 * @export
 * @interface ErrorLogResponse
 */
export interface ErrorLogResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestBody'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestHeaders'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'stackTrace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'remoteIpAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'dateCreated'?: string;
}
/**
 * Request object for creating or updating an exchange. An exchange represents a marketplace where financial instruments, such as stocks,  bonds, commodities, and derivatives, are traded. 
 * @export
 * @interface ExchangeRequest
 */
export interface ExchangeRequest {
    /**
     * 
     * @type {number}
     * @memberof ExchangeRequest
     */
    'version': number;
    /**
     * The name of the exchange. Example: New York Stock Exchange (NYSE), NASDAQ, London Stock Exchange (LSE), etc.
     * @type {string}
     * @memberof ExchangeRequest
     */
    'name': string;
}
/**
 * Response object containing exchange information.
 * @export
 * @interface ExchangeResponse
 */
export interface ExchangeResponse {
    /**
     * 
     * @type {number}
     * @memberof ExchangeResponse
     */
    'version': number;
    /**
     * The name of the exchange. Example: New York Stock Exchange (NYSE), NASDAQ, London Stock Exchange (LSE), etc.
     * @type {string}
     * @memberof ExchangeResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FeatureAssociationRequest
 */
export interface FeatureAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationRequest
     */
    'featureId': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationRequest
     */
    'mnaId': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface FeatureAssociationResponse
 */
export interface FeatureAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'featureId': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'mnaId': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a feature. Features allow users to customize Mna entities by adding additional details such as \'budget\', \'location\', or \'status\', providing more context and enhancing the overall understanding of an Mna entity.
 * @export
 * @interface FeatureRequest
 */
export interface FeatureRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureRequest
     */
    'version': number;
    /**
     * The name of the feature.
     * @type {string}
     * @memberof FeatureRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FeatureResponse
 */
export interface FeatureResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureResponse
     */
    'version': number;
    /**
     * The name of the feature.
     * @type {string}
     * @memberof FeatureResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for associating files with other domains.
 * @export
 * @interface FileAssociationRequest
 */
export interface FileAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof FileAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationRequest
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationRequest
     */
    'fileId': string;
}
/**
 * Response object for file association with other domains.
 * @export
 * @interface FileAssociationResponse
 */
export interface FileAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'filename'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Represents a single company or entity that is the focus of Merger Daily research, similar to a merger and acquisition (MNA) between two companies.
 * @export
 * @interface FundamentalRequest
 */
export interface FundamentalRequest {
    /**
     * 
     * @type {number}
     * @memberof FundamentalRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundamentalRequest
     */
    'sectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'dateIpo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'incorp'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'hq'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'geoExposure'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'businessDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'companyHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'companyBasics'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'driversValuation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'valuationHistory'?: string;
    /**
     * 
     * @type {AssociationActionsUUIDTagRequest}
     * @memberof FundamentalRequest
     */
    'tags'?: AssociationActionsUUIDTagRequest;
    /**
     * 
     * @type {AssociationActionsUUIDUUID}
     * @memberof FundamentalRequest
     */
    'files'?: AssociationActionsUUIDUUID;
    /**
     * 
     * @type {AssociationActionsUUIDWhatMattersRequest}
     * @memberof FundamentalRequest
     */
    'whatMatters'?: AssociationActionsUUIDWhatMattersRequest;
}
/**
 * Represents a single company or entity that is the focus of Merger Daily research, similar to a merger and acquisition (MNA) between two companies.
 * @export
 * @interface FundamentalResponse
 */
export interface FundamentalResponse {
    /**
     * 
     * @type {number}
     * @memberof FundamentalResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundamentalResponse
     */
    'sectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateIpo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'incorp'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'hq'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'geoExposure'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'businessDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'companyHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'companyBasics'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'driversValuation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'valuationHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FundamentalResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FundamentalResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof FundamentalResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof FundamentalResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteSimple>}
     * @memberof FundamentalResponse
     */
    'notes'?: Array<NoteSimple>;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'whatMattersId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'whatMatters'?: string;
}
/**
 * 
 * @export
 * @interface GlobalSearchCriteriaResponse
 */
export interface GlobalSearchCriteriaResponse {
    /**
     * 
     * @type {Array<GlobalSearchMnaResponse>}
     * @memberof GlobalSearchCriteriaResponse
     */
    'mnas'?: Array<GlobalSearchMnaResponse>;
    /**
     * 
     * @type {Array<GlobalSearchNoteResponse>}
     * @memberof GlobalSearchCriteriaResponse
     */
    'notes'?: Array<GlobalSearchNoteResponse>;
    /**
     * 
     * @type {Array<GlobalSearchFundamentalResponse>}
     * @memberof GlobalSearchCriteriaResponse
     */
    'fundamentals'?: Array<GlobalSearchFundamentalResponse>;
}
/**
 * Represents a global search response for a fundamental
 * @export
 * @interface GlobalSearchFundamentalResponse
 */
export interface GlobalSearchFundamentalResponse {
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchFundamentalResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchFundamentalResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchFundamentalResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GlobalSearchFundamentalResponse
     */
    'highlightedText'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface GlobalSearchMnaResponse
 */
export interface GlobalSearchMnaResponse {
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchMnaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchMnaResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchMnaResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GlobalSearchMnaResponse
     */
    'highlightedText'?: { [key: string]: Array<string>; };
}
/**
 *     Response object for note search results together with the highlighted text that     matches the search if applicable. 
 * @export
 * @interface GlobalSearchNoteResponse
 */
export interface GlobalSearchNoteResponse {
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchNoteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchNoteResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchNoteResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSearchNoteResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GlobalSearchNoteResponse
     */
    'highlightedText'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ImpliedProbabilityRequest
 */
export interface ImpliedProbabilityRequest {
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityRequest
     */
    'mnaId': string;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'downside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'currentPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'upside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'impliedProb'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityRequest
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityRequest
     */
    'author'?: string;
}
/**
 * 
 * @export
 * @interface ImpliedProbabilityResponse
 */
export interface ImpliedProbabilityResponse {
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'mnaId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'downside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'currentPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'upside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'impliedProb'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'author'?: string;
}
/**
 * Request object for associating Link with other domains.
 * @export
 * @interface LinkAssociationRequest
 */
export interface LinkAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationRequest
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationRequest
     */
    'linkId': string;
}
/**
 * Response object for Link association with other domains.
 * @export
 * @interface LinkAssociationResponse
 */
export interface LinkAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'linkId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'linkCategoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a link category. A link category is used to classify links into different groups for easier management and retrieval. 
 * @export
 * @interface LinkCategoryRequest
 */
export interface LinkCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryRequest
     */
    'version': number;
    /**
     * The name of the link category
     * @type {string}
     * @memberof LinkCategoryRequest
     */
    'name': string;
}
/**
 * Response object for Link Category, containing the category\'s details. A link category represents a specific classification for links within the system, such as News, Research, or Def Proxy. 
 * @export
 * @interface LinkCategoryResponse
 */
export interface LinkCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkCategoryResponse
     */
    'name'?: string;
}
/**
 * Request object for creating or updating a link. A link is an external URL associated with a specific Mergers and Acquisitions (MNA) record,  often used to provide additional context or reference material. 
 * @export
 * @interface LinkRequest
 */
export interface LinkRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof LinkRequest
     */
    'linkCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkRequest
     */
    'link': string;
    /**
     * 
     * @type {AssociationActionsUUIDDomainAssociationRequest}
     * @memberof LinkRequest
     */
    'domains'?: AssociationActionsUUIDDomainAssociationRequest;
}
/**
 * Response object for a link, which represents an external website link associated with a specific merger or acquisition (M&A).
 * @export
 * @interface LinkResponse
 */
export interface LinkResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkResponse
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof LinkResponse
     */
    'linkCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'id'?: string;
    /**
     * The category of the link, indicating the type or context of the link (e.g., news, research, analysis).
     * @type {string}
     * @memberof LinkResponse
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<LinkAssociationResponse>}
     * @memberof LinkResponse
     */
    'domains'?: Array<LinkAssociationResponse>;
}
/**
 * 
 * @export
 * @interface MnaBasicsCreateRequest
 */
export interface MnaBasicsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MnaBasicsCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaBasicsCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaBasicsCreateRequest
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaBasicsCreateRequest
     */
    'sectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaBasicsCreateRequest
     */
    'headquarters'?: string;
}
/**
 * Request object representing an MNA category. All MNA records must be associated with a category.
 * @export
 * @interface MnaCategoryRequest
 */
export interface MnaCategoryRequest {
    /**
     * The name of the MNA category.
     * @type {string}
     * @memberof MnaCategoryRequest
     */
    'name': string;
}
/**
 * Response object representing an MNA category. All MNA records must be associated with a category.
 * @export
 * @interface MnaCategoryResponse
 */
export interface MnaCategoryResponse {
    /**
     * The name of the MNA category.
     * @type {string}
     * @memberof MnaCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaCategoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaCategoryResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface MnaCreateRequest
 */
export interface MnaCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof MnaCreateRequest
     */
    'categoryId': number;
    /**
     * 
     * @type {string}
     * @memberof MnaCreateRequest
     */
    'dateAnnounced': string;
    /**
     * 
     * @type {MnaBasicsCreateRequest}
     * @memberof MnaCreateRequest
     */
    'target'?: MnaBasicsCreateRequest;
    /**
     * 
     * @type {MnaBasicsCreateRequest}
     * @memberof MnaCreateRequest
     */
    'acquirer'?: MnaBasicsCreateRequest;
}
/**
 * 
 * @export
 * @interface MnaPortlet
 */
export interface MnaPortlet {
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'categoryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'fundamentalTargetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'fundamentalAcquirerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MnaPortlet
     */
    'tickers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'dateAnnounced'?: string;
}
/**
 * Represents a merger and acquisition (MNA) between two companies. This request object is used to create or update MNA entities.
 * @export
 * @interface MnaRequest
 */
export interface MnaRequest {
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'categoryId': number;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'fundamentalTargetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'fundamentalAcquirerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dateAnnounced': string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dateClosing'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'acquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tickerAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tickerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'sectorAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'sectorTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'incorpAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'incorpTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'hqAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'hqTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'geoExposureAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'geoExposureTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terminationFeeAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terminationFeeTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'financialAdvisorForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'financialAdvisorForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'legalCounselForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'legalCounselForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'premium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'closingLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'appraisalRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'specificPerformance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'requiredToLitigateComment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MnaRequest
     */
    'requiredToLitigate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'proxySolicitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'informationAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'adrDepositary'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'guaranteedDelivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'competitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'customers'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dividendTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dividendAcquirer'?: string;
    /**
     * 
     * @type {AssociationActionsUUIDConditionRequest}
     * @memberof MnaRequest
     */
    'conditions'?: AssociationActionsUUIDConditionRequest;
    /**
     * 
     * @type {AssociationActionsUUIDWhatMattersRequest}
     * @memberof MnaRequest
     */
    'whatMatters'?: AssociationActionsUUIDWhatMattersRequest;
    /**
     * 
     * @type {AssociationActionsUUIDLinkRequest}
     * @memberof MnaRequest
     */
    'links'?: AssociationActionsUUIDLinkRequest;
    /**
     * 
     * @type {AssociationActionsUUIDDateEventRequest}
     * @memberof MnaRequest
     */
    'outDates'?: AssociationActionsUUIDDateEventRequest;
    /**
     * 
     * @type {AssociationActionsUUIDDateEventRequest}
     * @memberof MnaRequest
     */
    'brokerClosingDates'?: AssociationActionsUUIDDateEventRequest;
    /**
     * 
     * @type {AssociationActionsUUIDDateEventRequest}
     * @memberof MnaRequest
     */
    'financingDates'?: AssociationActionsUUIDDateEventRequest;
    /**
     * 
     * @type {AssociationActionsUUIDFeatureAssociationRequest}
     * @memberof MnaRequest
     */
    'features'?: AssociationActionsUUIDFeatureAssociationRequest;
    /**
     * 
     * @type {AssociationActionsUUIDTagRequest}
     * @memberof MnaRequest
     */
    'tags'?: AssociationActionsUUIDTagRequest;
    /**
     * 
     * @type {AssociationActionsUUIDUUID}
     * @memberof MnaRequest
     */
    'files'?: AssociationActionsUUIDUUID;
    /**
     * 
     * @type {AssociationActionsUUIDTxDocSummaryRequest}
     * @memberof MnaRequest
     */
    'txDocSummaries'?: AssociationActionsUUIDTxDocSummaryRequest;
}
/**
 * Response object representing a merger and acquisition (MNA) between two companies.
 * @export
 * @interface MnaResponse
 */
export interface MnaResponse {
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'categoryId': number;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'fundamentalTargetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'fundamentalAcquirerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateAnnounced': string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateClosing'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'acquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tickerAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tickerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'sectorAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'sectorTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'incorpAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'incorpTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'hqAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'hqTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'geoExposureAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'geoExposureTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terminationFeeAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terminationFeeTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'financialAdvisorForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'financialAdvisorForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'legalCounselForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'legalCounselForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'premium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'closingLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'appraisalRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'specificPerformance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'requiredToLitigateComment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MnaResponse
     */
    'requiredToLitigate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'proxySolicitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'informationAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'adrDepositary'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'guaranteedDelivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'competitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'customers'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dividendTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dividendAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'categoryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'subCategoryName'?: string;
    /**
     * 
     * @type {Array<WhatMattersResponse>}
     * @memberof MnaResponse
     */
    'whatMatters'?: Array<WhatMattersResponse>;
    /**
     * 
     * @type {Array<LinkResponse>}
     * @memberof MnaResponse
     */
    'links'?: Array<LinkResponse>;
    /**
     * 
     * @type {Array<ConditionResponse>}
     * @memberof MnaResponse
     */
    'conditions'?: Array<ConditionResponse>;
    /**
     * 
     * @type {Array<TxDocSummaryResponse>}
     * @memberof MnaResponse
     */
    'txDocSummaries'?: Array<TxDocSummaryResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'outDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'brokerClosingDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'financingDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<FeatureAssociationResponse>}
     * @memberof MnaResponse
     */
    'features'?: Array<FeatureAssociationResponse>;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof MnaResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof MnaResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteSimple>}
     * @memberof MnaResponse
     */
    'notes'?: Array<NoteSimple>;
    /**
     * 
     * @type {Array<ContactResponse>}
     * @memberof MnaResponse
     */
    'contacts'?: Array<ContactResponse>;
}
/**
 * 
 * @export
 * @interface MnaTxDocSummaryView
 */
export interface MnaTxDocSummaryView {
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'summaryType'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaTxDocSummaryView
     */
    'summaryTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'createdById'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaTxDocSummaryView
     */
    'modifiedById'?: string;
}
/**
 * 
 * @export
 * @interface MultiStepResponse
 */
export interface MultiStepResponse {
    /**
     * 
     * @type {string}
     * @memberof MultiStepResponse
     */
    'formId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MultiStepResponse
     */
    'page'?: number;
}
/**
 * Represents a request to associate a note with other domains. Note associations can link a note to various entities or domains within the system.  A note can belong to multiple Fundamentals and multiple MNA. 
 * @export
 * @interface NoteAssociationRequest
 */
export interface NoteAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationRequest
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationRequest
     */
    'noteId': string;
}
/**
 * Represents a response for a note association. Note association responses provide information about the association between a note and other domains.  A note can belong to multiple Fundamentals and multiple MNA. 
 * @export
 * @interface NoteAssociationResponse
 */
export interface NoteAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'noteId': string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'domainName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface NotePortlet
 */
export interface NotePortlet {
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'sourceType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotePortlet
     */
    'tickers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'dateModified'?: string;
}
/**
 * A detailed request object of a note, capturing important information and analysis relevant to business or financial contexts.  Notes are collected from various sources and serve as a comprehensive news feed providing financial data and insights. 
 * @export
 * @interface NoteRequest
 */
export interface NoteRequest {
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'noteSourceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'datePublished': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author3'?: string;
    /**
     * 
     * @type {AssociationActionsUUIDTagRequest}
     * @memberof NoteRequest
     */
    'tags'?: AssociationActionsUUIDTagRequest;
    /**
     * 
     * @type {AssociationActionsUUIDUUID}
     * @memberof NoteRequest
     */
    'files'?: AssociationActionsUUIDUUID;
    /**
     * 
     * @type {AssociationActionsUUIDDomainAssociationRequest}
     * @memberof NoteRequest
     */
    'domains'?: AssociationActionsUUIDDomainAssociationRequest;
}
/**
 * A detailed response object of a note, capturing important information and analysis relevant to business or financial contexts.  Notes are collected from various sources and serve as a comprehensive news feed providing financial data and insights. 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'noteSourceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'datePublished': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author3'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'sourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof NoteResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof NoteResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteAssociationResponse>}
     * @memberof NoteResponse
     */
    'domains'?: Array<NoteAssociationResponse>;
}
/**
 * 
 * @export
 * @interface NoteSimple
 */
export interface NoteSimple {
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'sourceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteSimple
     */
    'sourceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'datePublished'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteSimple
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author3'?: string;
}
/**
 * Request object for creating a note source type.
 * @export
 * @interface NoteSourceTypeCreateRequest
 */
export interface NoteSourceTypeCreateRequest {
    /**
     * The name of the note source type.
     * @type {string}
     * @memberof NoteSourceTypeCreateRequest
     */
    'name': string;
}
/**
 * Response object for creating a note source type.
 * @export
 * @interface NoteSourceTypeCreateResponse
 */
export interface NoteSourceTypeCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteSourceTypeCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteSourceTypeCreateResponse
     */
    'version'?: number;
    /**
     * The name of the note source type.
     * @type {string}
     * @memberof NoteSourceTypeCreateResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PagedModel
 */
export interface PagedModel {
    /**
     * 
     * @type {Array<object>}
     * @memberof PagedModel
     */
    'content'?: Array<object>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModel
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'passwordConfirm': string;
}
/**
 * Password reset details for the user
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'passwordConfirm': string;
}
/**
 * Response details for the password reset operation
 * @export
 * @interface PasswordResetResponse
 */
export interface PasswordResetResponse {
    /**
     * Status of the password reset operation
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'status'?: string;
    /**
     * Detailed message of the password reset operation
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'message'?: string;
}
/**
 * It holds all the responses from the recent view per domain
 * @export
 * @interface RecentViewResponse
 */
export interface RecentViewResponse {
    /**
     * 
     * @type {Array<MnaResponse>}
     * @memberof RecentViewResponse
     */
    'mnas'?: Array<MnaResponse>;
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof RecentViewResponse
     */
    'notes'?: Array<NoteResponse>;
    /**
     * 
     * @type {Array<FundamentalResponse>}
     * @memberof RecentViewResponse
     */
    'fundamentals'?: Array<FundamentalResponse>;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface RegulatoryRequest
 */
export interface RegulatoryRequest {
    /**
     * 
     * @type {number}
     * @memberof RegulatoryRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'mandate': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'timing'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'commentary'?: string;
    /**
     * 
     * @type {AssociationActionsUUIDWhatMattersRequest}
     * @memberof RegulatoryRequest
     */
    'whatMatters'?: AssociationActionsUUIDWhatMattersRequest;
    /**
     * 
     * @type {AssociationActionsUUIDLinkRequest}
     * @memberof RegulatoryRequest
     */
    'links'?: AssociationActionsUUIDLinkRequest;
    /**
     * 
     * @type {AssociationActionsUUIDUUID}
     * @memberof RegulatoryRequest
     */
    'files'?: AssociationActionsUUIDUUID;
}
/**
 * 
 * @export
 * @interface RegulatoryResponse
 */
export interface RegulatoryResponse {
    /**
     * 
     * @type {number}
     * @memberof RegulatoryResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'mandate': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'timing'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof RegulatoryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof RegulatoryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<LinkResponse>}
     * @memberof RegulatoryResponse
     */
    'links'?: Array<LinkResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof RegulatoryResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteSimple>}
     * @memberof RegulatoryResponse
     */
    'notes'?: Array<NoteSimple>;
    /**
     * 
     * @type {Array<WhatMattersResponse>}
     * @memberof RegulatoryResponse
     */
    'whatMatters'?: Array<WhatMattersResponse>;
}
/**
 * Request object for creating or updating a sector. Example: Real Estate
 * @export
 * @interface SectorRequest
 */
export interface SectorRequest {
    /**
     * Name of the sector
     * @type {string}
     * @memberof SectorRequest
     */
    'name': string;
}
/**
 * Response object for a sector. Example: Technology
 * @export
 * @interface SectorResponse
 */
export interface SectorResponse {
    /**
     * Name of the sector
     * @type {string}
     * @memberof SectorResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SectorResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectorResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectorResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof SectorResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface SimplePageErrorLogResponse
 */
export interface SimplePageErrorLogResponse {
    /**
     * 
     * @type {Array<ErrorLogResponse>}
     * @memberof SimplePageErrorLogResponse
     */
    'content'?: Array<ErrorLogResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageErrorLogResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageLinkAssociationResponse
 */
export interface SimplePageLinkAssociationResponse {
    /**
     * 
     * @type {Array<LinkAssociationResponse>}
     * @memberof SimplePageLinkAssociationResponse
     */
    'content'?: Array<LinkAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageLinkAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageNoteAssociationResponse
 */
export interface SimplePageNoteAssociationResponse {
    /**
     * 
     * @type {Array<NoteAssociationResponse>}
     * @memberof SimplePageNoteAssociationResponse
     */
    'content'?: Array<NoteAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageNoteAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageTxDocSummaryTypeResponse
 */
export interface SimplePageTxDocSummaryTypeResponse {
    /**
     * 
     * @type {Array<TxDocSummaryTypeResponse>}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'content'?: Array<TxDocSummaryTypeResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageUserAlertResponse
 */
export interface SimplePageUserAlertResponse {
    /**
     * 
     * @type {Array<UserAlertResponse>}
     * @memberof SimplePageUserAlertResponse
     */
    'content'?: Array<UserAlertResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageUserAlertResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageWhatMattersAssociationResponse
 */
export interface SimplePageWhatMattersAssociationResponse {
    /**
     * 
     * @type {Array<WhatMattersAssociationResponse>}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'content'?: Array<WhatMattersAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    'timeout'?: number;
}
/**
 * Represents a response for a tag association. Tag association responses provide information about the association between a tag and other domains.
 * @export
 * @interface TagAssociationRequest
 */
export interface TagAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof TagAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationRequest
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationRequest
     */
    'tag': string;
}
/**
 * Represents a response for a tag association. Tag association responses provide information about the association between a tag and other domains.
 * @export
 * @interface TagAssociationResponse
 */
export interface TagAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'tag': string;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a tag.
 * @export
 * @interface TagRequest
 */
export interface TagRequest {
    /**
     * The text of the tag, used to categorize domain entities like MNA,Note
     * @type {string}
     * @memberof TagRequest
     */
    'tag': string;
}
/**
 * Response object for a tag.
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * The text of the tag, used to categorize domain entities like MNA,Note
     * @type {string}
     * @memberof TagResponse
     */
    'tag': string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface TxDocSummaryRequest
 */
export interface TxDocSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryRequest
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryRequest
     */
    'mnaId': string;
}
/**
 * 
 * @export
 * @interface TxDocSummaryResponse
 */
export interface TxDocSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'mnaId': string;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TxDocSummaryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TxDocSummaryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface TxDocSummaryTypeResponse
 */
export interface TxDocSummaryTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryTypeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryTypeResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserAccountInfo
 */
export interface UserAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof UserAccountInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountInfo
     */
    'username'?: string;
}
/**
 * Response object containing details about all unread alerts across all domains (e.g., MNA, Fundamental, Note, etc.).
 * @export
 * @interface UserAlertResponse
 */
export interface UserAlertResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'domainTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'entityId': string;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'entityVersion': number;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'createdById'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'lastModifiedById'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserAlertResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserAlertResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * Title identifier for the domain type to which the alert pertains.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'title'?: string;
    /**
     * Name of the user who created the alert.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'createdByName'?: string;
    /**
     * Name of the user who last modified the alert.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'lastModifiedByName'?: string;
    /**
     * 
     * @type {Audited}
     * @memberof UserAlertResponse
     */
    'response'?: Audited;
}
/**
 * Request object for user preferences. User preferences represent the settings or configurations chosen by a user.
 * @export
 * @interface UserPreferenceRequest
 */
export interface UserPreferenceRequest {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceRequest
     */
    'value': string;
}
/**
 * Response object for user preferences. User preferences represent the settings or configurations chosen by a user.
 * @export
 * @interface UserPreferenceResponse
 */
export interface UserPreferenceResponse {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface UserPreferenceTypeRequest
 */
export interface UserPreferenceTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeRequest
     */
    'valueDefault'?: string;
}
/**
 * 
 * @export
 * @interface UserPreferenceTypeResponse
 */
export interface UserPreferenceTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'valueDefault'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * Request object for updating user profile information
 * @export
 * @interface UserProfileRequest
 */
export interface UserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameFirst': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameMiddle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameLast': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'email': string;
}
/**
 * Response object containing user profile information
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameFirst': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameMiddle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameLast': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'dateModified'?: string;
}
/**
 * User details for creating a new account
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'email': string;
}
/**
 * Request object for associating whatMatters with other domains.
 * @export
 * @interface WhatMattersAssociationRequest
 */
export interface WhatMattersAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationRequest
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationRequest
     */
    'whatMattersId': string;
}
/**
 * Response object for whatMatters association with other domains.
 * @export
 * @interface WhatMattersAssociationResponse
 */
export interface WhatMattersAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'whatMattersId': string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating what matters entries. Represents important notes or details related to mergers and acquisitions.
 * @export
 * @interface WhatMattersRequest
 */
export interface WhatMattersRequest {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersRequest
     */
    'note': string;
    /**
     * 
     * @type {AssociationActionsUUIDDomainAssociationRequest}
     * @memberof WhatMattersRequest
     */
    'domains'?: AssociationActionsUUIDDomainAssociationRequest;
}
/**
 * Response object for what matters entries. Represents important notes or details related to mergers and acquisitions.
 * @export
 * @interface WhatMattersResponse
 */
export interface WhatMattersResponse {
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<WhatMattersAssociationResponse>}
     * @memberof WhatMattersResponse
     */
    'domains'?: Array<WhatMattersAssociationResponse>;
}
/**
 * Represents a response object containing detailed information about the currently authenticated user.
 * @export
 * @interface WhoAmIResponse
 */
export interface WhoAmIResponse {
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    'username'?: string;
    /**
     * 
     * @type {UserProfileResponse}
     * @memberof WhoAmIResponse
     */
    'profile'?: UserProfileResponse;
    /**
     * List of roles associated with the user
     * @type {Array<string>}
     * @memberof WhoAmIResponse
     */
    'roles'?: Array<string>;
}

/**
 * AdminUserViewApi - axios parameter creator
 * @export
 */
export const AdminUserViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser: async (userRequest: UserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('adminCreateUser', 'userRequest', userRequest)
            const localVarPath = `/api/view/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserResetPassword: async (passwordChangeRequest: PasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('adminUserResetPassword', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/api/view/admin/users/passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserViewApi - functional programming interface
 * @export
 */
export const AdminUserViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserViewApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateUser(userRequest: UserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUser(userRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUserViewApi.adminCreateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserResetPassword(passwordChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUserViewApi.adminUserResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminUserViewApi - factory interface
 * @export
 */
export const AdminUserViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserViewApiFp(configuration)
    return {
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(userRequest: UserRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.adminCreateUser(userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<PasswordResetResponse> {
            return localVarFp.adminUserResetPassword(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserViewApi - object-oriented interface
 * @export
 * @class AdminUserViewApi
 * @extends {BaseAPI}
 */
export class AdminUserViewApi extends BaseAPI {
    /**
     * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
     * @summary Create a new user
     * @param {UserRequest} userRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserViewApi
     */
    public adminCreateUser(userRequest: UserRequest, options?: RawAxiosRequestConfig) {
        return AdminUserViewApiFp(this.configuration).adminCreateUser(userRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows an admin to reset a user\'s password. The new password and confirmation must match.
     * @summary Reset user password
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserViewApi
     */
    public adminUserResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig) {
        return AdminUserViewApiFp(this.configuration).adminUserResetPassword(passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AlertDomainApi - axios parameter creator
 * @export
 */
export const AlertDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadAlertsCount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/alerts/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertDomainApi - functional programming interface
 * @export
 */
export const AlertDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlerts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageUserAlertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlerts(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertDomainApi.getAlerts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalUnreadAlertsCount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUnreadAlertsCount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertDomainApi.getTotalUnreadAlertsCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AlertDomainApi - factory interface
 * @export
 */
export const AlertDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertDomainApiFp(configuration)
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageUserAlertResponse> {
            return localVarFp.getAlerts(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadAlertsCount(options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getTotalUnreadAlertsCount(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertDomainApi - object-oriented interface
 * @export
 * @class AlertDomainApi
 * @extends {BaseAPI}
 */
export class AlertDomainApi extends BaseAPI {
    /**
     * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
     * @summary Get all unread alerts for a specific user
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertDomainApi
     */
    public getAlerts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return AlertDomainApiFp(this.configuration).getAlerts(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the total count of unread alerts for the authenticated user.
     * @summary Get total count of unread alerts for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertDomainApi
     */
    public getTotalUnreadAlertsCount(options?: RawAxiosRequestConfig) {
        return AlertDomainApiFp(this.configuration).getTotalUnreadAlertsCount(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AlertReadDomainApi - axios parameter creator
 * @export
 */
export const AlertReadDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertRead: async (alertReadRequest: AlertReadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertReadRequest' is not null or undefined
            assertParamExists('createAlertRead', 'alertReadRequest', alertReadRequest)
            const localVarPath = `/api/domain/alerts/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertRead: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAlertRead', 'id', id)
            const localVarPath = `/api/domain/alerts/read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertReadDomainApi - functional programming interface
 * @export
 */
export const AlertReadDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertReadDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAlertRead(alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAlertRead(alertReadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertReadDomainApi.createAlertRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlertRead(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlertRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertReadDomainApi.deleteAlertRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AlertReadDomainApi - factory interface
 * @export
 */
export const AlertReadDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertReadDomainApiFp(configuration)
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertRead(alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createAlertRead(alertReadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertRead(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAlertRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertReadDomainApi - object-oriented interface
 * @export
 * @class AlertReadDomainApi
 * @extends {BaseAPI}
 */
export class AlertReadDomainApi extends BaseAPI {
    /**
     * This endpoint allows a user to mark an alert as read.
     * @summary Mark alert as read
     * @param {AlertReadRequest} alertReadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertReadDomainApi
     */
    public createAlertRead(alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig) {
        return AlertReadDomainApiFp(this.configuration).createAlertRead(alertReadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an authenticated user to delete an alert read entry by its ID.
     * @summary Delete alert read entry
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertReadDomainApi
     */
    public deleteAlertRead(id: string, options?: RawAxiosRequestConfig) {
        return AlertReadDomainApiFp(this.configuration).deleteAlertRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationRestControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (authenticationRequest: AuthenticationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('authenticate', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest: RefreshTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationRestControllerApi - functional programming interface
 * @export
 */
export const AuthenticationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(authenticationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationRestControllerApi.authenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationRestControllerApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationRestControllerApi - factory interface
 * @export
 */
export const AuthenticationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticate(authenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthenticationResponse> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationRestControllerApi - object-oriented interface
 * @export
 * @class AuthenticationRestControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRestControllerApi
     */
    public authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationRestControllerApiFp(this.configuration).authenticate(authenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRestControllerApi
     */
    public refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationRestControllerApiFp(this.configuration).refreshToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionCategoryDomainApi - axios parameter creator
 * @export
 */
export const ConditionCategoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionCategory: async (conditionCategoryRequest: ConditionCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionCategoryRequest' is not null or undefined
            assertParamExists('createConditionCategory', 'conditionCategoryRequest', conditionCategoryRequest)
            const localVarPath = `/api/domain/conditions/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConditionCategory', 'id', id)
            const localVarPath = `/api/domain/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/conditions/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionCategoryById', 'id', id)
            const localVarPath = `/api/domain/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionCategory: async (id: number, conditionCategoryRequest: ConditionCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConditionCategory', 'id', id)
            // verify required parameter 'conditionCategoryRequest' is not null or undefined
            assertParamExists('updateConditionCategory', 'conditionCategoryRequest', conditionCategoryRequest)
            const localVarPath = `/api/domain/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionCategoryDomainApi - functional programming interface
 * @export
 */
export const ConditionCategoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionCategoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConditionCategory(conditionCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryDomainApi.createConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConditionCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConditionCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryDomainApi.deleteConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryDomainApi.getConditionCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryDomainApi.getConditionCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConditionCategory(id, conditionCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryDomainApi.updateConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionCategoryDomainApi - factory interface
 * @export
 */
export const ConditionCategoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionCategoryDomainApiFp(configuration)
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createConditionCategory(conditionCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionCategory(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteConditionCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getConditionCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategoryById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConditionCategoryResponse> {
            return localVarFp.getConditionCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateConditionCategory(id, conditionCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionCategoryDomainApi - object-oriented interface
 * @export
 * @class ConditionCategoryDomainApi
 * @extends {BaseAPI}
 */
export class ConditionCategoryDomainApi extends BaseAPI {
    /**
     * This endpoint allows an admin or user to create a new condition category.
     * @summary Create a new Condition Category
     * @param {ConditionCategoryRequest} conditionCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryDomainApi
     */
    public createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig) {
        return ConditionCategoryDomainApiFp(this.configuration).createConditionCategory(conditionCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an admin or user to delete an existing condition category by its ID.
     * @summary Delete a Condition Category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryDomainApi
     */
    public deleteConditionCategory(id: number, options?: RawAxiosRequestConfig) {
        return ConditionCategoryDomainApiFp(this.configuration).deleteConditionCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of condition categories, optionally filtered by a search term.
     * @summary Get a page of Condition Categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryDomainApi
     */
    public getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionCategoryDomainApiFp(this.configuration).getConditionCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a condition category by its unique identifier.
     * @summary Get Condition Category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryDomainApi
     */
    public getConditionCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return ConditionCategoryDomainApiFp(this.configuration).getConditionCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an admin or user to update an existing condition category.
     * @summary Update an existing Condition Category
     * @param {number} id 
     * @param {ConditionCategoryRequest} conditionCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryDomainApi
     */
    public updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig) {
        return ConditionCategoryDomainApiFp(this.configuration).updateConditionCategory(id, conditionCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionDomainApi - axios parameter creator
 * @export
 */
export const ConditionDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/domain/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition: async (conditionRequest: ConditionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionRequest' is not null or undefined
            assertParamExists('createCondition', 'conditionRequest', conditionRequest)
            const localVarPath = `/api/domain/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConditionsByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllConditionsByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllConditionsByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/conditions/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionById', 'id', id)
            const localVarPath = `/api/domain/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditions: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getConditionsCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getConditionsCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/conditions/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {string} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCondition: async (id: string, conditionRequest: ConditionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCondition', 'id', id)
            // verify required parameter 'conditionRequest' is not null or undefined
            assertParamExists('updateCondition', 'conditionRequest', conditionRequest)
            const localVarPath = `/api/domain/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionDomainApi - functional programming interface
 * @export
 */
export const ConditionDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCondition(conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCondition(conditionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.createCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConditionsByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.getAllConditionsByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.getConditionById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditions(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.getConditions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionsCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionsCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.getConditionsCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {string} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCondition(id: string, conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCondition(id, conditionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionDomainApi.updateCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionDomainApi - factory interface
 * @export
 */
export const ConditionDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionDomainApiFp(configuration)
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition(conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createCondition(conditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllConditionsByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ConditionResponse> {
            return localVarFp.getConditionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getConditions(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getConditionsCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {string} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCondition(id: string, conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCondition(id, conditionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionDomainApi - object-oriented interface
 * @export
 * @class ConditionDomainApi
 * @extends {BaseAPI}
 */
export class ConditionDomainApi extends BaseAPI {
    /**
     * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
     * @summary Delete a condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public _delete(id: string, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
     * @summary Create a new condition
     * @param {ConditionRequest} conditionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public createCondition(conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).createCondition(conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).getAllConditionsByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the details of a condition by its ID.
     * @summary Get condition by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public getConditionById(id: string, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).getConditionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
     * @summary Get a list of Conditions
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).getConditions(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the count of conditions that were modified between the provided start and end dates.
     * @summary Get the count of conditions modified between specified dates
     * @param {string} startDate Start date in ISO format
     * @param {string} endDate End date in ISO format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public getConditionsCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).getConditionsCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
     * @summary Update an existing condition
     * @param {string} id 
     * @param {ConditionRequest} conditionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionDomainApi
     */
    public updateCondition(id: string, conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig) {
        return ConditionDomainApiFp(this.configuration).updateCondition(id, conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionStatusDomainApi - axios parameter creator
 * @export
 */
export const ConditionStatusDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionStatus: async (conditionStatusRequest: ConditionStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionStatusRequest' is not null or undefined
            assertParamExists('createConditionStatus', 'conditionStatusRequest', conditionStatusRequest)
            const localVarPath = `/api/domain/conditions/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConditionStatus', 'id', id)
            const localVarPath = `/api/domain/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionStatusById', 'id', id)
            const localVarPath = `/api/domain/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatuses: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/conditions/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionStatus: async (id: number, conditionStatusRequest: ConditionStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConditionStatus', 'id', id)
            // verify required parameter 'conditionStatusRequest' is not null or undefined
            assertParamExists('updateConditionStatus', 'conditionStatusRequest', conditionStatusRequest)
            const localVarPath = `/api/domain/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionStatusDomainApi - functional programming interface
 * @export
 */
export const ConditionStatusDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionStatusDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConditionStatus(conditionStatusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusDomainApi.createConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConditionStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConditionStatus(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusDomainApi.deleteConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionStatusById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionStatusById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusDomainApi.getConditionStatusById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionStatuses(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusDomainApi.getConditionStatuses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConditionStatus(id, conditionStatusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusDomainApi.updateConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionStatusDomainApi - factory interface
 * @export
 */
export const ConditionStatusDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionStatusDomainApiFp(configuration)
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createConditionStatus(conditionStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionStatus(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteConditionStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConditionStatusResponse> {
            return localVarFp.getConditionStatusById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getConditionStatuses(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.updateConditionStatus(id, conditionStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionStatusDomainApi - object-oriented interface
 * @export
 * @class ConditionStatusDomainApi
 * @extends {BaseAPI}
 */
export class ConditionStatusDomainApi extends BaseAPI {
    /**
     * Create a new condition status with the provided details. Requires ADMIN or USER role.
     * @summary Create Condition Status
     * @param {ConditionStatusRequest} conditionStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusDomainApi
     */
    public createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig) {
        return ConditionStatusDomainApiFp(this.configuration).createConditionStatus(conditionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing condition status by its ID. Requires ADMIN or USER role.
     * @summary Delete Condition Status
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusDomainApi
     */
    public deleteConditionStatus(id: number, options?: RawAxiosRequestConfig) {
        return ConditionStatusDomainApiFp(this.configuration).deleteConditionStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a condition status based on its unique identifier.
     * @summary Get Condition Status by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusDomainApi
     */
    public getConditionStatusById(id: number, options?: RawAxiosRequestConfig) {
        return ConditionStatusDomainApiFp(this.configuration).getConditionStatusById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
     * @summary Get condition statuses
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusDomainApi
     */
    public getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionStatusDomainApiFp(this.configuration).getConditionStatuses(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing condition status with the provided details. Requires ADMIN or USER role.
     * @summary Update Condition Status
     * @param {number} id 
     * @param {ConditionStatusRequest} conditionStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusDomainApi
     */
    public updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig) {
        return ConditionStatusDomainApiFp(this.configuration).updateConditionStatus(id, conditionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContactDomainApi - axios parameter creator
 * @export
 */
export const ContactDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (contactRequest: ContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactRequest' is not null or undefined
            assertParamExists('createContact', 'contactRequest', contactRequest)
            const localVarPath = `/api/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContact', 'id', id)
            const localVarPath = `/api/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContacts: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactById', 'id', id)
            const localVarPath = `/api/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (id: string, contactRequest: ContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContact', 'id', id)
            // verify required parameter 'contactRequest' is not null or undefined
            assertParamExists('updateContact', 'contactRequest', contactRequest)
            const localVarPath = `/api/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactDomainApi - functional programming interface
 * @export
 */
export const ContactDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(contactRequest: ContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(contactRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactDomainApi.createContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactDomainApi.deleteContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllContacts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllContacts(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactDomainApi.getAllContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactDomainApi.getContactById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(id: string, contactRequest: ContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(id, contactRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactDomainApi.updateContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactDomainApi - factory interface
 * @export
 */
export const ContactDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(contactRequest: ContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createContact(contactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContacts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllContacts(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ContactResponse> {
            return localVarFp.getContactById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ContactRequest} contactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(id: string, contactRequest: ContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateContact(id, contactRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactDomainApi - object-oriented interface
 * @export
 * @class ContactDomainApi
 * @extends {BaseAPI}
 */
export class ContactDomainApi extends BaseAPI {
    /**
     * 
     * @param {ContactRequest} contactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactDomainApi
     */
    public createContact(contactRequest: ContactRequest, options?: RawAxiosRequestConfig) {
        return ContactDomainApiFp(this.configuration).createContact(contactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactDomainApi
     */
    public deleteContact(id: string, options?: RawAxiosRequestConfig) {
        return ContactDomainApiFp(this.configuration).deleteContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactDomainApi
     */
    public getAllContacts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ContactDomainApiFp(this.configuration).getAllContacts(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactDomainApi
     */
    public getContactById(id: string, options?: RawAxiosRequestConfig) {
        return ContactDomainApiFp(this.configuration).getContactById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ContactRequest} contactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactDomainApi
     */
    public updateContact(id: string, contactRequest: ContactRequest, options?: RawAxiosRequestConfig) {
        return ContactDomainApiFp(this.configuration).updateContact(id, contactRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardRestControllerApi - axios parameter creator
 * @export
 */
export const DashboardRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardRestControllerApi - functional programming interface
 * @export
 */
export const DashboardRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardRestControllerApi.getDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardRestControllerApi - factory interface
 * @export
 */
export const DashboardRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardRestControllerApiFp(configuration)
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(options?: RawAxiosRequestConfig): AxiosPromise<DashboardResp> {
            return localVarFp.getDashboard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardRestControllerApi - object-oriented interface
 * @export
 * @class DashboardRestControllerApi
 * @extends {BaseAPI}
 */
export class DashboardRestControllerApi extends BaseAPI {
    /**
     * Fetch data for the dashboard, including recent MNAs and notes.
     * @summary Retrieve Dashboard Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardRestControllerApi
     */
    public getDashboard(options?: RawAxiosRequestConfig) {
        return DashboardRestControllerApiFp(this.configuration).getDashboard(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataMigrationDomainApi - axios parameter creator
 * @export
 */
export const DataMigrationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get fundamentals migration status
         * @summary Get fundamentals migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsMigrationStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/migrations/fundamentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get MNA migration status
         * @summary Get MNA migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnaMigrationStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/migrations/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes migration status
         * @summary Get notes migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesMigrationStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/migrations/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get regulatory migration status
         * @summary Get regulatory migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatoryMigrationStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/migrations/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataMigrationDomainApi - functional programming interface
 * @export
 */
export const DataMigrationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataMigrationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Get fundamentals migration status
         * @summary Get fundamentals migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsMigrationStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsMigrationStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataMigrationDomainApi.fundamentalsMigrationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get MNA migration status
         * @summary Get MNA migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mnaMigrationStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mnaMigrationStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataMigrationDomainApi.mnaMigrationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes migration status
         * @summary Get notes migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesMigrationStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesMigrationStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataMigrationDomainApi.notesMigrationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get regulatory migration status
         * @summary Get regulatory migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regulatoryMigrationStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regulatoryMigrationStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataMigrationDomainApi.regulatoryMigrationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DataMigrationDomainApi - factory interface
 * @export
 */
export const DataMigrationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataMigrationDomainApiFp(configuration)
    return {
        /**
         * Get fundamentals migration status
         * @summary Get fundamentals migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsMigrationStatus(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.fundamentalsMigrationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Get MNA migration status
         * @summary Get MNA migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mnaMigrationStatus(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.mnaMigrationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes migration status
         * @summary Get notes migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesMigrationStatus(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.notesMigrationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Get regulatory migration status
         * @summary Get regulatory migration status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regulatoryMigrationStatus(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.regulatoryMigrationStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataMigrationDomainApi - object-oriented interface
 * @export
 * @class DataMigrationDomainApi
 * @extends {BaseAPI}
 */
export class DataMigrationDomainApi extends BaseAPI {
    /**
     * Get fundamentals migration status
     * @summary Get fundamentals migration status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMigrationDomainApi
     */
    public fundamentalsMigrationStatus(options?: RawAxiosRequestConfig) {
        return DataMigrationDomainApiFp(this.configuration).fundamentalsMigrationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get MNA migration status
     * @summary Get MNA migration status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMigrationDomainApi
     */
    public mnaMigrationStatus(options?: RawAxiosRequestConfig) {
        return DataMigrationDomainApiFp(this.configuration).mnaMigrationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes migration status
     * @summary Get notes migration status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMigrationDomainApi
     */
    public notesMigrationStatus(options?: RawAxiosRequestConfig) {
        return DataMigrationDomainApiFp(this.configuration).notesMigrationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get regulatory migration status
     * @summary Get regulatory migration status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMigrationDomainApi
     */
    public regulatoryMigrationStatus(options?: RawAxiosRequestConfig) {
        return DataMigrationDomainApiFp(this.configuration).regulatoryMigrationStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DateEventDomainApi - axios parameter creator
 * @export
 */
export const DateEventDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDateEvent: async (dateEventRequest: DateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateEventRequest' is not null or undefined
            assertParamExists('createDateEvent', 'dateEventRequest', dateEventRequest)
            const localVarPath = `/api/domain/mna/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDateEvent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDateEvent', 'id', id)
            const localVarPath = `/api/domain/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateEventById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDateEventById', 'id', id)
            const localVarPath = `/api/domain/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mna/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {string} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents: async (id: string, dateEventRequest: DateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUpcomingDateEvents', 'id', id)
            // verify required parameter 'dateEventRequest' is not null or undefined
            assertParamExists('getUpcomingDateEvents', 'dateEventRequest', dateEventRequest)
            const localVarPath = `/api/domain/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mna/dates/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DateEventDomainApi - functional programming interface
 * @export
 */
export const DateEventDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DateEventDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDateEvent(dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDateEvent(dateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.createDateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDateEvent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDateEvent(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.deleteDateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateEventById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateEventById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.getDateEventById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDates(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.getDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {string} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingDateEvents(id: string, dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingDateEvents(id, dateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.getUpcomingDateEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingDateEvents1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingDateEvents1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventDomainApi.getUpcomingDateEvents1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DateEventDomainApi - factory interface
 * @export
 */
export const DateEventDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DateEventDomainApiFp(configuration)
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDateEvent(dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createDateEvent(dateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDateEvent(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDateEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateEventById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DateEventResponse> {
            return localVarFp.getDateEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getDates(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {string} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents(id: string, dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getUpcomingDateEvents(id, dateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents1(options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getUpcomingDateEvents1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DateEventDomainApi - object-oriented interface
 * @export
 * @class DateEventDomainApi
 * @extends {BaseAPI}
 */
export class DateEventDomainApi extends BaseAPI {
    /**
     * Create a new Date Event.
     * @summary Create Date Event
     * @param {DateEventRequest} dateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public createDateEvent(dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).createDateEvent(dateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Date Event identified by its ID.
     * @summary Delete Date Event
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public deleteDateEvent(id: string, options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).deleteDateEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a Date Event by its unique identifier.
     * @summary Get Date Event by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public getDateEventById(id: string, options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).getDateEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve date events with optional filtering(currently not utilized) and pagination.
     * @summary Get Date Events
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).getDates(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of upcoming Date Events.
     * @summary Get Upcoming Date Events
     * @param {string} id 
     * @param {DateEventRequest} dateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public getUpcomingDateEvents(id: string, dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).getUpcomingDateEvents(id, dateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventDomainApi
     */
    public getUpcomingDateEvents1(options?: RawAxiosRequestConfig) {
        return DateEventDomainApiFp(this.configuration).getUpcomingDateEvents1(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainTypeDomainApi - axios parameter creator
 * @export
 */
export const DomainTypeDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainTypes: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/domains/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainTypeDomainApi - functional programming interface
 * @export
 */
export const DomainTypeDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainTypeDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainTypes(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainTypeDomainApi.getDomainTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainTypeDomainApi - factory interface
 * @export
 */
export const DomainTypeDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainTypeDomainApiFp(configuration)
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getDomainTypes(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainTypeDomainApi - object-oriented interface
 * @export
 * @class DomainTypeDomainApi
 * @extends {BaseAPI}
 */
export class DomainTypeDomainApi extends BaseAPI {
    /**
     * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
     * @summary Get Domain Types
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainTypeDomainApi
     */
    public getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return DomainTypeDomainApiFp(this.configuration).getDomainTypes(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ErrorLogDomainApi - axios parameter creator
 * @export
 */
export const ErrorLogDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorLogById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteErrorLogById', 'id', id)
            const localVarPath = `/api/domain/error-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getErrorLogById', 'id', id)
            const localVarPath = `/api/domain/error-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogs: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getErrorLogs', 'pageable', pageable)
            const localVarPath = `/api/domain/error-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorLogDomainApi - functional programming interface
 * @export
 */
export const ErrorLogDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorLogDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteErrorLogById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteErrorLogById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogDomainApi.deleteErrorLogById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorLogById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorLogById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogDomainApi.getErrorLogById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorLogs(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageErrorLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorLogs(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogDomainApi.getErrorLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ErrorLogDomainApi - factory interface
 * @export
 */
export const ErrorLogDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorLogDomainApiFp(configuration)
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorLogById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteErrorLogById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ErrorLogResponse> {
            return localVarFp.getErrorLogById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogs(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageErrorLogResponse> {
            return localVarFp.getErrorLogs(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorLogDomainApi - object-oriented interface
 * @export
 * @class ErrorLogDomainApi
 * @extends {BaseAPI}
 */
export class ErrorLogDomainApi extends BaseAPI {
    /**
     * Delete an error log by its ID. Requires ADMIN or user privileges.
     * @summary Delete Error Log by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogDomainApi
     */
    public deleteErrorLogById(id: string, options?: RawAxiosRequestConfig) {
        return ErrorLogDomainApiFp(this.configuration).deleteErrorLogById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an error log by its ID. Requires ADMIN privileges.
     * @summary Get Error Log by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogDomainApi
     */
    public getErrorLogById(id: string, options?: RawAxiosRequestConfig) {
        return ErrorLogDomainApiFp(this.configuration).getErrorLogById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of error logs. Requires ADMIN privileges.
     * @summary Get Error Logs
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogDomainApi
     */
    public getErrorLogs(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ErrorLogDomainApiFp(this.configuration).getErrorLogs(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExchangeDomainApi - axios parameter creator
 * @export
 */
export const ExchangeDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange: async (exchangeRequest: ExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('createExchange', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/api/domain/exchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExchange', 'id', id)
            const localVarPath = `/api/domain/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExchangeById', 'id', id)
            const localVarPath = `/api/domain/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/exchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange: async (id: number, exchangeRequest: ExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExchange', 'id', id)
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('updateExchange', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/api/domain/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeDomainApi - functional programming interface
 * @export
 */
export const ExchangeDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExchange(exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExchange(exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeDomainApi.createExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExchange(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExchange(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeDomainApi.deleteExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeDomainApi.getExchangeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchanges(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchanges(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeDomainApi.getExchanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchange(id, exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeDomainApi.updateExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExchangeDomainApi - factory interface
 * @export
 */
export const ExchangeDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeDomainApiFp(configuration)
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange(exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createExchange(exchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteExchange(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ExchangeResponse> {
            return localVarFp.getExchangeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getExchanges(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.updateExchange(id, exchangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeDomainApi - object-oriented interface
 * @export
 * @class ExchangeDomainApi
 * @extends {BaseAPI}
 */
export class ExchangeDomainApi extends BaseAPI {
    /**
     * Create a new exchange. Requires ADMIN or USER role.
     * @summary Create Exchange
     * @param {ExchangeRequest} exchangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeDomainApi
     */
    public createExchange(exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeDomainApiFp(this.configuration).createExchange(exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an exchange by its ID. Requires ADMIN or USER role.
     * @summary Delete Exchange
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeDomainApi
     */
    public deleteExchange(id: number, options?: RawAxiosRequestConfig) {
        return ExchangeDomainApiFp(this.configuration).deleteExchange(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an exchange by its unique identifier.
     * @summary Get exchange by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeDomainApi
     */
    public getExchangeById(id: number, options?: RawAxiosRequestConfig) {
        return ExchangeDomainApiFp(this.configuration).getExchangeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all exchanges.
     * @summary Get all exchanges
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeDomainApi
     */
    public getExchanges(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ExchangeDomainApiFp(this.configuration).getExchanges(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing exchange. Requires ADMIN or USER role.
     * @summary Update Exchange
     * @param {number} id 
     * @param {ExchangeRequest} exchangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeDomainApi
     */
    public updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeDomainApiFp(this.configuration).updateExchange(id, exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureAssociationDomainApi - axios parameter creator
 * @export
 */
export const FeatureAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureAssociation: async (featureAssociationRequest: FeatureAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureAssociationRequest' is not null or undefined
            assertParamExists('createFeatureAssociation', 'featureAssociationRequest', featureAssociationRequest)
            const localVarPath = `/api/domain/features/associations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeatureAssociation', 'id', id)
            const localVarPath = `/api/domain/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureAssociationById', 'id', id)
            const localVarPath = `/api/domain/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/features/associations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationsByMnaId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureAssociationsByMnaId', 'id', id)
            const localVarPath = `/api/domain/features/associations/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureAssociation: async (id: string, featureAssociationRequest: FeatureAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeatureAssociation', 'id', id)
            // verify required parameter 'featureAssociationRequest' is not null or undefined
            assertParamExists('updateFeatureAssociation', 'featureAssociationRequest', featureAssociationRequest)
            const localVarPath = `/api/domain/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureAssociationDomainApi - functional programming interface
 * @export
 */
export const FeatureAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureAssociation(featureAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.createFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.deleteFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.getFeatureAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.getFeatureAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociationsByMnaId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociationsByMnaId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.getFeatureAssociationsByMnaId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureAssociation(id: string, featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureAssociation(id, featureAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationDomainApi.updateFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureAssociationDomainApi - factory interface
 * @export
 */
export const FeatureAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureAssociationDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createFeatureAssociation(featureAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFeatureAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FeatureAssociationResponse> {
            return localVarFp.getFeatureAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getFeatureAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationsByMnaId(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureAssociationResponse>> {
            return localVarFp.getFeatureAssociationsByMnaId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureAssociation(id: string, featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateFeatureAssociation(id, featureAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureAssociationDomainApi - object-oriented interface
 * @export
 * @class FeatureAssociationDomainApi
 * @extends {BaseAPI}
 */
export class FeatureAssociationDomainApi extends BaseAPI {
    /**
     * 
     * @param {FeatureAssociationRequest} featureAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).createFeatureAssociation(featureAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public deleteFeatureAssociation(id: string, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).deleteFeatureAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public getFeatureAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).getFeatureAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).getFeatureAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public getFeatureAssociationsByMnaId(id: string, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).getFeatureAssociationsByMnaId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FeatureAssociationRequest} featureAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationDomainApi
     */
    public updateFeatureAssociation(id: string, featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig) {
        return FeatureAssociationDomainApiFp(this.configuration).updateFeatureAssociation(id, featureAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureDomainApi - axios parameter creator
 * @export
 */
export const FeatureDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (featureRequest: FeatureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureRequest' is not null or undefined
            assertParamExists('createFeature', 'featureRequest', featureRequest)
            const localVarPath = `/api/domain/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeature', 'id', id)
            const localVarPath = `/api/domain/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureById', 'id', id)
            const localVarPath = `/api/domain/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (id: string, featureRequest: FeatureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeature', 'id', id)
            // verify required parameter 'featureRequest' is not null or undefined
            assertParamExists('updateFeature', 'featureRequest', featureRequest)
            const localVarPath = `/api/domain/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureDomainApi - functional programming interface
 * @export
 */
export const FeatureDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(featureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureDomainApi.createFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureDomainApi.deleteFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureDomainApi.getFeatureById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureDomainApi.getFeatures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(id: string, featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(id, featureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureDomainApi.updateFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureDomainApi - factory interface
 * @export
 */
export const FeatureDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createFeature(featureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFeature(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.getFeatureById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getFeatures(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(id: string, featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateFeature(id, featureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureDomainApi - object-oriented interface
 * @export
 * @class FeatureDomainApi
 * @extends {BaseAPI}
 */
export class FeatureDomainApi extends BaseAPI {
    /**
     * 
     * @param {FeatureRequest} featureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureDomainApi
     */
    public createFeature(featureRequest: FeatureRequest, options?: RawAxiosRequestConfig) {
        return FeatureDomainApiFp(this.configuration).createFeature(featureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureDomainApi
     */
    public deleteFeature(id: string, options?: RawAxiosRequestConfig) {
        return FeatureDomainApiFp(this.configuration).deleteFeature(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureDomainApi
     */
    public getFeatureById(id: string, options?: RawAxiosRequestConfig) {
        return FeatureDomainApiFp(this.configuration).getFeatureById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureDomainApi
     */
    public getFeatures(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FeatureDomainApiFp(this.configuration).getFeatures(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FeatureRequest} featureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureDomainApi
     */
    public updateFeature(id: string, featureRequest: FeatureRequest, options?: RawAxiosRequestConfig) {
        return FeatureDomainApiFp(this.configuration).updateFeature(id, featureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileAssociationDomainApi - axios parameter creator
 * @export
 */
export const FileAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileAssociation: async (fileAssociationRequest: FileAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('createFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/domain/files/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiFileAssociation: async (fileAssociationRequest: Array<FileAssociationRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('createMultiFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/domain/files/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllFileAssociation: async (id: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAllFileAssociation', 'id', id)
            const localVarPath = `/api/domain/files/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFileAssociation', 'id', id)
            const localVarPath = `/api/domain/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileAssociationById', 'id', id)
            const localVarPath = `/api/domain/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociations: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/files/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {string} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileAssociation: async (id: string, fileAssociationRequest: FileAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFileAssociation', 'id', id)
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('updateFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/domain/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileAssociationDomainApi - functional programming interface
 * @export
 */
export const FileAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileAssociation(fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.createFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiFileAssociation(fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.createMultiFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllFileAssociation(id: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllFileAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.deleteAllFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.deleteFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.getFileAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileAssociations(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.getFileAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {string} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFileAssociation(id: string, fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFileAssociation(id, fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationDomainApi.updateFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileAssociationDomainApi - factory interface
 * @export
 */
export const FileAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileAssociationDomainApiFp(configuration)
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createFileAssociation(fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.createMultiFileAssociation(fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllFileAssociation(id: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAllFileAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFileAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FileAssociationResponse> {
            return localVarFp.getFileAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getFileAssociations(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {string} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileAssociation(id: string, fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateFileAssociation(id, fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileAssociationDomainApi - object-oriented interface
 * @export
 * @class FileAssociationDomainApi
 * @extends {BaseAPI}
 */
export class FileAssociationDomainApi extends BaseAPI {
    /**
     * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Create file association
     * @param {FileAssociationRequest} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).createFileAssociation(fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Create multiple file associations
     * @param {Array<FileAssociationRequest>} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).createMultiFileAssociation(fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Delete all file associations
     * @param {Array<string>} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public deleteAllFileAssociation(id: Array<string>, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).deleteAllFileAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Delete file association
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public deleteFileAssociation(id: string, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).deleteFileAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a file association by its ID.
     * @summary Retrieve file association by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public getFileAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).getFileAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves file associations with other domains in the system.
     * @summary Retrieve file associations
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).getFileAssociations(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Update file association
     * @param {string} id 
     * @param {FileAssociationRequest} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationDomainApi
     */
    public updateFileAssociation(id: string, fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig) {
        return FileAssociationDomainApiFp(this.configuration).updateFileAssociation(id, fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileDomainApi - axios parameter creator
 * @export
 */
export const FileDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            const localVarPath = `/api/domain/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadFile', 'id', id)
            const localVarPath = `/api/domain/files/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFiles: async (search?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/domain/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileDomainApi - functional programming interface
 * @export
 */
export const FileDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileDomainApi.deleteFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileDomainApi.downloadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFiles(search, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileDomainApi.findFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileDomainApi.uploadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileDomainApi - factory interface
 * @export
 */
export const FileDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileDomainApiFp(configuration)
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(id: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.downloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.findFiles(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.uploadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileDomainApi - object-oriented interface
 * @export
 * @class FileDomainApi
 * @extends {BaseAPI}
 */
export class FileDomainApi extends BaseAPI {
    /**
     * Deletes a file by its ID.
     * @summary Delete File
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDomainApi
     */
    public deleteFile(id: string, options?: RawAxiosRequestConfig) {
        return FileDomainApiFp(this.configuration).deleteFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Downloads a file by its ID.
     * @summary Download File
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDomainApi
     */
    public downloadFile(id: string, options?: RawAxiosRequestConfig) {
        return FileDomainApiFp(this.configuration).downloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDomainApi
     */
    public findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FileDomainApiFp(this.configuration).findFiles(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads a file to the system.
     * @summary Upload File
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDomainApi
     */
    public uploadFile(file: File, options?: RawAxiosRequestConfig) {
        return FileDomainApiFp(this.configuration).uploadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FundamentalDomainApi - axios parameter creator
 * @export
 */
export const FundamentalDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundamental: async (fundamentalRequest: FundamentalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundamentalRequest' is not null or undefined
            assertParamExists('createFundamental', 'fundamentalRequest', fundamentalRequest)
            const localVarPath = `/api/domain/fundamentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundamentalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundamental: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFundamental', 'id', id)
            const localVarPath = `/api/domain/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFundamentalById', 'id', id)
            const localVarPath = `/api/domain/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalNotes: async (id: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFundamentalNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getFundamentalNotes', 'pageable', pageable)
            const localVarPath = `/api/domain/fundamentals/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentals: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/fundamentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate fundamentals to elastic search
         * @summary Migrate fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateFundamentals: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/fundamentals/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partially update an existing Fundamental entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Fundamental
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateFundamental: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partiallyUpdateFundamental', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partiallyUpdateFundamental', 'body', body)
            const localVarPath = `/api/domain/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFundamentalByNameOrTicker: async (query: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchFundamentalByNameOrTicker', 'query', query)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('searchFundamentalByNameOrTicker', 'pageable', pageable)
            const localVarPath = `/api/domain/fundamentals/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {string} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundamental: async (id: string, fundamentalRequest: FundamentalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFundamental', 'id', id)
            // verify required parameter 'fundamentalRequest' is not null or undefined
            assertParamExists('updateFundamental', 'fundamentalRequest', fundamentalRequest)
            const localVarPath = `/api/domain/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundamentalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundamentalDomainApi - functional programming interface
 * @export
 */
export const FundamentalDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundamentalDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFundamental(fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFundamental(fundamentalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.createFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFundamental(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFundamental(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.deleteFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentalById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundamentalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentalById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.getFundamentalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentalNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentalNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.getFundamentalNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentals(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.getFundamentals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Migrate fundamentals to elastic search
         * @summary Migrate fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateFundamentals(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateFundamentals(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.migrateFundamentals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Partially update an existing Fundamental entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Fundamental
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partiallyUpdateFundamental(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partiallyUpdateFundamental(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.partiallyUpdateFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFundamentalByNameOrTicker(query, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.searchFundamentalByNameOrTicker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {string} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFundamental(id: string, fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFundamental(id, fundamentalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalDomainApi.updateFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FundamentalDomainApi - factory interface
 * @export
 */
export const FundamentalDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundamentalDomainApiFp(configuration)
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundamental(fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createFundamental(fundamentalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundamental(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFundamental(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FundamentalResponse> {
            return localVarFp.getFundamentalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getFundamentalNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getFundamentals(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate fundamentals to elastic search
         * @summary Migrate fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateFundamentals(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.migrateFundamentals(options).then((request) => request(axios, basePath));
        },
        /**
         * Partially update an existing Fundamental entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Fundamental
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateFundamental(id: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partiallyUpdateFundamental(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.searchFundamentalByNameOrTicker(query, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {string} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundamental(id: string, fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateFundamental(id, fundamentalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FundamentalDomainApi - object-oriented interface
 * @export
 * @class FundamentalDomainApi
 * @extends {BaseAPI}
 */
export class FundamentalDomainApi extends BaseAPI {
    /**
     * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Create Fundamental
     * @param {FundamentalRequest} fundamentalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public createFundamental(fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).createFundamental(fundamentalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Delete Fundamental
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public deleteFundamental(id: string, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).deleteFundamental(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the fundamental data for a company by its unique identifier.
     * @summary Get fundamental by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public getFundamentalById(id: string, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).getFundamentalById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a fundamental data.
     * @summary Get Fundamental Notes
     * @param {string} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public getFundamentalNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).getFundamentalNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of all fundamental data for companies.
     * @summary Get all fundamentals
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).getFundamentals(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate fundamentals to elastic search
     * @summary Migrate fundamentals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public migrateFundamentals(options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).migrateFundamentals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partially update an existing Fundamental entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Partially update Fundamental
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public partiallyUpdateFundamental(id: string, body: object, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).partiallyUpdateFundamental(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for companies based on their name or ticker symbol.
     * @summary Search fundamentals by name or ticker
     * @param {string} query 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).searchFundamentalByNameOrTicker(query, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Update Fundamental
     * @param {string} id 
     * @param {FundamentalRequest} fundamentalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalDomainApi
     */
    public updateFundamental(id: string, fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig) {
        return FundamentalDomainApiFp(this.configuration).updateFundamental(id, fundamentalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImpliedProbabilityDomainApi - axios parameter creator
 * @export
 */
export const ImpliedProbabilityDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImpliedProbability: async (impliedProbabilityRequest: ImpliedProbabilityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'impliedProbabilityRequest' is not null or undefined
            assertParamExists('createImpliedProbability', 'impliedProbabilityRequest', impliedProbabilityRequest)
            const localVarPath = `/api/domain/implied-probabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impliedProbabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpliedProbability: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImpliedProbability', 'id', id)
            const localVarPath = `/api/domain/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImpliedProbByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllImpliedProbByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllImpliedProbByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/implied-probabilities/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getImpliedProbCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getImpliedProbCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/implied-probabilities/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilities: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/implied-probabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilityById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImpliedProbabilityById', 'id', id)
            const localVarPath = `/api/domain/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpliedProbability: async (id: string, impliedProbabilityRequest: ImpliedProbabilityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateImpliedProbability', 'id', id)
            // verify required parameter 'impliedProbabilityRequest' is not null or undefined
            assertParamExists('updateImpliedProbability', 'impliedProbabilityRequest', impliedProbabilityRequest)
            const localVarPath = `/api/domain/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impliedProbabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImpliedProbabilityDomainApi - functional programming interface
 * @export
 */
export const ImpliedProbabilityDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImpliedProbabilityDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImpliedProbability(impliedProbabilityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.createImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImpliedProbability(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImpliedProbability(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.deleteImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.getAllImpliedProbByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.getImpliedProbCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbabilities(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.getImpliedProbabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbabilityById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImpliedProbabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbabilityById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.getImpliedProbabilityById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImpliedProbability(id: string, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImpliedProbability(id, impliedProbabilityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityDomainApi.updateImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImpliedProbabilityDomainApi - factory interface
 * @export
 */
export const ImpliedProbabilityDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImpliedProbabilityDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createImpliedProbability(impliedProbabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpliedProbability(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteImpliedProbability(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getImpliedProbCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getImpliedProbabilities(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilityById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ImpliedProbabilityResponse> {
            return localVarFp.getImpliedProbabilityById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpliedProbability(id: string, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateImpliedProbability(id, impliedProbabilityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImpliedProbabilityDomainApi - object-oriented interface
 * @export
 * @class ImpliedProbabilityDomainApi
 * @extends {BaseAPI}
 */
export class ImpliedProbabilityDomainApi extends BaseAPI {
    /**
     * 
     * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).createImpliedProbability(impliedProbabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public deleteImpliedProbability(id: string, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).deleteImpliedProbability(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).getImpliedProbCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).getImpliedProbabilities(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public getImpliedProbabilityById(id: string, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).getImpliedProbabilityById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityDomainApi
     */
    public updateImpliedProbability(id: string, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityDomainApiFp(this.configuration).updateImpliedProbability(id, impliedProbabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkAssociationDomainApi - axios parameter creator
 * @export
 */
export const LinkAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkAssociation: async (linkAssociationRequest: LinkAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkAssociationRequest' is not null or undefined
            assertParamExists('createLinkAssociation', 'linkAssociationRequest', linkAssociationRequest)
            const localVarPath = `/api/domain/link/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLinkAssociation', 'id', id)
            const localVarPath = `/api/domain/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId2: async (entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId2', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId2', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/domain/link/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkAssociationById', 'id', id)
            const localVarPath = `/api/domain/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/link/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {string} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkAssociation: async (id: string, linkAssociationRequest: LinkAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLinkAssociation', 'id', id)
            // verify required parameter 'linkAssociationRequest' is not null or undefined
            assertParamExists('updateLinkAssociation', 'linkAssociationRequest', linkAssociationRequest)
            const localVarPath = `/api/domain/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkAssociationDomainApi - functional programming interface
 * @export
 */
export const LinkAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkAssociation(linkAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.createLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.deleteLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId2(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageLinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.findByEntityIdAndDomainTypeId2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.getLinkAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageLinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.getLinkAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {string} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkAssociation(id: string, linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkAssociation(id, linkAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationDomainApi.updateLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkAssociationDomainApi - factory interface
 * @export
 */
export const LinkAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkAssociationDomainApiFp(configuration)
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createLinkAssociation(linkAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLinkAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId2(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageLinkAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<LinkAssociationResponse> {
            return localVarFp.getLinkAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageLinkAssociationResponse> {
            return localVarFp.getLinkAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {string} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkAssociation(id: string, linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLinkAssociation(id, linkAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkAssociationDomainApi - object-oriented interface
 * @export
 * @class LinkAssociationDomainApi
 * @extends {BaseAPI}
 */
export class LinkAssociationDomainApi extends BaseAPI {
    /**
     * Creates a new association between a link and other domains. Requires ADMIN or USER role.
     * @summary Create a new link association
     * @param {LinkAssociationRequest} linkAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).createLinkAssociation(linkAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a link and other domains. Requires ADMIN or USER role.
     * @summary Delete a link association
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public deleteLinkAssociation(id: string, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).deleteLinkAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {string} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public findByEntityIdAndDomainTypeId2(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a link association by its unique identifier.
     * @summary Get link association by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public getLinkAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).getLinkAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between links and other domains.
     * @summary Get all link associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).getLinkAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
     * @summary Update an existing link association
     * @param {string} id 
     * @param {LinkAssociationRequest} linkAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationDomainApi
     */
    public updateLinkAssociation(id: string, linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig) {
        return LinkAssociationDomainApiFp(this.configuration).updateLinkAssociation(id, linkAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkCategoryDomainApi - axios parameter creator
 * @export
 */
export const LinkCategoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCategory: async (linkCategoryRequest: LinkCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkCategoryRequest' is not null or undefined
            assertParamExists('createLinkCategory', 'linkCategoryRequest', linkCategoryRequest)
            const localVarPath = `/api/domain/links/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLinkCategory', 'id', id)
            const localVarPath = `/api/domain/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/links/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkCategoryById', 'id', id)
            const localVarPath = `/api/domain/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkCategory: async (id: number, linkCategoryRequest: LinkCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLinkCategory', 'id', id)
            // verify required parameter 'linkCategoryRequest' is not null or undefined
            assertParamExists('updateLinkCategory', 'linkCategoryRequest', linkCategoryRequest)
            const localVarPath = `/api/domain/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkCategoryDomainApi - functional programming interface
 * @export
 */
export const LinkCategoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkCategoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkCategory(linkCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryDomainApi.createLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryDomainApi.deleteLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryDomainApi.getLinkCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryDomainApi.getLinkCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkCategory(id, linkCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryDomainApi.updateLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkCategoryDomainApi - factory interface
 * @export
 */
export const LinkCategoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkCategoryDomainApiFp(configuration)
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createLinkCategory(linkCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkCategory(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLinkCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getLinkCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategoryById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<LinkCategoryResponse> {
            return localVarFp.getLinkCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLinkCategory(id, linkCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkCategoryDomainApi - object-oriented interface
 * @export
 * @class LinkCategoryDomainApi
 * @extends {BaseAPI}
 */
export class LinkCategoryDomainApi extends BaseAPI {
    /**
     * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Create a new link category
     * @param {LinkCategoryRequest} linkCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryDomainApi
     */
    public createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig) {
        return LinkCategoryDomainApiFp(this.configuration).createLinkCategory(linkCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Delete a link category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryDomainApi
     */
    public deleteLinkCategory(id: number, options?: RawAxiosRequestConfig) {
        return LinkCategoryDomainApiFp(this.configuration).deleteLinkCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of all link categories.
     * @summary Retrieve all link categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryDomainApi
     */
    public getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkCategoryDomainApiFp(this.configuration).getLinkCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the details of a specific link category by its ID.
     * @summary Retrieve a link category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryDomainApi
     */
    public getLinkCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return LinkCategoryDomainApiFp(this.configuration).getLinkCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Update a link category
     * @param {number} id 
     * @param {LinkCategoryRequest} linkCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryDomainApi
     */
    public updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig) {
        return LinkCategoryDomainApiFp(this.configuration).updateLinkCategory(id, linkCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkDomainApi - axios parameter creator
 * @export
 */
export const LinkDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink: async (linkRequest: LinkRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkRequest' is not null or undefined
            assertParamExists('createLink', 'linkRequest', linkRequest)
            const localVarPath = `/api/domain/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLink', 'id', id)
            const localVarPath = `/api/domain/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkById', 'id', id)
            const localVarPath = `/api/domain/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {string} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink: async (id: string, linkRequest: LinkRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLink', 'id', id)
            // verify required parameter 'linkRequest' is not null or undefined
            assertParamExists('updateLink', 'linkRequest', linkRequest)
            const localVarPath = `/api/domain/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkDomainApi - functional programming interface
 * @export
 */
export const LinkDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLink(linkRequest: LinkRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLink(linkRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkDomainApi.createLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLink(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkDomainApi.deleteLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkDomainApi.getLinkById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinks(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkDomainApi.getLinks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {string} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLink(id: string, linkRequest: LinkRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLink(id, linkRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkDomainApi.updateLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkDomainApi - factory interface
 * @export
 */
export const LinkDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkDomainApiFp(configuration)
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(linkRequest: LinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createLink(linkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<LinkResponse> {
            return localVarFp.getLinkById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getLinks(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {string} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink(id: string, linkRequest: LinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLink(id, linkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkDomainApi - object-oriented interface
 * @export
 * @class LinkDomainApi
 * @extends {BaseAPI}
 */
export class LinkDomainApi extends BaseAPI {
    /**
     * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Create a new link
     * @param {LinkRequest} linkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkDomainApi
     */
    public createLink(linkRequest: LinkRequest, options?: RawAxiosRequestConfig) {
        return LinkDomainApiFp(this.configuration).createLink(linkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Delete a link
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkDomainApi
     */
    public deleteLink(id: string, options?: RawAxiosRequestConfig) {
        return LinkDomainApiFp(this.configuration).deleteLink(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a link by its unique identifier. 
     * @summary Retrieve link by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkDomainApi
     */
    public getLinkById(id: string, options?: RawAxiosRequestConfig) {
        return LinkDomainApiFp(this.configuration).getLinkById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
     * @summary Retrieve paginated list of links
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkDomainApi
     */
    public getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkDomainApiFp(this.configuration).getLinks(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Update an existing link
     * @param {string} id 
     * @param {LinkRequest} linkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkDomainApi
     */
    public updateLink(id: string, linkRequest: LinkRequest, options?: RawAxiosRequestConfig) {
        return LinkDomainApiFp(this.configuration).updateLink(id, linkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaCategoryDomainApi - axios parameter creator
 * @export
 */
export const MnaCategoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaCategory: async (mnaCategoryRequest: MnaCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaCategoryRequest' is not null or undefined
            assertParamExists('createMnaCategory', 'mnaCategoryRequest', mnaCategoryRequest)
            const localVarPath = `/api/domain/mnas/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMnaCategory', 'id', id)
            const localVarPath = `/api/domain/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mnas/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaCategoryById', 'id', id)
            const localVarPath = `/api/domain/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaCategory: async (id: number, mnaCategoryRequest: MnaCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMnaCategory', 'id', id)
            // verify required parameter 'mnaCategoryRequest' is not null or undefined
            assertParamExists('updateMnaCategory', 'mnaCategoryRequest', mnaCategoryRequest)
            const localVarPath = `/api/domain/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaCategoryDomainApi - functional programming interface
 * @export
 */
export const MnaCategoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaCategoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMnaCategory(mnaCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryDomainApi.createMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMnaCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMnaCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryDomainApi.deleteMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryDomainApi.getMnaCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnaCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryDomainApi.getMnaCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMnaCategory(id, mnaCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryDomainApi.updateMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaCategoryDomainApi - factory interface
 * @export
 */
export const MnaCategoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaCategoryDomainApiFp(configuration)
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createMnaCategory(mnaCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaCategory(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMnaCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getMnaCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategoryById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MnaCategoryResponse> {
            return localVarFp.getMnaCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.updateMnaCategory(id, mnaCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaCategoryDomainApi - object-oriented interface
 * @export
 * @class MnaCategoryDomainApi
 * @extends {BaseAPI}
 */
export class MnaCategoryDomainApi extends BaseAPI {
    /**
     * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
     * @summary Create MNA category
     * @param {MnaCategoryRequest} mnaCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryDomainApi
     */
    public createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaCategoryDomainApiFp(this.configuration).createMnaCategory(mnaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
     * @summary Delete MNA category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryDomainApi
     */
    public deleteMnaCategory(id: number, options?: RawAxiosRequestConfig) {
        return MnaCategoryDomainApiFp(this.configuration).deleteMnaCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve all MNA categories.
     * @summary Retrieve all MNA categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryDomainApi
     */
    public getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaCategoryDomainApiFp(this.configuration).getMnaCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve an MNA category by its ID.
     * @summary Retrieve MNA category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryDomainApi
     */
    public getMnaCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return MnaCategoryDomainApiFp(this.configuration).getMnaCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
     * @summary Update MNA category
     * @param {number} id 
     * @param {MnaCategoryRequest} mnaCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryDomainApi
     */
    public updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaCategoryDomainApiFp(this.configuration).updateMnaCategory(id, mnaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaDomainApi - axios parameter creator
 * @export
 */
export const MnaDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMna: async (mnaRequest: MnaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaRequest' is not null or undefined
            assertParamExists('createMna', 'mnaRequest', mnaRequest)
            const localVarPath = `/api/domain/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMnasByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllMnasByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllMnasByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/mnas/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaById', 'id', id)
            const localVarPath = `/api/domain/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaNotes: async (id: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMnaNotes', 'pageable', pageable)
            const localVarPath = `/api/domain/mnas/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnas: async (search?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnasCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMnasCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMnasCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/mnas/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate MNAs to elastic search
         * @summary Migrate MNA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateMnaToElasticSearch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mnas/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partially update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update MNA
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateMna: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partiallyUpdateMna', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partiallyUpdateMna', 'body', body)
            const localVarPath = `/api/domain/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {string} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMna: async (id: string, mnaRequest: MnaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMna', 'id', id)
            // verify required parameter 'mnaRequest' is not null or undefined
            assertParamExists('updateMna', 'mnaRequest', mnaRequest)
            const localVarPath = `/api/domain/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaDomainApi - functional programming interface
 * @export
 */
export const MnaDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMna(mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMna(mnaRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.createMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMnasByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.getAllMnasByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.getMnaById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.getMnaNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnas(search, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.getMnas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnasCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnasCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.getMnasCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Migrate MNAs to elastic search
         * @summary Migrate MNA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateMnaToElasticSearch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateMnaToElasticSearch(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.migrateMnaToElasticSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Partially update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update MNA
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partiallyUpdateMna(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partiallyUpdateMna(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.partiallyUpdateMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {string} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMna(id: string, mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMna(id, mnaRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaDomainApi.updateMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaDomainApi - factory interface
 * @export
 */
export const MnaDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaDomainApiFp(configuration)
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMna(mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createMna(mnaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllMnasByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<MnaResponse> {
            return localVarFp.getMnaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getMnaNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getMnas(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnasCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getMnasCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate MNAs to elastic search
         * @summary Migrate MNA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateMnaToElasticSearch(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.migrateMnaToElasticSearch(options).then((request) => request(axios, basePath));
        },
        /**
         * Partially update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update MNA
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateMna(id: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partiallyUpdateMna(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {string} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMna(id: string, mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateMna(id, mnaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaDomainApi - object-oriented interface
 * @export
 * @class MnaDomainApi
 * @extends {BaseAPI}
 */
export class MnaDomainApi extends BaseAPI {
    /**
     * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Create MNA
     * @param {MnaRequest} mnaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public createMna(mnaRequest: MnaRequest, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).createMna(mnaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The start date for the period to retrieve MNA records from.
     * @param {string} endDate The end date for the period to retrieve MNA records up to.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).getAllMnasByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve MNA data by its unique identifier.
     * @summary Get MNA by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public getMnaById(id: string, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).getMnaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a mna data.
     * @summary Get Mna Notes
     * @param {string} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public getMnaNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).getMnaNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all MNAs with optional search
     * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).getMnas(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The start date for the period to count MNA records from.
     * @param {string} endDate The end date for the period to count MNA records up to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public getMnasCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).getMnasCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate MNAs to elastic search
     * @summary Migrate MNA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public migrateMnaToElasticSearch(options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).migrateMnaToElasticSearch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partially update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Partially update MNA
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public partiallyUpdateMna(id: string, body: object, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).partiallyUpdateMna(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Update MNA
     * @param {string} id 
     * @param {MnaRequest} mnaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaDomainApi
     */
    public updateMna(id: string, mnaRequest: MnaRequest, options?: RawAxiosRequestConfig) {
        return MnaDomainApiFp(this.configuration).updateMna(id, mnaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaHistoryDomainApi - axios parameter creator
 * @export
 */
export const MnaHistoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistory: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaHistory', 'id', id)
            const localVarPath = `/api/domain/mnas/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistoryBetweenDates: async (id: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'endDate', endDate)
            const localVarPath = `/api/domain/mnas/history/{id}/between-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaHistoryDomainApi - functional programming interface
 * @export
 */
export const MnaHistoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaHistoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaHistory(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainChangeByProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaHistoryDomainApi.getMnaHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainPropertyChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaHistoryBetweenDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaHistoryDomainApi.getMnaHistoryBetweenDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaHistoryDomainApi - factory interface
 * @export
 */
export const MnaHistoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaHistoryDomainApiFp(configuration)
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistory(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DomainChangeByProperty> {
            return localVarFp.getMnaHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DomainPropertyChange>> {
            return localVarFp.getMnaHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaHistoryDomainApi - object-oriented interface
 * @export
 * @class MnaHistoryDomainApi
 * @extends {BaseAPI}
 */
export class MnaHistoryDomainApi extends BaseAPI {
    /**
     * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
     * @summary Get MNA History
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaHistoryDomainApi
     */
    public getMnaHistory(id: string, options?: RawAxiosRequestConfig) {
        return MnaHistoryDomainApiFp(this.configuration).getMnaHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
     * @summary Get MNA History Between Dates
     * @param {string} id 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaHistoryDomainApi
     */
    public getMnaHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MnaHistoryDomainApiFp(this.configuration).getMnaHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaViewApi - axios parameter creator
 * @export
 */
export const MnaViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new view MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create view MNA
         * @param {MnaCreateRequest} mnaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewMna: async (mnaCreateRequest: MnaCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaCreateRequest' is not null or undefined
            assertParamExists('createViewMna', 'mnaCreateRequest', mnaCreateRequest)
            const localVarPath = `/api/view/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaViewApi - functional programming interface
 * @export
 */
export const MnaViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaViewApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new view MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create view MNA
         * @param {MnaCreateRequest} mnaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewMna(mnaCreateRequest: MnaCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewMna(mnaCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaViewApi.createViewMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaViewApi - factory interface
 * @export
 */
export const MnaViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaViewApiFp(configuration)
    return {
        /**
         * Create a new view MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create view MNA
         * @param {MnaCreateRequest} mnaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewMna(mnaCreateRequest: MnaCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createViewMna(mnaCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaViewApi - object-oriented interface
 * @export
 * @class MnaViewApi
 * @extends {BaseAPI}
 */
export class MnaViewApi extends BaseAPI {
    /**
     * Create a new view MNA entity. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Create view MNA
     * @param {MnaCreateRequest} mnaCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaViewApi
     */
    public createViewMna(mnaCreateRequest: MnaCreateRequest, options?: RawAxiosRequestConfig) {
        return MnaViewApiFp(this.configuration).createViewMna(mnaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MultiStepFormDomainApi - axios parameter creator
 * @export
 */
export const MultiStepFormDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetId: async (domainUri: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('createMultiStepFormGetId', 'domainUri', domainUri)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMultiStepFormGetId', 'body', body)
            const localVarPath = `/api/domain/{domainUri}/multistep`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetObject: async (domainUri: string, page: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'domainUri', domainUri)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'page', page)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'body', body)
            const localVarPath = `/api/domain/{domainUri}/multistep/page/{page}`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetId: async (domainUri: string, id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'domainUri', domainUri)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'body', body)
            const localVarPath = `/api/domain/{domainUri}/{id}/multistep`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {string} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetObject: async (domainUri: string, id: string, page: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'domainUri', domainUri)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'page', page)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'body', body)
            const localVarPath = `/api/domain/{domainUri}/{id}/multistep/page/{page}`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultiStepFormDomainApi - functional programming interface
 * @export
 */
export const MultiStepFormDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultiStepFormDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiStepFormGetId(domainUri: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiStepFormGetId(domainUri, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepFormDomainApi.createMultiStepFormGetId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiStepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiStepFormGetObject(domainUri, page, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepFormDomainApi.createMultiStepFormGetObject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMultiStepFormGetId(domainUri: string, id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMultiStepFormGetId(domainUri, id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepFormDomainApi.updateMultiStepFormGetId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {string} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMultiStepFormGetObject(domainUri: string, id: string, page: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiStepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMultiStepFormGetObject(domainUri, id, page, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepFormDomainApi.updateMultiStepFormGetObject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MultiStepFormDomainApi - factory interface
 * @export
 */
export const MultiStepFormDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultiStepFormDomainApiFp(configuration)
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetId(domainUri: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createMultiStepFormGetId(domainUri, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: RawAxiosRequestConfig): AxiosPromise<MultiStepResponse> {
            return localVarFp.createMultiStepFormGetObject(domainUri, page, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetId(domainUri: string, id: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateMultiStepFormGetId(domainUri, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {string} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetObject(domainUri: string, id: string, page: number, body: object, options?: RawAxiosRequestConfig): AxiosPromise<MultiStepResponse> {
            return localVarFp.updateMultiStepFormGetObject(domainUri, id, page, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultiStepFormDomainApi - object-oriented interface
 * @export
 * @class MultiStepFormDomainApi
 * @extends {BaseAPI}
 */
export class MultiStepFormDomainApi extends BaseAPI {
    /**
     * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
     * @summary Update Multi-Step Form
     * @param {string} domainUri 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepFormDomainApi
     */
    public createMultiStepFormGetId(domainUri: string, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepFormDomainApiFp(this.configuration).createMultiStepFormGetId(domainUri, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
     * @summary Create Multi-Step Form Page
     * @param {string} domainUri 
     * @param {number} page 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepFormDomainApi
     */
    public createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepFormDomainApiFp(this.configuration).createMultiStepFormGetObject(domainUri, page, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
     * @summary Update Multi-Step Form
     * @param {string} domainUri 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepFormDomainApi
     */
    public updateMultiStepFormGetId(domainUri: string, id: string, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepFormDomainApiFp(this.configuration).updateMultiStepFormGetId(domainUri, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
     * @summary Update Multi-Step Form Page
     * @param {string} domainUri 
     * @param {string} id 
     * @param {number} page 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepFormDomainApi
     */
    public updateMultiStepFormGetObject(domainUri: string, id: string, page: number, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepFormDomainApiFp(this.configuration).updateMultiStepFormGetObject(domainUri, id, page, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteAssociationDomainApi - axios parameter creator
 * @export
 */
export const NoteAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteAssociation: async (noteAssociationRequest: NoteAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteAssociationRequest' is not null or undefined
            assertParamExists('createNoteAssociation', 'noteAssociationRequest', noteAssociationRequest)
            const localVarPath = `/api/domain/notes/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNoteAssociation', 'id', id)
            const localVarPath = `/api/domain/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId1: async (entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId1', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId1', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/domain/notes/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteAssociationById', 'id', id)
            const localVarPath = `/api/domain/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/notes/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Runs the note classifier
         * @summary Runs the note classifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNoteClassifier: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/notes/assoc/classify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDomain: async (query: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchDomain', 'query', query)
            const localVarPath = `/api/domain/notes/assoc/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {string} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteAssociation: async (id: string, noteAssociationRequest: NoteAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteAssociation', 'id', id)
            // verify required parameter 'noteAssociationRequest' is not null or undefined
            assertParamExists('updateNoteAssociation', 'noteAssociationRequest', noteAssociationRequest)
            const localVarPath = `/api/domain/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteAssociationDomainApi - functional programming interface
 * @export
 */
export const NoteAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteAssociation(noteAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.createNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNoteAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNoteAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.deleteNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId1(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageNoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.findByEntityIdAndDomainTypeId1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.getNoteAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageNoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.getNoteAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Runs the note classifier
         * @summary Runs the note classifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runNoteClassifier(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runNoteClassifier(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.runNoteClassifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDomain(query: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDomain(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.searchDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {string} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteAssociation(id: string, noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteAssociation(id, noteAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationDomainApi.updateNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteAssociationDomainApi - factory interface
 * @export
 */
export const NoteAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteAssociationDomainApiFp(configuration)
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createNoteAssociation(noteAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNoteAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId1(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageNoteAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<NoteAssociationResponse> {
            return localVarFp.getNoteAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageNoteAssociationResponse> {
            return localVarFp.getNoteAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Runs the note classifier
         * @summary Runs the note classifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNoteClassifier(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.runNoteClassifier(options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDomain(query: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyData>> {
            return localVarFp.searchDomain(query, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {string} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteAssociation(id: string, noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateNoteAssociation(id, noteAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteAssociationDomainApi - object-oriented interface
 * @export
 * @class NoteAssociationDomainApi
 * @extends {BaseAPI}
 */
export class NoteAssociationDomainApi extends BaseAPI {
    /**
     * Creates a new association between a note and other domains. Requires ADMIN or USER role.
     * @summary Create a new note association
     * @param {NoteAssociationRequest} noteAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).createNoteAssociation(noteAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a note and other domains. Requires ADMIN or USER role.
     * @summary Delete a note association
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public deleteNoteAssociation(id: string, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).deleteNoteAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {string} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public findByEntityIdAndDomainTypeId1(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a note association by its unique identifier.
     * @summary Get note association by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public getNoteAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).getNoteAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between notes and other domains.
     * @summary Get all note associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).getNoteAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Runs the note classifier
     * @summary Runs the note classifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public runNoteClassifier(options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).runNoteClassifier(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
     * @summary Search domain
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public searchDomain(query: string, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).searchDomain(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
     * @summary Update an existing note association
     * @param {string} id 
     * @param {NoteAssociationRequest} noteAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationDomainApi
     */
    public updateNoteAssociation(id: string, noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig) {
        return NoteAssociationDomainApiFp(this.configuration).updateNoteAssociation(id, noteAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteDomainApi - axios parameter creator
 * @export
 */
export const NoteDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (noteRequest: NoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteRequest' is not null or undefined
            assertParamExists('createNote', 'noteRequest', noteRequest)
            const localVarPath = `/api/domain/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNote', 'id', id)
            const localVarPath = `/api/domain/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotesByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllNotesByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllNotesByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/notes/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteById', 'id', id)
            const localVarPath = `/api/domain/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getNotesCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getNotesCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/notes/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate Notes to elastic search
         * @summary Migrate Notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateNoteToElasticSearch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/notes/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply partial update on note
         * @summary Apply partial update on note
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOnNote: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateOnNote', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partialUpdateOnNote', 'body', body)
            const localVarPath = `/api/domain/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {string} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (id: string, noteRequest: NoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNote', 'id', id)
            // verify required parameter 'noteRequest' is not null or undefined
            assertParamExists('updateNote', 'noteRequest', noteRequest)
            const localVarPath = `/api/domain/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteDomainApi - functional programming interface
 * @export
 */
export const NoteDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(noteRequest: NoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(noteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.createNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.deleteNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNotesByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNotesByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.getAllNotesByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.getNoteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotes(search, format, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.getNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotesCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotesCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.getNotesCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Migrate Notes to elastic search
         * @summary Migrate Notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateNoteToElasticSearch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateNoteToElasticSearch(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.migrateNoteToElasticSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Apply partial update on note
         * @summary Apply partial update on note
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateOnNote(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOnNote(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.partialUpdateOnNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {string} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(id: string, noteRequest: NoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(id, noteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteDomainApi.updateNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteDomainApi - factory interface
 * @export
 */
export const NoteDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteDomainApiFp(configuration)
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(noteRequest: NoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createNote(noteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotesByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllNotesByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<NoteResponse> {
            return localVarFp.getNoteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getNotes(search, format, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getNotesCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate Notes to elastic search
         * @summary Migrate Notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateNoteToElasticSearch(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.migrateNoteToElasticSearch(options).then((request) => request(axios, basePath));
        },
        /**
         * Apply partial update on note
         * @summary Apply partial update on note
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOnNote(id: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partialUpdateOnNote(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {string} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(id: string, noteRequest: NoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateNote(id, noteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteDomainApi - object-oriented interface
 * @export
 * @class NoteDomainApi
 * @extends {BaseAPI}
 */
export class NoteDomainApi extends BaseAPI {
    /**
     * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Create a new note
     * @param {NoteRequest} noteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public createNote(noteRequest: NoteRequest, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).createNote(noteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Delete a note
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public deleteNote(id: string, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).deleteNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a paginated list of notes that have been modified between the specified start and end dates.
     * @summary Get notes modified between specified dates
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public getAllNotesByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).getAllNotesByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a note specified by its ID.
     * @summary Get note by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public getNoteById(id: string, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).getNoteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
     * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).getNotes(search, format, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the count of notes that have been modified between the specified start and end dates.
     * @summary Get count of notes modified between specified dates
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public getNotesCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).getNotesCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate Notes to elastic search
     * @summary Migrate Notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public migrateNoteToElasticSearch(options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).migrateNoteToElasticSearch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply partial update on note
     * @summary Apply partial update on note
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public partialUpdateOnNote(id: string, body: object, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).partialUpdateOnNote(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Update an existing note
     * @param {string} id 
     * @param {NoteRequest} noteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteDomainApi
     */
    public updateNote(id: string, noteRequest: NoteRequest, options?: RawAxiosRequestConfig) {
        return NoteDomainApiFp(this.configuration).updateNote(id, noteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteHistoryDomainApi - axios parameter creator
 * @export
 */
export const NoteHistoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistory: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteHistory', 'id', id)
            const localVarPath = `/api/domain/notes/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryBetweenDates: async (id: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'endDate', endDate)
            const localVarPath = `/api/domain/notes/history/{id}/between-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteHistoryDomainApi - functional programming interface
 * @export
 */
export const NoteHistoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteHistoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteHistory(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteHistoryDomainApi.getNoteHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainPropertyChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistoryBetweenDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteHistoryDomainApi.getNoteHistoryBetweenDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteHistoryDomainApi - factory interface
 * @export
 */
export const NoteHistoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteHistoryDomainApiFp(configuration)
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistory(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getNoteHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DomainPropertyChange>> {
            return localVarFp.getNoteHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteHistoryDomainApi - object-oriented interface
 * @export
 * @class NoteHistoryDomainApi
 * @extends {BaseAPI}
 */
export class NoteHistoryDomainApi extends BaseAPI {
    /**
     * Retrieve the history of changes for a note by its ID.
     * @summary Get Note History
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteHistoryDomainApi
     */
    public getNoteHistory(id: string, options?: RawAxiosRequestConfig) {
        return NoteHistoryDomainApiFp(this.configuration).getNoteHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the history of changes for a note between specified dates.
     * @summary Get Note History Between Dates
     * @param {string} id 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteHistoryDomainApi
     */
    public getNoteHistoryBetweenDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteHistoryDomainApiFp(this.configuration).getNoteHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteSourceTypeDomainApi - axios parameter creator
 * @export
 */
export const NoteSourceTypeDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteSourceType: async (noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteSourceTypeCreateRequest' is not null or undefined
            assertParamExists('createNoteSourceType', 'noteSourceTypeCreateRequest', noteSourceTypeCreateRequest)
            const localVarPath = `/api/domain/notes/sources/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteSourceTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteSourceType: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNoteSourceType', 'id', id)
            const localVarPath = `/api/domain/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceType: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/notes/sources/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteSourceTypeById', 'id', id)
            const localVarPath = `/api/domain/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteSourceType: async (id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteSourceType', 'id', id)
            // verify required parameter 'noteSourceTypeCreateRequest' is not null or undefined
            assertParamExists('updateNoteSourceType', 'noteSourceTypeCreateRequest', noteSourceTypeCreateRequest)
            const localVarPath = `/api/domain/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteSourceTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteSourceTypeDomainApi - functional programming interface
 * @export
 */
export const NoteSourceTypeDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteSourceTypeDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteSourceType(noteSourceTypeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeDomainApi.createNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNoteSourceType(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNoteSourceType(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeDomainApi.deleteNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteSourceType(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeDomainApi.getNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteSourceTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteSourceTypeCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteSourceTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeDomainApi.getNoteSourceTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteSourceType(id, noteSourceTypeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeDomainApi.updateNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteSourceTypeDomainApi - factory interface
 * @export
 */
export const NoteSourceTypeDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteSourceTypeDomainApiFp(configuration)
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createNoteSourceType(noteSourceTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteSourceType(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNoteSourceType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getNoteSourceType(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceTypeById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<NoteSourceTypeCreateResponse> {
            return localVarFp.getNoteSourceTypeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateNoteSourceType(id, noteSourceTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteSourceTypeDomainApi - object-oriented interface
 * @export
 * @class NoteSourceTypeDomainApi
 * @extends {BaseAPI}
 */
export class NoteSourceTypeDomainApi extends BaseAPI {
    /**
     * Create a new note source type with the provided details. Requires ADMIN or USER role.
     * @summary Create a new note source type
     * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeDomainApi
     */
    public createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeDomainApiFp(this.configuration).createNoteSourceType(noteSourceTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a note source type by its unique ID. Requires ADMIN or USER role.
     * @summary Delete a note source type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeDomainApi
     */
    public deleteNoteSourceType(id: number, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeDomainApiFp(this.configuration).deleteNoteSourceType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all note source types.
     * @summary Get all note source types
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeDomainApi
     */
    public getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeDomainApiFp(this.configuration).getNoteSourceType(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a note source type by its unique ID.
     * @summary Get a note source type by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeDomainApi
     */
    public getNoteSourceTypeById(id: number, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeDomainApiFp(this.configuration).getNoteSourceTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing note source type with the provided details. Requires ADMIN or USER role.
     * @summary Update an existing note source type
     * @param {number} id 
     * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeDomainApi
     */
    public updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeDomainApiFp(this.configuration).updateNoteSourceType(id, noteSourceTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordResetViewApi - axios parameter creator
 * @export
 */
export const PasswordResetViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword', 'email', email)
            const localVarPath = `/api/view/users/password-reset/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (token: string, passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updatePassword', 'token', token)
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('updatePassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/api/view/users/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validateToken', 'token', token)
            const localVarPath = `/api/view/users/password-reset/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetViewApi - functional programming interface
 * @export
 */
export const PasswordResetViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetViewApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(token, passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetViewApi.updatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateToken(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateToken(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetViewApi.validateToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PasswordResetViewApi - factory interface
 * @export
 */
export const PasswordResetViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetViewApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(email: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.resetPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PasswordResetResponse> {
            return localVarFp.updatePassword(token, passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(token: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.validateToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetViewApi - object-oriented interface
 * @export
 * @class PasswordResetViewApi
 * @extends {BaseAPI}
 */
export class PasswordResetViewApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetViewApi
     */
    public resetPassword(email: string, options?: RawAxiosRequestConfig) {
        return PasswordResetViewApiFp(this.configuration).resetPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetViewApi
     */
    public updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig) {
        return PasswordResetViewApiFp(this.configuration).updatePassword(token, passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetViewApi
     */
    public validateToken(token: string, options?: RawAxiosRequestConfig) {
        return PasswordResetViewApiFp(this.configuration).validateToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecentViewDomainApi - axios parameter creator
 * @export
 */
export const RecentViewDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a cached history of recently viewed fundamentals.
         * @summary View recently viewed fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFundamentalRecentViews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/search/recent-views/fundamental`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a cached history of recently viewed MNAs.
         * @summary View recently viewed MNAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMnaRecentViews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/search/recent-views/mna`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a cached history of recently viewed notes.
         * @summary View recently viewed notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNoteRecentViews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/search/recent-views/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a cached history of recent search results
         * @summary View recent search results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecentViews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/search/recent-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a cached history of recently viewed Regulatory.
         * @summary View recently viewed Regulatory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRegulatoryRecentViews: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/search/recent-views/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentViewDomainApi - functional programming interface
 * @export
 */
export const RecentViewDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentViewDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a cached history of recently viewed fundamentals.
         * @summary View recently viewed fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFundamentalRecentViews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FundamentalResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFundamentalRecentViews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentViewDomainApi.getUserFundamentalRecentViews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a cached history of recently viewed MNAs.
         * @summary View recently viewed MNAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMnaRecentViews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MnaResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMnaRecentViews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentViewDomainApi.getUserMnaRecentViews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a cached history of recently viewed notes.
         * @summary View recently viewed notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNoteRecentViews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNoteRecentViews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentViewDomainApi.getUserNoteRecentViews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a cached history of recent search results
         * @summary View recent search results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRecentViews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRecentViews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentViewDomainApi.getUserRecentViews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a cached history of recently viewed Regulatory.
         * @summary View recently viewed Regulatory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRegulatoryRecentViews(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegulatoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRegulatoryRecentViews(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentViewDomainApi.getUserRegulatoryRecentViews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RecentViewDomainApi - factory interface
 * @export
 */
export const RecentViewDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentViewDomainApiFp(configuration)
    return {
        /**
         * Returns a cached history of recently viewed fundamentals.
         * @summary View recently viewed fundamentals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFundamentalRecentViews(options?: RawAxiosRequestConfig): AxiosPromise<Array<FundamentalResponse>> {
            return localVarFp.getUserFundamentalRecentViews(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a cached history of recently viewed MNAs.
         * @summary View recently viewed MNAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMnaRecentViews(options?: RawAxiosRequestConfig): AxiosPromise<Array<MnaResponse>> {
            return localVarFp.getUserMnaRecentViews(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a cached history of recently viewed notes.
         * @summary View recently viewed notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNoteRecentViews(options?: RawAxiosRequestConfig): AxiosPromise<Array<NoteResponse>> {
            return localVarFp.getUserNoteRecentViews(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a cached history of recent search results
         * @summary View recent search results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecentViews(options?: RawAxiosRequestConfig): AxiosPromise<RecentViewResponse> {
            return localVarFp.getUserRecentViews(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a cached history of recently viewed Regulatory.
         * @summary View recently viewed Regulatory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRegulatoryRecentViews(options?: RawAxiosRequestConfig): AxiosPromise<Array<RegulatoryResponse>> {
            return localVarFp.getUserRegulatoryRecentViews(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentViewDomainApi - object-oriented interface
 * @export
 * @class RecentViewDomainApi
 * @extends {BaseAPI}
 */
export class RecentViewDomainApi extends BaseAPI {
    /**
     * Returns a cached history of recently viewed fundamentals.
     * @summary View recently viewed fundamentals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentViewDomainApi
     */
    public getUserFundamentalRecentViews(options?: RawAxiosRequestConfig) {
        return RecentViewDomainApiFp(this.configuration).getUserFundamentalRecentViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a cached history of recently viewed MNAs.
     * @summary View recently viewed MNAs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentViewDomainApi
     */
    public getUserMnaRecentViews(options?: RawAxiosRequestConfig) {
        return RecentViewDomainApiFp(this.configuration).getUserMnaRecentViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a cached history of recently viewed notes.
     * @summary View recently viewed notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentViewDomainApi
     */
    public getUserNoteRecentViews(options?: RawAxiosRequestConfig) {
        return RecentViewDomainApiFp(this.configuration).getUserNoteRecentViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a cached history of recent search results
     * @summary View recent search results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentViewDomainApi
     */
    public getUserRecentViews(options?: RawAxiosRequestConfig) {
        return RecentViewDomainApiFp(this.configuration).getUserRecentViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a cached history of recently viewed Regulatory.
     * @summary View recently viewed Regulatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentViewDomainApi
     */
    public getUserRegulatoryRecentViews(options?: RawAxiosRequestConfig) {
        return RecentViewDomainApiFp(this.configuration).getUserRegulatoryRecentViews(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegulatoryDomainApi - axios parameter creator
 * @export
 */
export const RegulatoryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegulatory: async (regulatoryRequest: RegulatoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regulatoryRequest' is not null or undefined
            assertParamExists('createRegulatory', 'regulatoryRequest', regulatoryRequest)
            const localVarPath = `/api/domain/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(regulatoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatory: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegulatoryById', 'id', id)
            const localVarPath = `/api/domain/regulatory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryNotes: async (id: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegulatoryNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getRegulatoryNotes', 'pageable', pageable)
            const localVarPath = `/api/domain/regulatory/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate Regulators to ElasticSearch
         * @summary Migrate Regulators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateRegulatorsToElasticSearch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/regulatory/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partially update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Regulatory
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateRegulatory: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partiallyUpdateRegulatory', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partiallyUpdateRegulatory', 'body', body)
            const localVarPath = `/api/domain/regulatory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {string} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulatory: async (id: string, regulatoryRequest: RegulatoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegulatory', 'id', id)
            // verify required parameter 'regulatoryRequest' is not null or undefined
            assertParamExists('updateRegulatory', 'regulatoryRequest', regulatoryRequest)
            const localVarPath = `/api/domain/regulatory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(regulatoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegulatoryDomainApi - functional programming interface
 * @export
 */
export const RegulatoryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegulatoryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegulatory(regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegulatory(regulatoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.createRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatory(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.getRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatoryById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegulatoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.getRegulatoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatoryNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatoryNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.getRegulatoryNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Migrate Regulators to ElasticSearch
         * @summary Migrate Regulators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateRegulatorsToElasticSearch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateRegulatorsToElasticSearch(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.migrateRegulatorsToElasticSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Partially update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Regulatory
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partiallyUpdateRegulatory(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partiallyUpdateRegulatory(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.partiallyUpdateRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {string} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegulatory(id: string, regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegulatory(id, regulatoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryDomainApi.updateRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegulatoryDomainApi - factory interface
 * @export
 */
export const RegulatoryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegulatoryDomainApiFp(configuration)
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegulatory(regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createRegulatory(regulatoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getRegulatory(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<RegulatoryResponse> {
            return localVarFp.getRegulatoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {string} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getRegulatoryNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate Regulators to ElasticSearch
         * @summary Migrate Regulators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateRegulatorsToElasticSearch(options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.migrateRegulatorsToElasticSearch(options).then((request) => request(axios, basePath));
        },
        /**
         * Partially update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Partially update Regulatory
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateRegulatory(id: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partiallyUpdateRegulatory(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {string} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulatory(id: string, regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateRegulatory(id, regulatoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegulatoryDomainApi - object-oriented interface
 * @export
 * @class RegulatoryDomainApi
 * @extends {BaseAPI}
 */
export class RegulatoryDomainApi extends BaseAPI {
    /**
     * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Create Regulatory
     * @param {RegulatoryRequest} regulatoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public createRegulatory(regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).createRegulatory(regulatoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
     * @summary Retrieve paginated list of regulatory items
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).getRegulatory(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve Regulatory data by its unique identifier.
     * @summary Get Regulatory by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public getRegulatoryById(id: string, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).getRegulatoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a regulatory data.
     * @summary Get regulatory Notes
     * @param {string} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public getRegulatoryNotes(id: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).getRegulatoryNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate Regulators to ElasticSearch
     * @summary Migrate Regulators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public migrateRegulatorsToElasticSearch(options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).migrateRegulatorsToElasticSearch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partially update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Partially update Regulatory
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public partiallyUpdateRegulatory(id: string, body: object, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).partiallyUpdateRegulatory(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Update Regulatory
     * @param {string} id 
     * @param {RegulatoryRequest} regulatoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryDomainApi
     */
    public updateRegulatory(id: string, regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig) {
        return RegulatoryDomainApiFp(this.configuration).updateRegulatory(id, regulatoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchRestControllerApi - axios parameter creator
 * @export
 */
export const SearchRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Searches for MNAs, Notes and Fundamentals based on the provided search criteria
         * @summary Search for MNAs, Notes and Fundamentals
         * @param {string} query 
         * @param {Set<GlobalSearchFiltersEnum>} filters 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalSearch: async (query: string, filters: Set<GlobalSearchFiltersEnum>, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('globalSearch', 'query', query)
            // verify required parameter 'filters' is not null or undefined
            assertParamExists('globalSearch', 'filters', filters)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('globalSearch', 'pageable', pageable)
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (filters) {
                localVarQueryParameter['filters'] = Array.from(filters);
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchRestControllerApi - functional programming interface
 * @export
 */
export const SearchRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Searches for MNAs, Notes and Fundamentals based on the provided search criteria
         * @summary Search for MNAs, Notes and Fundamentals
         * @param {string} query 
         * @param {Set<GlobalSearchFiltersEnum>} filters 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalSearch(query: string, filters: Set<GlobalSearchFiltersEnum>, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSearchCriteriaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalSearch(query, filters, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchRestControllerApi.globalSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchRestControllerApi - factory interface
 * @export
 */
export const SearchRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchRestControllerApiFp(configuration)
    return {
        /**
         * Searches for MNAs, Notes and Fundamentals based on the provided search criteria
         * @summary Search for MNAs, Notes and Fundamentals
         * @param {string} query 
         * @param {Set<GlobalSearchFiltersEnum>} filters 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalSearch(query: string, filters: Set<GlobalSearchFiltersEnum>, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<GlobalSearchCriteriaResponse> {
            return localVarFp.globalSearch(query, filters, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchRestControllerApi - object-oriented interface
 * @export
 * @class SearchRestControllerApi
 * @extends {BaseAPI}
 */
export class SearchRestControllerApi extends BaseAPI {
    /**
     * Searches for MNAs, Notes and Fundamentals based on the provided search criteria
     * @summary Search for MNAs, Notes and Fundamentals
     * @param {string} query 
     * @param {Set<GlobalSearchFiltersEnum>} filters 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchRestControllerApi
     */
    public globalSearch(query: string, filters: Set<GlobalSearchFiltersEnum>, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return SearchRestControllerApiFp(this.configuration).globalSearch(query, filters, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GlobalSearchFiltersEnum = {
    All: 'ALL',
    Mna: 'MNA',
    Fundamental: 'FUNDAMENTAL',
    Notes: 'NOTES'
} as const;
export type GlobalSearchFiltersEnum = typeof GlobalSearchFiltersEnum[keyof typeof GlobalSearchFiltersEnum];


/**
 * SectorDomainApi - axios parameter creator
 * @export
 */
export const SectorDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSector: async (sectorRequest: SectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectorRequest' is not null or undefined
            assertParamExists('createSector', 'sectorRequest', sectorRequest)
            const localVarPath = `/api/domain/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSector: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSector', 'id', id)
            const localVarPath = `/api/domain/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSectorById', 'id', id)
            const localVarPath = `/api/domain/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectors: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSector: async (id: number, sectorRequest: SectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSector', 'id', id)
            // verify required parameter 'sectorRequest' is not null or undefined
            assertParamExists('updateSector', 'sectorRequest', sectorRequest)
            const localVarPath = `/api/domain/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectorDomainApi - functional programming interface
 * @export
 */
export const SectorDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectorDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSector(sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSector(sectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorDomainApi.createSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSector(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSector(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorDomainApi.deleteSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectorById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectorById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorDomainApi.getSectorById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectors(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorDomainApi.getSectors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSector(id: number, sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSector(id, sectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorDomainApi.updateSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SectorDomainApi - factory interface
 * @export
 */
export const SectorDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectorDomainApiFp(configuration)
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSector(sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createSector(sectorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSector(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSector(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<SectorResponse> {
            return localVarFp.getSectorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getSectors(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSector(id: number, sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.updateSector(id, sectorRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectorDomainApi - object-oriented interface
 * @export
 * @class SectorDomainApi
 * @extends {BaseAPI}
 */
export class SectorDomainApi extends BaseAPI {
    /**
     * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
     * @summary Create a new sector
     * @param {SectorRequest} sectorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorDomainApi
     */
    public createSector(sectorRequest: SectorRequest, options?: RawAxiosRequestConfig) {
        return SectorDomainApiFp(this.configuration).createSector(sectorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
     * @summary Delete a sector
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorDomainApi
     */
    public deleteSector(id: number, options?: RawAxiosRequestConfig) {
        return SectorDomainApiFp(this.configuration).deleteSector(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a sector by its unique identifier.
     * @summary Get sector by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorDomainApi
     */
    public getSectorById(id: number, options?: RawAxiosRequestConfig) {
        return SectorDomainApiFp(this.configuration).getSectorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all sectors.
     * @summary Get all sectors
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorDomainApi
     */
    public getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SectorDomainApiFp(this.configuration).getSectors(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
     * @summary Update a sector
     * @param {number} id 
     * @param {SectorRequest} sectorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorDomainApi
     */
    public updateSector(id: number, sectorRequest: SectorRequest, options?: RawAxiosRequestConfig) {
        return SectorDomainApiFp(this.configuration).updateSector(id, sectorRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagAssociationDomainApi - axios parameter creator
 * @export
 */
export const TagAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiTagAssociation: async (tagAssociationRequest: Array<TagAssociationRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('createMultiTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/domain/tags/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagAssociation: async (tagAssociationRequest: TagAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('createTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/domain/tags/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTagAssociation: async (id: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAllTagAssociation', 'id', id)
            const localVarPath = `/api/domain/tags/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTagAssociation', 'id', id)
            const localVarPath = `/api/domain/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagAssociationById', 'id', id)
            const localVarPath = `/api/domain/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociations: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/tags/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {string} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagAssociation: async (id: string, tagAssociationRequest: TagAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTagAssociation', 'id', id)
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('updateTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/domain/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagAssociationDomainApi - functional programming interface
 * @export
 */
export const TagAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiTagAssociation(tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.createMultiTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagAssociation(tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.createTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllTagAssociation(id: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllTagAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.deleteAllTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.deleteTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.getTagAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagAssociations(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.getTagAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {string} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTagAssociation(id: string, tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTagAssociation(id, tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationDomainApi.updateTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagAssociationDomainApi - factory interface
 * @export
 */
export const TagAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagAssociationDomainApiFp(configuration)
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.createMultiTagAssociation(tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createTagAssociation(tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<string>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTagAssociation(id: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAllTagAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTagAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<TagAssociationResponse> {
            return localVarFp.getTagAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getTagAssociations(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {string} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagAssociation(id: string, tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTagAssociation(id, tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagAssociationDomainApi - object-oriented interface
 * @export
 * @class TagAssociationDomainApi
 * @extends {BaseAPI}
 */
export class TagAssociationDomainApi extends BaseAPI {
    /**
     * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
     * @summary Create multiple tag associations
     * @param {Array<TagAssociationRequest>} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).createMultiTagAssociation(tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new tag association. Only users with ADMIN or USER roles can access this API.
     * @summary Create tag association
     * @param {TagAssociationRequest} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).createTagAssociation(tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
     * @summary Delete multiple tag associations
     * @param {Array<string>} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public deleteAllTagAssociation(id: Array<string>, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).deleteAllTagAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
     * @summary Delete tag association
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public deleteTagAssociation(id: string, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).deleteTagAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a tag association by its unique identifier.
     * @summary Get tag association by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public getTagAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).getTagAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of tag associations.
     * @summary Get all tag associations
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).getTagAssociations(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
     * @summary Update tag association
     * @param {string} id 
     * @param {TagAssociationRequest} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationDomainApi
     */
    public updateTagAssociation(id: string, tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig) {
        return TagAssociationDomainApiFp(this.configuration).updateTagAssociation(id, tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagDomainApi - axios parameter creator
 * @export
 */
export const TagDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tagRequest: TagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('createTag', 'tagRequest', tagRequest)
            const localVarPath = `/api/domain/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTag', 'id', id)
            const localVarPath = `/api/domain/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagById', 'id', id)
            const localVarPath = `/api/domain/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {string} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (id: string, tagRequest: TagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTag', 'id', id)
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('updateTag', 'tagRequest', tagRequest)
            const localVarPath = `/api/domain/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagDomainApi - functional programming interface
 * @export
 */
export const TagDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tagRequest: TagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagDomainApi.createTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagDomainApi.deleteTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagDomainApi.getTagById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagDomainApi.getTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {string} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(id: string, tagRequest: TagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(id, tagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagDomainApi.updateTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagDomainApi - factory interface
 * @export
 */
export const TagDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagDomainApiFp(configuration)
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tagRequest: TagRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createTag(tagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<TagResponse> {
            return localVarFp.getTagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getTags(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {string} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(id: string, tagRequest: TagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTag(id, tagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagDomainApi - object-oriented interface
 * @export
 * @class TagDomainApi
 * @extends {BaseAPI}
 */
export class TagDomainApi extends BaseAPI {
    /**
     * Create a new tag. Requires ADMIN or USER role.
     * @summary Create tag
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagDomainApi
     */
    public createTag(tagRequest: TagRequest, options?: RawAxiosRequestConfig) {
        return TagDomainApiFp(this.configuration).createTag(tagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag by its ID. Requires ADMIN or USER role.
     * @summary Delete tag
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagDomainApi
     */
    public deleteTag(id: string, options?: RawAxiosRequestConfig) {
        return TagDomainApiFp(this.configuration).deleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a tag by its ID.
     * @summary Get tag by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagDomainApi
     */
    public getTagById(id: string, options?: RawAxiosRequestConfig) {
        return TagDomainApiFp(this.configuration).getTagById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of tags.
     * @summary Get tags
     * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagDomainApi
     */
    public getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TagDomainApiFp(this.configuration).getTags(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag. Requires ADMIN or USER role.
     * @summary Update tag
     * @param {string} id 
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagDomainApi
     */
    public updateTag(id: string, tagRequest: TagRequest, options?: RawAxiosRequestConfig) {
        return TagDomainApiFp(this.configuration).updateTag(id, tagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TxDocSummaryDomainApi - axios parameter creator
 * @export
 */
export const TxDocSummaryDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTxDocSummary: async (txDocSummaryRequest: TxDocSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txDocSummaryRequest' is not null or undefined
            assertParamExists('createTxDocSummary', 'txDocSummaryRequest', txDocSummaryRequest)
            const localVarPath = `/api/domain/mnas/tx-doc-summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(txDocSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTxDocSummary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTxDocSummary', 'id', id)
            const localVarPath = `/api/domain/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaries: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mnas/tx-doc-summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTxDocSummaryById', 'id', id)
            const localVarPath = `/api/domain/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTxDocSummary: async (id: string, txDocSummaryRequest: TxDocSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTxDocSummary', 'id', id)
            // verify required parameter 'txDocSummaryRequest' is not null or undefined
            assertParamExists('updateTxDocSummary', 'txDocSummaryRequest', txDocSummaryRequest)
            const localVarPath = `/api/domain/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(txDocSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TxDocSummaryDomainApi - functional programming interface
 * @export
 */
export const TxDocSummaryDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TxDocSummaryDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTxDocSummary(txDocSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryDomainApi.createTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTxDocSummary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTxDocSummary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryDomainApi.deleteTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTxDocSummaries(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryDomainApi.getAllTxDocSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxDocSummaryById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TxDocSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTxDocSummaryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryDomainApi.getTxDocSummaryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTxDocSummary(id: string, txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTxDocSummary(id, txDocSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryDomainApi.updateTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TxDocSummaryDomainApi - factory interface
 * @export
 */
export const TxDocSummaryDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TxDocSummaryDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createTxDocSummary(txDocSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTxDocSummary(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTxDocSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllTxDocSummaries(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<TxDocSummaryResponse> {
            return localVarFp.getTxDocSummaryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTxDocSummary(id: string, txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTxDocSummary(id, txDocSummaryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TxDocSummaryDomainApi - object-oriented interface
 * @export
 * @class TxDocSummaryDomainApi
 * @extends {BaseAPI}
 */
export class TxDocSummaryDomainApi extends BaseAPI {
    /**
     * 
     * @param {TxDocSummaryRequest} txDocSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryDomainApi
     */
    public createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig) {
        return TxDocSummaryDomainApiFp(this.configuration).createTxDocSummary(txDocSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryDomainApi
     */
    public deleteTxDocSummary(id: string, options?: RawAxiosRequestConfig) {
        return TxDocSummaryDomainApiFp(this.configuration).deleteTxDocSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryDomainApi
     */
    public getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TxDocSummaryDomainApiFp(this.configuration).getAllTxDocSummaries(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryDomainApi
     */
    public getTxDocSummaryById(id: string, options?: RawAxiosRequestConfig) {
        return TxDocSummaryDomainApiFp(this.configuration).getTxDocSummaryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TxDocSummaryRequest} txDocSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryDomainApi
     */
    public updateTxDocSummary(id: string, txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig) {
        return TxDocSummaryDomainApiFp(this.configuration).updateTxDocSummary(id, txDocSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TxDocSummaryTypeDomainApi - axios parameter creator
 * @export
 */
export const TxDocSummaryTypeDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaryTypes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/mnas/tx-doc-summaries/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTxDocSummaryTypeById', 'id', id)
            const localVarPath = `/api/domain/mnas/tx-doc-summaries/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TxDocSummaryTypeDomainApi - functional programming interface
 * @export
 */
export const TxDocSummaryTypeDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TxDocSummaryTypeDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageTxDocSummaryTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTxDocSummaryTypes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryTypeDomainApi.getAllTxDocSummaryTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxDocSummaryTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TxDocSummaryTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTxDocSummaryTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryTypeDomainApi.getTxDocSummaryTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TxDocSummaryTypeDomainApi - factory interface
 * @export
 */
export const TxDocSummaryTypeDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TxDocSummaryTypeDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageTxDocSummaryTypeResponse> {
            return localVarFp.getAllTxDocSummaryTypes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryTypeById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<TxDocSummaryTypeResponse> {
            return localVarFp.getTxDocSummaryTypeById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TxDocSummaryTypeDomainApi - object-oriented interface
 * @export
 * @class TxDocSummaryTypeDomainApi
 * @extends {BaseAPI}
 */
export class TxDocSummaryTypeDomainApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryTypeDomainApi
     */
    public getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TxDocSummaryTypeDomainApiFp(this.configuration).getAllTxDocSummaryTypes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryTypeDomainApi
     */
    public getTxDocSummaryTypeById(id: number, options?: RawAxiosRequestConfig) {
        return TxDocSummaryTypeDomainApiFp(this.configuration).getTxDocSummaryTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TxDocSummaryViewRestControllerApi - axios parameter creator
 * @export
 */
export const TxDocSummaryViewRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummariesByMna: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getTxDocSummariesByMna', 'uuid', uuid)
            const localVarPath = `/api/view/mnas/{uuid}/tx-doc-summaries`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TxDocSummaryViewRestControllerApi - functional programming interface
 * @export
 */
export const TxDocSummaryViewRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TxDocSummaryViewRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxDocSummariesByMna(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MnaTxDocSummaryView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTxDocSummariesByMna(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryViewRestControllerApi.getTxDocSummariesByMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TxDocSummaryViewRestControllerApi - factory interface
 * @export
 */
export const TxDocSummaryViewRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TxDocSummaryViewRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummariesByMna(uuid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MnaTxDocSummaryView>> {
            return localVarFp.getTxDocSummariesByMna(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TxDocSummaryViewRestControllerApi - object-oriented interface
 * @export
 * @class TxDocSummaryViewRestControllerApi
 * @extends {BaseAPI}
 */
export class TxDocSummaryViewRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryViewRestControllerApi
     */
    public getTxDocSummariesByMna(uuid: string, options?: RawAxiosRequestConfig) {
        return TxDocSummaryViewRestControllerApiFp(this.configuration).getTxDocSummariesByMna(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserAccountDomainApi - axios parameter creator
 * @export
 */
export const UserAccountDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: async (passwordChangeRequest: PasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('userResetPassword', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/api/domain/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAccountDomainApi - functional programming interface
 * @export
 */
export const UserAccountDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAccountDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPassword(passwordChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAccountDomainApi.userResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserAccountDomainApi - factory interface
 * @export
 */
export const UserAccountDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAccountDomainApiFp(configuration)
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<PasswordResetResponse> {
            return localVarFp.userResetPassword(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAccountDomainApi - object-oriented interface
 * @export
 * @class UserAccountDomainApi
 * @extends {BaseAPI}
 */
export class UserAccountDomainApi extends BaseAPI {
    /**
     * Reset the password for the currently authenticated user.
     * @summary Reset User Password
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccountDomainApi
     */
    public userResetPassword(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig) {
        return UserAccountDomainApiFp(this.configuration).userResetPassword(passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPreferenceDomainApi - axios parameter creator
 * @export
 */
export const UserPreferenceDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences: async (userPreferenceRequest: UserPreferenceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPreferenceRequest' is not null or undefined
            assertParamExists('updateUserPreferences', 'userPreferenceRequest', userPreferenceRequest)
            const localVarPath = `/api/domain/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferenceDomainApi - functional programming interface
 * @export
 */
export const UserPreferenceDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferenceDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferences(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPreferenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferences(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceDomainApi.getUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferences(userPreferenceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceDomainApi.updateUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPreferenceDomainApi - factory interface
 * @export
 */
export const UserPreferenceDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferenceDomainApiFp(configuration)
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserPreferenceResponse>> {
            return localVarFp.getUserPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateUserPreferences(userPreferenceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPreferenceDomainApi - object-oriented interface
 * @export
 * @class UserPreferenceDomainApi
 * @extends {BaseAPI}
 */
export class UserPreferenceDomainApi extends BaseAPI {
    /**
     * Retrieve user preferences based on the authenticated user\'s account.
     * @summary Get user preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceDomainApi
     */
    public getUserPreferences(options?: RawAxiosRequestConfig) {
        return UserPreferenceDomainApiFp(this.configuration).getUserPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user preferences for the authenticated user\'s account.
     * @summary Update user preferences
     * @param {UserPreferenceRequest} userPreferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceDomainApi
     */
    public updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceDomainApiFp(this.configuration).updateUserPreferences(userPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPreferenceTypeDomainApi - axios parameter creator
 * @export
 */
export const UserPreferenceTypeDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPreferenceType: async (userPreferenceTypeRequest: UserPreferenceTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPreferenceTypeRequest' is not null or undefined
            assertParamExists('createUserPreferenceType', 'userPreferenceTypeRequest', userPreferenceTypeRequest)
            const localVarPath = `/api/domain/users/preferenceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreferenceType: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserPreferenceType', 'id', id)
            const localVarPath = `/api/domain/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPreferenceTypeById', 'id', id)
            const localVarPath = `/api/domain/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeOptionsById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPreferenceTypeOptionsById', 'id', id)
            const localVarPath = `/api/domain/users/preferenceType/options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/users/preferenceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferenceType: async (id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserPreferenceType', 'id', id)
            // verify required parameter 'userPreferenceTypeRequest' is not null or undefined
            assertParamExists('updateUserPreferenceType', 'userPreferenceTypeRequest', userPreferenceTypeRequest)
            const localVarPath = `/api/domain/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferenceTypeDomainApi - functional programming interface
 * @export
 */
export const UserPreferenceTypeDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferenceTypeDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserPreferenceType(userPreferenceTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.createUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserPreferenceType(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPreferenceType(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.deleteUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreferenceTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.getUserPreferenceTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypeOptionsById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypeOptionsById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.getUserPreferenceTypeOptionsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.getUserPreferenceTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferenceType(id, userPreferenceTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeDomainApi.updateUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPreferenceTypeDomainApi - factory interface
 * @export
 */
export const UserPreferenceTypeDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferenceTypeDomainApiFp(configuration)
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.createUserPreferenceType(userPreferenceTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreferenceType(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserPreferenceType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<UserPreferenceTypeResponse> {
            return localVarFp.getUserPreferenceTypeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeOptionsById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getUserPreferenceTypeOptionsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getUserPreferenceTypes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.updateUserPreferenceType(id, userPreferenceTypeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPreferenceTypeDomainApi - object-oriented interface
 * @export
 * @class UserPreferenceTypeDomainApi
 * @extends {BaseAPI}
 */
export class UserPreferenceTypeDomainApi extends BaseAPI {
    /**
     * Creates a new user preference type based on the provided request.
     * @summary Create User Preference Type
     * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).createUserPreferenceType(userPreferenceTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a user preference type with the specified ID.
     * @summary Delete User Preference Type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public deleteUserPreferenceType(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).deleteUserPreferenceType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a user preference type by its unique identifier.
     * @summary Get User Preference Type by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public getUserPreferenceTypeById(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).getUserPreferenceTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all options available.
     * @summary Get Options for User Preference Type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public getUserPreferenceTypeOptionsById(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).getUserPreferenceTypeOptionsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of all user preference types.
     * @summary Get All User Preference Types
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).getUserPreferenceTypes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing user preference type with the provided request.
     * @summary Update User Preference Type
     * @param {number} id 
     * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeDomainApi
     */
    public updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeDomainApiFp(this.configuration).updateUserPreferenceType(id, userPreferenceTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserProfileDomainApi - axios parameter creator
 * @export
 */
export const UserProfileDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {string} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (id: string, userProfileRequest: UserProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserProfile', 'id', id)
            // verify required parameter 'userProfileRequest' is not null or undefined
            assertParamExists('updateUserProfile', 'userProfileRequest', userProfileRequest)
            const localVarPath = `/api/domain/users/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/users/profiles/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileDomainApi - functional programming interface
 * @export
 */
export const UserProfileDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {string} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(id: string, userProfileRequest: UserProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(id, userProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileDomainApi.updateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileDomainApi.whoAmI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileDomainApi - factory interface
 * @export
 */
export const UserProfileDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileDomainApiFp(configuration)
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {string} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(id: string, userProfileRequest: UserProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateUserProfile(id, userProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: RawAxiosRequestConfig): AxiosPromise<WhoAmIResponse> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileDomainApi - object-oriented interface
 * @export
 * @class UserProfileDomainApi
 * @extends {BaseAPI}
 */
export class UserProfileDomainApi extends BaseAPI {
    /**
     * Updates the profile of a user with the specified ID
     * @summary Update user profile
     * @param {string} id 
     * @param {UserProfileRequest} userProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileDomainApi
     */
    public updateUserProfile(id: string, userProfileRequest: UserProfileRequest, options?: RawAxiosRequestConfig) {
        return UserProfileDomainApiFp(this.configuration).updateUserProfile(id, userProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves information about the currently authenticated user
     * @summary Get current user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileDomainApi
     */
    public whoAmI(options?: RawAxiosRequestConfig) {
        return UserProfileDomainApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhatMattersAssociationDomainApi - axios parameter creator
 * @export
 */
export const WhatMattersAssociationDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMattersAssociation: async (whatMattersAssociationRequest: WhatMattersAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whatMattersAssociationRequest' is not null or undefined
            assertParamExists('createWhatMattersAssociation', 'whatMattersAssociationRequest', whatMattersAssociationRequest)
            const localVarPath = `/api/domain/what-matters/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMattersAssociation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWhatMattersAssociation', 'id', id)
            const localVarPath = `/api/domain/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId: async (entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/domain/what-matters/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWhatMattersAssociationById', 'id', id)
            const localVarPath = `/api/domain/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/what-matters/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {string} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMattersAssociation: async (id: string, whatMattersAssociationRequest: WhatMattersAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWhatMattersAssociation', 'id', id)
            // verify required parameter 'whatMattersAssociationRequest' is not null or undefined
            assertParamExists('updateWhatMattersAssociation', 'whatMattersAssociationRequest', whatMattersAssociationRequest)
            const localVarPath = `/api/domain/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatMattersAssociationDomainApi - functional programming interface
 * @export
 */
export const WhatMattersAssociationDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhatMattersAssociationDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWhatMattersAssociation(whatMattersAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.createWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhatMattersAssociation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhatMattersAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.deleteWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageWhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.findByEntityIdAndDomainTypeId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersAssociationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.getWhatMattersAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageWhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.getWhatMattersAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {string} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhatMattersAssociation(id: string, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhatMattersAssociation(id, whatMattersAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationDomainApi.updateWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhatMattersAssociationDomainApi - factory interface
 * @export
 */
export const WhatMattersAssociationDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhatMattersAssociationDomainApiFp(configuration)
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createWhatMattersAssociation(whatMattersAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMattersAssociation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWhatMattersAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {string} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageWhatMattersAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<WhatMattersAssociationResponse> {
            return localVarFp.getWhatMattersAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SimplePageWhatMattersAssociationResponse> {
            return localVarFp.getWhatMattersAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {string} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMattersAssociation(id: string, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateWhatMattersAssociation(id, whatMattersAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhatMattersAssociationDomainApi - object-oriented interface
 * @export
 * @class WhatMattersAssociationDomainApi
 * @extends {BaseAPI}
 */
export class WhatMattersAssociationDomainApi extends BaseAPI {
    /**
     * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Create a new WhatMatters association
     * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).createWhatMattersAssociation(whatMattersAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Delete a WhatMatters association
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public deleteWhatMattersAssociation(id: string, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).deleteWhatMattersAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {string} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public findByEntityIdAndDomainTypeId(entityId: string, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a WhatMatters association by its unique identifier.
     * @summary Get WhatMatters association by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public getWhatMattersAssociationById(id: string, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).getWhatMattersAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between WhatMatters and other domains.
     * @summary Get all WhatMatters associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).getWhatMattersAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Update an existing WhatMatters association
     * @param {string} id 
     * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationDomainApi
     */
    public updateWhatMattersAssociation(id: string, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationDomainApiFp(this.configuration).updateWhatMattersAssociation(id, whatMattersAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhatMattersDomainApi - axios parameter creator
 * @export
 */
export const WhatMattersDomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMatters: async (whatMattersRequest: WhatMattersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whatMattersRequest' is not null or undefined
            assertParamExists('createWhatMatters', 'whatMattersRequest', whatMattersRequest)
            const localVarPath = `/api/domain/what-matters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMatters: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWhatMatters', 'id', id)
            const localVarPath = `/api/domain/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWhatMattersByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllWhatMattersByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllWhatMattersByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/what-matters/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMatters: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domain/what-matters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWhatMattersById', 'id', id)
            const localVarPath = `/api/domain/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWhatMattersCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWhatMattersCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/domain/what-matters/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMatters: async (id: string, whatMattersRequest: WhatMattersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWhatMatters', 'id', id)
            // verify required parameter 'whatMattersRequest' is not null or undefined
            assertParamExists('updateWhatMatters', 'whatMattersRequest', whatMattersRequest)
            const localVarPath = `/api/domain/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatMattersDomainApi - functional programming interface
 * @export
 */
export const WhatMattersDomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhatMattersDomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWhatMatters(whatMattersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.createWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhatMatters(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhatMatters(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.deleteWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWhatMattersByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.getAllWhatMattersByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMatters(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.getWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhatMattersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.getWhatMattersById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.getWhatMattersCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhatMatters(id: string, whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhatMatters(id, whatMattersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersDomainApi.updateWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhatMattersDomainApi - factory interface
 * @export
 */
export const WhatMattersDomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhatMattersDomainApiFp(configuration)
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createWhatMatters(whatMattersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMatters(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWhatMatters(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getAllWhatMattersByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PagedModel> {
            return localVarFp.getWhatMatters(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<WhatMattersResponse> {
            return localVarFp.getWhatMattersById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getWhatMattersCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMatters(id: string, whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateWhatMatters(id, whatMattersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhatMattersDomainApi - object-oriented interface
 * @export
 * @class WhatMattersDomainApi
 * @extends {BaseAPI}
 */
export class WhatMattersDomainApi extends BaseAPI {
    /**
     * 
     * @param {WhatMattersRequest} whatMattersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).createWhatMatters(whatMattersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public deleteWhatMatters(id: string, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).deleteWhatMatters(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).getAllWhatMattersByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).getWhatMatters(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public getWhatMattersById(id: string, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).getWhatMattersById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).getWhatMattersCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WhatMattersRequest} whatMattersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersDomainApi
     */
    public updateWhatMatters(id: string, whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersDomainApiFp(this.configuration).updateWhatMatters(id, whatMattersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



