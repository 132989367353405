import React from "react";
import EditableFieldMna from "../../../components/EditableFields/EditableField";

interface CompanyDescriptionTabProps {
  data: any;
  fetchMnaData: () => void;
}

const CompanyDescriptionTab: React.FC<CompanyDescriptionTabProps> = ({
  data,
  fetchMnaData,
}) => {
    const handleFetchMnaData = async () => {
        fetchMnaData();
    }
  return (
    <table className="w-full">
      <caption className="text-left heading-bold text-title pt-4 px-4">
        Company Description
      </caption>
      <tbody className="">
        <tr>
          <td className="pl-4 pr-3.5 text-[#696868] dark:text-white text-sm font-normal align-top">
            <p className="mt-2">Description:</p>
          </td>
        </tr>
        <tr>
          <td>
            <div className="w-full px-2">
              <EditableFieldMna
                data={data?.description}
                fieldtype="textEditor"
                fieldName="description"
                fetchMnaData={handleFetchMnaData}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="pl-4 pr-3.5 text-[#696868] dark:text-white text-sm font-normal align-top">
            <p className="mt-2">Competitors:</p>
          </td>
        </tr>
        <tr className="w-full">
          <td>
            <div className="w-full px-2">
              <EditableFieldMna
                data={data?.competitors}
                fieldtype="text"
                fieldName="competitors"
                fetchMnaData={handleFetchMnaData}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="pl-4 pr-3.5 pt-2.5 text-[#696868] dark:text-white text-sm font-normal align-top">
            <p className="">Customers:</p>
          </td>
        </tr>
        <tr className="w-full">
          <td>
            <div className="w-full px-2">
              <EditableFieldMna
                data={data?.customers}
                fieldtype="text"
                fieldName="customers"
                fetchMnaData={handleFetchMnaData}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CompanyDescriptionTab;
