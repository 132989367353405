import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import checkmark from "../../assets/images/checkmark.png";
import InputFieldMna from "./InputFieldMna";
import { apiConfig } from "../ConfigurationApi/Configuration";
import { FundamentalDomainApi } from "../../openapi";
import { useParams } from "react-router-dom";
import close from "../../assets/svg/close.svg";
import Loader from "../Loader";
// Text Editor
import IndividualFieldTextEditor from "./IndividualFieldTextEditor";
// Third Party
import ReactQuill from "react-quill";
//Utils
import { removeEmptyHTML } from "../../utils";

interface Sector {
  label: string;
  value: number;
}

interface EditableFieldFundamentalProps {
  data?: any;
  fieldName: string;
  fieldtype: "text" | "select" | "date" | 'textEditor';
  sectorList?: Sector[];
  dateIndex?: any;
  fullData?: any;
  dateItemValue?: string;
  setDate?: any;
  setFetchMnaDetails?: any;
  setLocalData?: any;
  fetchFundamentalData: () => Promise<void>;
  whatMatterId?: any;
  whatMatterStatus?: any;
}

const EditableFieldFundamental: React.FC<EditableFieldFundamentalProps> = ({
  data,
  fieldName,
  fieldtype,
  sectorList = [],
  dateItemValue,
  fetchFundamentalData,
  whatMatterId,
  whatMatterStatus
}) => {
  const [activeRow, setActiveRow] = useState(
    fieldtype === "date" && dateItemValue === null
  );

  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(data || "");
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });


  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      inputValue: initialValue,
    },

    onSubmit: async (values) => {

      if (fieldtype === "date") {
        setInitialValue(formatDate(values.inputValue));
      } else {
        setInitialValue(values.inputValue);
      }

      let jsonPatch: any = [];

      jsonPatch = [
        {
          op: "replace",
          path: `/${fieldName}`,
          value: values.inputValue,
        },
      ];

      // For Add WhatMatters
      const jsonPatchForNewWhatMatters = [
        {
          op: "add",
          path: `/${fieldName}`,
          value: {
            add: [
              {
                note: values.inputValue,
              },
            ],
          },
        },
      ];

      // For Edit WhatMatters
      const jsonPatchForWhatMatters = [
        {
          op: "add",
          path: `/${fieldName}`,
          value: {
            edit: {
              [whatMatterId]: {
                note: values.inputValue
              }
            }
          }
        }
      ]

      try {
        const api = new FundamentalDomainApi(apiConfig());

        if (id) {
          const payLoadData = fieldName === 'whatMatters' ? whatMatterId !== null ? jsonPatchForWhatMatters : jsonPatchForNewWhatMatters : jsonPatch;
          await api.partiallyUpdateFundamental(String(id), payLoadData);
        }
        await fetchFundamentalData();
      }
      finally {
        setActiveRow(false);

      }
    },

    enableReinitialize: true,
  });

  useEffect(() => {
    setInitialValue(data || "");
    formik.setFieldValue("inputValue", data || "");
  }, [data]);

  const handleEdit = () => {
    setActiveRow(true);
  };

  const handleCancel = () => {
    setActiveRow(false);
    formik.setFieldValue("inputValue", initialValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === submitButtonRef.current) {
      return;
    }
    handleCancel();
  };

  let selectedSector = formik.values.inputValue;
  if (formik.values.inputValue && fieldName === "sectorId") {
    const index = Number(formik.values.inputValue) - 1;
    if (index >= 0 && index < sectorList.length) {
      selectedSector = sectorList[index].label;
    }
  }

  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  const $description = useRef<ReactQuill | null>(null);

  return (
    <div
      className={`flex w-full h-full relative ${fieldtype === "date"
        ? "justify-end items-center"
        : "justify-between items-start"
        }`}
    >
      {" "}
      {loading && <Loader loading={loading} />}
      <div className="w-full">
        <div onDoubleClick={handleEdit} className="w-full">
          {activeRow ? (
            <form onSubmit={formik.handleSubmit} className="w-full">
              {fieldtype === "select" ? (
                <div className="w-full h-full ">
                  <InputFieldMna
                    label={fieldName}
                    id={fieldName}
                    name="inputValue"
                    type="select"
                    optionList={sectorList}
                    placeholder="Sector"
                    serverError={""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                    autoSubmit={true}
                    cancel={handleBlur}
                  />
                </div>
              ) : fieldtype === "date" ? (
                <div className="w-full">
                  <InputFieldMna
                    label="Date"
                    id="inputValue"
                    name="inputValue"
                    type="date"
                    placeholder="date"
                    showIcon
                    bgColor={"#fff"}
                    last={true}
                    formik={formik}
                    cancel={handleCancel}
                    autoSubmit={true}
                  />
                </div>
              ) : fieldtype === "textEditor" ? (
                <IndividualFieldTextEditor
                  formik={formik}
                  id="description"
                  quillRef={$description}
                  name={"inputValue"}
                  isDynamic
                  value={formik.values.inputValue}
                  label="Descrition"
                  onEditorRef={handleEditorRef}
                />
              ) : (
                <div className="">
                  <input
                    type="text"
                    name="inputValue"
                    autoFocus
                    value={formik.values.inputValue}
                    onChange={formik.handleChange}
                    className="rounded-sm px-2 py-1  box-border border border-primary-default focus:outline-none w-full h-full"
                    onBlur={handleBlur}
                  />
                </div>
              )}

              {fieldtype === "textEditor" ? (
                <div className="flex justify-end gap-3 mt-2">
                  <button onClick={handleCancel} className="font-medium">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="font-medium bg-blue-primary text-body rounded-md py-2 px-4"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div
                  className={`absolute right-[0px] flex space-x-[3px] shadow-2xl ${fieldtype === "select" ? "hidden" : "top-9"
                    }`}
                >
                  {" "}
                  {fieldtype !== "date" && (
                    <>
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                      >
                        <img
                          className="w-3 h-3"
                          src={checkmark}
                          alt="checkmark"
                        />
                      </button>

                      <button
                        type="button"
                        onClick={handleCancel}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                      >
                        <img src={close} className="w-3 h-3" alt="close" />
                      </button>
                    </>
                  )}
                </div>
              )}

              {/* <div
                className={`absolute right-[0px] flex space-x-[3px] shadow-2xl ${fieldtype === "select" ? "hidden" : "top-9"
                  }`}
              >
                {" "}
                {fieldtype !== "date" && (
                  <>
                    <button
                      type="submit"
                      ref={submitButtonRef}
                      className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                    >
                      <img
                        className="w-3 h-3"
                        src={checkmark}
                        alt="checkmark"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={handleCancel}
                      className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                    >
                      <img src={close} className="w-3 h-3" alt="close" />
                    </button>
                  </>
                )}
              </div> */}
            </form>
          ) : (
            <p
              className={` rounded-sm cursor-text flex flex-col align-center break-words whitespace-normal `}
            >
              {initialValue ? (
                <>
                  <span
                    className={`hover:bg-button-dark hover:bg-opacity-15 rounded-md p-2 font-normal ${fieldtype === "select"
                      ? ""
                      : fieldtype === "text"
                        ? "-mr-2 self-start"
                        : fieldtype === "date" &&
                          (fieldName === "outDates" ||
                            fieldName === "brokerClosingDates" ||
                            fieldName === "financingDates")
                          ? "self-end"
                          : "-ml-2"
                      }`}
                  >
                    {fieldtype === "select"
                      ? selectedSector
                      : fieldtype === "textEditor" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              removeEmptyHTML(formik.values.inputValue) || "",
                          }}
                        />
                      ) :
                        fieldtype === "date"
                          ? formik.values.inputValue
                          : initialValue}
                  </span>
                </>
              ) : (
                <div className=" bg-opacity-10 w-full rounded-md px-2 hover:bg-table-dark hover:bg-opacity-10">
                  <p className="py-2">-</p>
                </div>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableFieldFundamental;
