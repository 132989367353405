// React
import React from "react";
import DeleteIcon from "../../../assets/svg/deleteIcon.svg"

interface DeleteModalProps {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: any) => void;
  onDeleteItem: () => void;
}

const DeleteContactModal: React.FC<DeleteModalProps> = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  onDeleteItem,
}) => {
  return (
    <div
      className={`${isDeleteModalOpen ? "block opacity-100" : "hidden opacity-0"
        } absolute text-gray-500 flex items-center justify-center  top-0 left-0 z-30  h-full w-full  px-4 py-5   transition ease duration-300 `}
    >
      <div className="absolute inset-0 bg-[#0000001A]  transition-opacity" />
      <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-auto sm:max-w-lg">
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="flex flex-col items-center">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 mb-4">
              <img src={DeleteIcon} alt="Delete contact" className="h-full w-[100px] object-contain" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                className="text-base font-medium leading-5 text-gray-500 text-center text-black"
                id="modal-title"
              >
                Are you sure you want to <br /> delete this contact?
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 flex  justify-end sm:px-6">
          <button
            type="button"
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-6 py-2  bg-[#5EC1F92E] text-[#191919] font-medium text-base rounded-lg mr-3"
          >
            No,Cancel
          </button>
          <button
            type="button"
            onClick={onDeleteItem}
            className="px-6 py-1 text-[#fff] bg-[#3B97FF] text-base font-medium rounded-lg"
          >
            Yes,Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteContactModal;
