// React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
// Data
import { formStepsTitles } from "../data/fundamentalInfo";
// Types
import { Steps } from "..";
// Images
import ArrowLeft from "../../../assets/svg/arrow-left-solid.svg";
import ArrowRight from "../../../assets/svg/arrow-right-solid.svg";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface StepError {
  [key: string]: string; 
}

type StepContainerProps = {
  children: JSX.Element;
  setActiveStep: React.Dispatch<React.SetStateAction<Steps>>;
  activeStep: Steps;
  onSubmitForm(): Promise<boolean>;
  errorObj:StepError 
};

type StepsPaginationError = {
  [key in Steps]: { isValid: boolean };
};

export default function StepContainer({
  children,
  errorObj,
  setActiveStep,
  activeStep,
  onSubmitForm,
}: StepContainerProps): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const [stepsPaginationError, setStepsPaginationError] = useState<StepsPaginationError>({
    "0": { isValid: true },
    "1": { isValid: true },
    "2": { isValid: true },
    "3": { isValid: true },
    "4": { isValid: true },
    "5": { isValid: true },
    "6": { isValid: true },
    "7": { isValid: true },
  });

  useEffect(() =>{    
    for (const step in errorObj) {
      if (Object.keys(errorObj[step]).length > 0) {          
          // If step has errors, set isValid to false
          setStepsPaginationError((prevState:StepsPaginationError) => ({
              ...prevState,
              [step]: { isValid: false }
          }));
      } else {
          // If no errors for step, set isValid to true
          setStepsPaginationError((prevState:StepsPaginationError) => ({
              ...prevState,
              [step]: { isValid: true }
          }));
      }
  }
  },[errorObj])

  const stepPagination = Array.from({ length: 8 }, (_, index) => index);

  function onChangePaginationError(isValid: boolean): void {
    setStepsPaginationError((prev) => {
      return { ...prev, [activeStep]: { isValid } };
    });
  }

  async function onNexStep(): Promise<void> {
    if (activeStep === 7) return;
    const response: boolean = await onSubmitForm();
    onChangePaginationError(response);
    if (!response) return;

    setActiveStep((prev) => (prev + 1) as Steps);
  }

  async function onPrevStep(): Promise<void> {
    if (activeStep === 0) return;
    const response: boolean = await onSubmitForm();
    onChangePaginationError(response);
    if (!response) return;

    setActiveStep((prev) => (prev - 1) as Steps);
  }

  async function onStepClick(step: Steps): Promise<void> {
    if (step === activeStep) return;
    const response: boolean = await onSubmitForm();

    onChangePaginationError(response);
    setActiveStep(step);
  }
  return (
    <>
      <div className="flex justify-between items-center flex-wrap  sticky top-0 py-3 z-[1] bg-primary-light dark:bg-primary-dark px-7">
        <div className="flex items-center gap-8">
          <Breadcrumbs breadcrumbs={[id ? "Update MNA" : "Add New MNA"]} />
          <h2 className="text-[#41413F] flex items-center text-lg font-bold w-[100px] h-[50px] max-w-[100px] dark:text-white">
            {formStepsTitles[activeStep]}
          </h2>
        </div>
        <div className="flex gap-16">
          <div className="flex justify-center items-center gap-2">
            <button onClick={onPrevStep} type="button">
              <img src={ArrowLeft} alt="prev" className="h-5 invert-[50%] dark:invert" />
            </button>
            <div className="gap-6 flex">
              {stepPagination.map((step) => (
                <div
                  className="relative cursor-pointer"
                  key={`pagination-step-${step}`}
                  onClick={() => onStepClick(step as Steps)}
                >
                  <div
                    className={`rounded-full flex justify-center border items-center	w-7 h-7 ${
                      step === activeStep || step < activeStep ? "border-[#0D9872]" : "border-[#808080]"
                    }
               ${
                 step !== 0 &&
                 "before:absolute before:content-[''] before:right-[115%] before:h-[1.5px] before:bg-[#808080] dark:before:bg-white before:w-4"
               }
               ${!stepsPaginationError[step as Steps].isValid ? "border-[#E83A46]" : ""} 
              `}
                  >
                    {step + 1}
                  </div>
                </div>
              ))}
            </div>
            <button onClick={onNexStep} type="button">
              <img src={ArrowRight} alt="next" className="h-5 invert-[50%] dark:invert" />
            </button>
          </div>

          <div className="flex gap-2">
            <button type="submit" className="py-1 px-4 ">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[44px] dark:text-[#375922] text-[#4A9E16] rounded-full bg-white"
              />
            </button>
            <button type="button" className="py-1 " onClick={() => navigate("/mnas")}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="text-[44px] text-[#c52233] dark:text-[#8B1F1F] bg-white rounded-full"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="p-[30px] pt-1 ">{children}</div>
    </>
  );
}
