import React from "react";

const FinancialSummary = () => {
  return (
    <div className=" overflow-x-auto">
      <div className=" min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <th className="pl-4 pr-3.5 pb-2 pt-4 text-title dark:text-white heading-bold font-normal text-left">
                Financial Summary
              </th>
              <td className="px-3.5 py-2 text-title dark:text-white text-base font-normal">
                Downside
              </td>
              <td className="px-3.5 py-2 text-title dark:text-white text-base font-normal">
                Base
              </td>
              <td className="pl-3.5 pr-4 py-2 text-title dark:text-white text-base font-normal">
                Upside
              </td>
            </thead>
            <tbody className="">
              <tr>
                <td className="pr-3.5 pl-4 py-2  heading-regular-grey">
                  Simple Target:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pr-3.5 pl-4 py-2   heading-regular-grey">
                  Divident/Other:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  0.00
                </td>
              </tr>
              <tr>
                <td className="pr-3.5 pl-4 py-2 heading-regular-grey">
                  Total Return:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 heading-regular-grey">
                  Nominal % Return:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  -10.00%
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  -16.00%
                </td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  -180.00%
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2  heading-regular-grey">
                  Probability:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white text-base font-normal">
                  Risk/Reward:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 pt-2 pb-4 text-[#696868] dark:text-white text-base font-normal">
                  Annuluzed Return:
                </td>
                <td className="px-3.5 pt-2 pb-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $120.00
                </td>
                <td className="px-3.5 pt-2 pb-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $160.00
                </td>
                <td className="pr-4 pl-3.5 pt-2 pb-4 text-[#000817] dark:text-white text-base font-medium text-left">
                  $180.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FinancialSummary;
