import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { FileDomainApi, NoteDomainApi } from "../../../openapi";

export const fetchNoteDataApi = async (id: any) => {
  const api = new NoteDomainApi(apiConfig());
  try {
    const response = await api.getNoteById(id);
    return response;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const downloadFileApi = async (id: string) => {
  try {
    const api = new FileDomainApi(apiConfig());
    const response = await api.downloadFile(id);
    return response;
  } catch (error) {
    console.error("Error in file download service:", error);
    throw error;
  }
};
