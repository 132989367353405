
const TagList = ({ tags }: { tags: string[] }) => (
  <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
    {tags?.map((tag, index) => (
      <div key={index} className="bg-[#DBE9FF] text-primary-dark font-medium dark:text-primary-light dark:bg-primary-dark text-sm leading-3 py-2 px-3 rounded-full">
        {tag}
      </div>
    ))}
  </div>
)

export default TagList
