import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { getApiData } from "../../../controller/getApiDataConfiguration";
import { ErrorLogDomainApi } from "../../../openapi";

export const fetchErrorLogDomainApi = async (
  pageSize: number,
  pageIndex: number,
  sortBy: any
) => {
  try {
    const api = new getApiData();
    return await api.getErrorLogDomainApi(pageSize, pageIndex, sortBy);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const deleteErrorLogById = async (deleteID: string) => {
  try {
    const api = new ErrorLogDomainApi(apiConfig());
    await api.deleteErrorLogById(deleteID);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};
