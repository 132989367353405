import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { getSortArray } from "../../../helpers/getSortArray";
import { Sort } from "../../../interfaces/pages/variedInterfaces";
import { AlertDomainApi } from "../../../openapi";

export const fetchAlerts = async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
  try {
    const api = new AlertDomainApi(apiConfig());
    const response = await api.getAlerts(pageIndex, pageSize, getSortArray(sortBy));
    return response.data;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};