import TabOne from "./Profile";
import TabTwo from "./Password";

interface Tab {
  label: string;
  path: string;
  content: React.ComponentType<any>;
}

export const Tabs: Tab[] = [
  { label: "Profile", path: "/settings/profile", content: TabOne },
  // { label: "Reset Password", path: "/settings/reset-password", content: TabTwo },
];
