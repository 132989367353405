// React
import React, { useRef, useState } from "react";
// Third party
import AsyncCreatableSelect from "react-select/async-creatable";
import ReactSelectAsync from "react-select/async";
// Theme
import { useTheme } from "../../App";
// Images
import Search from "../../assets/svg/search.svg";
import close from "../../assets/svg/close.svg";
import { clearIndicatorCSS } from "react-select/dist/declarations/src/components/indicators";

interface AsyncSelectProps {
  formik: any;
  error: any;
  required?: boolean;
  fetchOptions: (value: any) => Promise<{ value: number; label: string }[]>;
  onkeydown?: (event: any) => void;
  loading: boolean;
  id: string;
  name: string;
  placeholder: string;
  label?: string;
  isMulti?: boolean;
  last?: boolean;
  noOptionsMessage: string;
  defaultOptions?: boolean;
  value?: any;
  bgColor?: string;
  tags?: boolean;
  isCustom?: boolean;
  tagRef?: any;
  onSelectionChange?: (option: any) => void;
}

const AsyncSelect: React.FC<AsyncSelectProps> = ({
  formik,
  error,
  required,
  fetchOptions,
  loading,
  id,
  name,
  placeholder,
  label,
  isMulti,
  last,
  noOptionsMessage,
  defaultOptions,
  onkeydown,
  value,
  bgColor,
  tags,
  isCustom,
  tagRef,
  onSelectionChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const $tagRef = useRef<any>();

  const CustomDropdownIndicator = (props: any) => (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => props.selectProps.onChange(props.selectProps.value)}
    >
      <img src={Search} alt="dropdown" className="mr-2" />
    </div>
  );

  interface State {
    isSelected: boolean;
    isFocused: any;
  }

  const customStyles = {
    menu: (provided: {}) => ({
      ...provided,
      left: "0px",
      backgroundColor: theme.theme === "light" ? "#fff" : "#202020",
    }),
    option: (provided: {}, state: State) => ({
      ...provided,
      margin: "8px",
      width: "auto",
      borderRadius: "8px",
      backgroundColor: state.isFocused
        ? "#F3F8FF"
        : theme.theme === "light"
        ? "white"
        : "#202020",
      color: state.isFocused ? "#3B97FF" : "#888888",
      borderRight: "none",
    }),
    control: (provided: {}) => ({
      ...provided,
      flexDirection: "row-reverse" as "row-reverse",
      border: "0px",
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        border: "0px",
      },
      "&:focus": {
        borderColor: "transparent",
      },
    }),
    valueContainer: (provided: {}) => ({
      ...provided,
      padding: "0px",
    }),
    indicatorsContainer: (provided: {}) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (provided: {}) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided: {}) => ({
      ...provided,
      color: "#999",
    }),
    singleValue: (provided: {}) => ({
      ...provided,
      color: theme.theme === "light" ? "#333" : "#FFF",
      backgroundColor: isCustom ? "#E5E5E5" : "",
      margin: isCustom ? "2px" : "",
      padding: isCustom ? "3px" : "",
      borderRadius: isCustom ? "2px" : "",
      width: isCustom ? "fit-content" : "",
      paddingRight: "20px",
    }),
    input: (provided: {}) => ({
      ...provided,
      margin: "0px",
      color: theme.theme === "light" ? "black" : "white",
      width: "0px",
    }),
    focus: (provided: {}) => ({
      ...provided,
      borderColor: "transparent",
    }),
  };

  return (
    <React.Fragment>
      <div>
        <div className="relative">
          {tags ? (
            <AsyncCreatableSelect
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              name={name}
              noOptionsMessage={() => <p>{noOptionsMessage}</p>}
              placeholder={placeholder}
              id={id}
              ref={tagRef || $tagRef}
              loadOptions={async (inputValue) => await fetchOptions(inputValue)}
              value={formik.values[name] || value || ""}
              onBlur={formik.handleBlur}
              onChange={(option) => {
                formik.setFieldValue(name, option);
                setSearchTerm(""); // Clear searchTerm on selection
                if (onSelectionChange) onSelectionChange(option);
              }}
              onKeyDown={onkeydown}
              isSearchable
              isClearable
              components={{
                DropdownIndicator: CustomDropdownIndicator,
                ClearIndicator: (props) => (
                  <div
                    {...props.innerProps}
                    className="cursor-pointer ml-2 p-2 absolute right-0"
                    onClick={() => {
                      formik.setFieldValue(name, null);
                      props.clearValue();
                    }}
                  >
                    <img src={close} alt="close" />
                  </div>
                ),
              }}
              className={`dark:bg-primary-dark dark:border-white py-[11px] px-[30px] border ${
                error[name] ? "border-danger" : "border-[#C3C1DF]"
              }  rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none  bg-[${
                bgColor ? bgColor : "#FAFAFE"
              }]`}
              styles={customStyles}
              onInputChange={(input) => setSearchTerm(input)}
              inputValue={searchTerm || ""}
              isLoading={loading}
            />
          ) : (
            <ReactSelectAsync
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              name={name}
              noOptionsMessage={() => <p>{noOptionsMessage}</p>}
              placeholder={placeholder}
              id={id}
              loadOptions={(inputValue) => {
                const options = fetchOptions(inputValue);
                return options;
              }}
              value={formik.values[name] || value}
              onBlur={(e) => {
                const value = e.target.value;
                if (
                  (name === "target" || name === "acquirer") &&
                  !formik.values[name]?.label
                ) {
                  formik.setValues({
                    ...formik.values,
                    [name]: value ? value : null,
                  });
                }
              }}
              onChange={(option) => {
                formik.setFieldValue(name, option);
                setSearchTerm("");
                if (onSelectionChange) onSelectionChange(option);
              }}
              isSearchable
              isClearable
              components={{
                DropdownIndicator: CustomDropdownIndicator,
                ClearIndicator: (props) => (
                  <div
                    {...props.innerProps}
                    className="cursor-pointer ml-2 p-2 absolute right-0"
                    onClick={() => props.clearValue()}
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  >
                    <img src={close} alt="close" />
                  </div>
                ),
              }}
              className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[10px] pt-[5px]  ${
                error[name] ? "border-danger" : "border-[#C3C1DF]"
              } text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
              styles={customStyles}
              onInputChange={(input) => setSearchTerm(input)}
              inputValue={searchTerm || ""}
              isLoading={loading}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AsyncSelect;
