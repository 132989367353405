import React from "react";

export default function TopFiveHolders() {
  return (
    <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
      <table className="min-w-full dark:bg-table-dark bg-table-light ">
        <caption className="text-left text-[18px] font-semibold text-title pt-4 pb-2 px-4 bg-table-light dark:bg-table-dark">
          Holders Top 5 Holders
        </caption>
        <tbody className="">
          <tr className="flex justify-between items-center w-full">
            <td className="px-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
              ATVI
            </td>
            <td className="px-4  text-[#696868] dark:text-white text-xs font-normal leading-5">
              <div> Changed by: James</div>
              <div>Date: November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full">
            <td className="px-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
              ATVI
            </td>
            <td className="px-4 text-[#696868] dark:text-white text-xs font-normal leading-5">
              <div> Changed by: James</div>
              <div>Date: November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full">
            <td className="px-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
              ATVI
            </td>
            <td className="px-4 text-[#696868] dark:text-white text-xs font-normal leading-5">
              <div> Changed by: James</div>
              <div>Date: November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full">
            <td className="px-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
              ATVI
            </td>
            <td className="px-4 text-[#696868] dark:text-white text-xs font-normal leading-5">
              <div> Changed by: James</div>
              <div>Date: November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full">
            <td className="px-4 pb-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-3.5 pb-4 text-[#D70000] text-sm font-medium text-left">
              Vanguard
            </td>
            <td className="px-3.5 pb-4 text-[#007B1B] text-sm font-medium text-left">
              ATVI
            </td>
            <td className="px-4 pb-4 text-[#696868] dark:text-white text-xs font-normal leading-5">
              <div> Changed by: James</div>
              <div>Date: November 11, 2023</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
