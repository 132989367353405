// React
import React, { useState } from "react";

interface VersionModalProps{
  isMenuOpen: boolean
}

const VersionModal: React.FC<VersionModalProps> = ({isMenuOpen}) => {
  const [newVersion, setNewVersion] = useState(true);
  const handleClearStorageClick = () => {
    // Clear local storage
    localStorage.clear();

    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clear cache
    if (caches) {
      caches.keys().then(function (names) {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }

    // Clear IndexedDB
    if (window.indexedDB) {
      const request = window.indexedDB.deleteDatabase("your_database_name");

      request.onerror = function (event: any) {
        console.error("Error deleting database:", event.target.error)
      };
      request.onsuccess = function (event) {
        console.log("Database deleted successfully");
      };
    }

    // Reload the page after clearing all storage
    window.location.reload();
  };
  return (
    <React.Fragment>
      {newVersion && (
        <div
          className={` ${isMenuOpen ? "pl-[316px]" : "pl-[92px]"} w-full p-5 text-gray-500 bg-table-sub-light dark:bg-black dark:text-white rounded-lg shadow flex justify-between items-center duration-500`}
          role="alert"
        >
          <div className="flex gap-3 items-center">
            <div>
              <svg
                className="w-4 h-4 dark:brightness-[4]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 20"
              >
                <path
                  stroke="#7978E9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
                />
              </svg>
              <span className="sr-only">Refresh icon</span>
            </div>
            <span className="text-sm font-semibold text-primary dark:text-white">
              Update available
            </span>
            <span className="text-sm font-normal text-primary">
              A new software version is available.
            </span>
            <span className="text-sm font-normal text-primary">
              Please{" "}
              <button className="underline" onClick={handleClearStorageClick}>
                click here
              </button>{" "}
              to refresh your browser.
            </span>
          </div>
          <button
            type="button"
            onClick={() => setNewVersion(false)}
            className="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-3 h-3 dark:brightness-[4]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"

            >
              <path
                stroke="#7978E9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default VersionModal;
