// React
import React, { useState, useEffect, useRef } from "react";
// Third party
import ReactQuill, { Quill } from "react-quill";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import MagicUrl from "quill-magic-url";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  Bold,
  CloudServices,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  Markdown,
  MediaEmbed,
  PageBreak,
  Paragraph,
  PasteFromMarkdownExperimental,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Title,
  Underline,
  Undo,
  HtmlEmbed,
  BlockQuote,
  IndentBlock,
  Indent,
  ListProperties,
  TodoList,
  AutoLink,
  CodeBlock,
  HorizontalLine,
  List,
} from "ckeditor5";
import { ExportPdf, ExportWord, ImportWord } from "ckeditor5-premium-features";

// import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
// Styles
import "react-quill/dist/quill.snow.css";
import "../../App.css";

interface EditorProps {
  formik: any;
  error?: string[];
  required?: boolean;
  id: string;
  name: string;
  label?: string;
  isDynamic?: boolean;
  value?: any;
  quillRef: React.RefObject<ReactQuill>;
  onEditorRef?: any;
  serverError?: any;
}

const TextEditor: React.FC<EditorProps> = ({
  formik,
  error,
  name,
  id,
  label,
  isDynamic,
  value,
  quillRef,
  onEditorRef,
  serverError,
}) => {
  const [highlightMode, setHighlightMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ckeditor");

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const applyHighlight = () => {
      const selection = editor.getSelection();
      if (selection) {
        editor.format("background", "yellow");
      }
    };
    if (highlightMode) {
      editor.on("selection-change", applyHighlight);
    } else {
      editor.off("selection-change", applyHighlight);
    }
    return () => {
      editor.off("selection-change", applyHighlight);
    };
  }, [highlightMode, quillRef]);

  const styles = {
    fontFamily: "Times New Roman",
    fontSize: "16px",
    paddingBottom: "0px",
  };

  const editorContainerRef = useRef(null);
  const editorRef = useRef<any>(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const colors = [
    {
      color: "hsl(0, 0%, 0%)",
      label: "Black",
    },
    {
      color: "hsl(0, 0%, 30%)",
      label: "Dim grey",
    },
    {
      color: "hsl(0, 0%, 60%)",
      label: "Grey",
    },
    {
      color: "hsl(0, 0%, 90%)",
      label: "Light grey",
    },
    {
      color: "hsl(0, 0%, 100%)",
      label: "White",
      hasBorder: true,
    },
    {
      color: "hsl(0, 75%, 60%)",
      label: "Red",
    },
    {
      color: "hsl(30, 75%, 60%)",
      label: "Orange",
    },
    {
      color: "hsl(60, 75%, 60%)",
      label: "Yellow",
    },
    {
      color: "hsl(90, 75%, 60%)",
      label: "Light green",
    },
    {
      color: "#006400",
      label: "Green",
    },
    {
      color: "#4ce699",
      label: "Aquamarine",
    },
    {
      color: "#4ce6e6",
      label: "Turquoise",
    },
    {
      color: "#4c99e6",
      label: "Light blue",
    },
    {
      color: "#4c4ce6",
      label: "Blue",
    },
    {
      color: "#994ce6",
      label: "Purple",
    },
  ];

  const editorConfig: any = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "insertTable",
        "highlight",
        "blockQuote",
        "codeBlock",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      Autosave,
      BalloonToolbar,
      Base64UploadAdapter,
      Bold,
      CloudServices,
      Code,
      Essentials,
      ExportPdf,
      ExportWord,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImportWord,
      Italic,
      Link,
      LinkImage,
      Markdown,
      MediaEmbed,
      PageBreak,
      Paragraph,
      PasteFromMarkdownExperimental,
      PasteFromOffice,
      RemoveFormat,
      SelectAll,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextPartLanguage,
      TextTransformation,
      Title,
      Underline,
      Undo,
      HtmlEmbed,
      BlockQuote,
      IndentBlock,
      Indent,
      ListProperties,
      TodoList,
      AutoLink,
      CodeBlock,
      HorizontalLine,
      List,
    ],
    balloonToolbar: [
      "bold",
      "italic",
      "|",
      "Highlight",
      "link",
      "strikethrough",
    ],
    exportPdf: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-pdf.html */
        "./App.css",
        /* Export PDF needs access to stylesheets that style the content. */
        "https://cdn.ckeditor.com/ckeditor5/42.0.1/ckeditor5.css",
        "https://cdn.ckeditor.com/ckeditor5-premium-features/42.0.1/ckeditor5-premium-features.css",
      ],
      fileName: "export-pdf-demo.pdf",
      converterOptions: {
        format: "Tabloid",
        margin_top: "20mm",
        margin_bottom: "20mm",
        margin_right: "24mm",
        margin_left: "24mm",
        page_orientation: "portrait",
      },
    },
    exportWord: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-word.html */
        "./App.css",
        /* Export Word needs access to stylesheets that style the content. */
        "https://cdn.ckeditor.com/ckeditor5/42.0.1/ckeditor5.css",
        "https://cdn.ckeditor.com/ckeditor5-premium-features/42.0.1/ckeditor5-premium-features.css",
      ],
      fileName: "export-word-demo.docx",
      converterOptions: {
        format: "Tabloid",
        margin_top: "20mm",
        margin_bottom: "20mm",
        margin_right: "24mm",
        margin_left: "24mm",
        orientation: "portrait",
      },
    },
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    highlight: {
      options: [
        {
          model: "redPen",
          class: "pen-red",
          title: "Red pen",
          color: "var(--ck-highlight-pen-red)",
          type: "pen",
        },
        {
          model: "greenPen",
          class: "pen-green",
          title: "Green pen",
          color: "var(--ck-highlight-pen-green)",
          type: "pen",
        },
        {
          model: "yellowMarker",
          class: "marker-yellow",
          title: "Yellow Marker",
          color: "var(--ck-highlight-marker-yellow)",
          type: "marker",
        },
        {
          model: "greenMarker",
          class: "marker-green",
          title: "Green marker",
          color: "var(--ck-highlight-marker-green)",
          type: "marker",
        },
        {
          model: "pinkMarker",
          class: "marker-pink",
          title: "Pink marker",
          color: "var(--ck-highlight-marker-pink)",
          type: "marker",
        },
        {
          model: "blueMarker",
          class: "marker-blue",
          title: "Blue marker",
          color: "var(--ck-highlight-marker-blue)",
          type: "marker",
        },
      ],
    },
    fontColor: {
      colors,
    },
    fontBackgroundColor: {
      colors,
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    initialData: "",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    menuBar: {
      isVisible: true,
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removePlugins: ["Markdown", "Title"],
  };

  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications"
        )
      ) {
        const overlay = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (overlay) {
          overlay.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="">
        <div className="mt-4">
          <div>
            {selectedTab === "ckeditor" && (
              <div>
                <div>
                  <div>
                    <style>
                      {`
                        .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
                        border-color: ${
                          error ? "#DB1A21" : "#C4C1DE"
                        } !important;
                        border-top-right-radius: 20px !important;
                        border-top-left-radius : 20px !important;
                        }
                        .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-menu-bar{
                        border-top-right-radius: 20px !important;
                        border-top-left-radius : 20px !important;
                        border-color: #C4C1DE !important;
                        }
                        .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
                        border-color: ${
                          error ? "#DB1A21" : "#C4C1DE"
                        } !important;
                        border-top-color: #C4C1DE !important;
                        border-bottom-right-radius: 20px;
                        border-bottom-left-radius : 20px;
                        min-height: 200px !important;
                        }
                        .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
                        box-shadow: none !important;
                        border-color: ${
                          error ? "#DB1A21" : "#C4C1DE"
                        } !important;
                        border-top-color: #C4C1DE !important;
                        border-bottom-right-radius: 20px;
                        border-bottom-left-radius : 20px;
                        min-height: 200px !important;
                        }

                         .ck.ck-toolbar {
                        border-color: ${
                          error ? "#DB1A21" : "#C4C1DE"
                        } !important;
                          width:90%;
                        }
                       } 

                   
                    `}
                    </style>
                    <div className="main-container">
                      <div
                        className="editor-container editor-container_classic-editor"
                        ref={editorContainerRef}
                      >
                        <div className="editor-container__editor">
                          <div ref={editorRef}>
                            {isLayoutReady && (
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfig}
                                data={
                                  isDynamic
                                    ? value || ""
                                    : formik?.values[name] || ""
                                }
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  formik.setFieldValue(name, data);
                                  onEditorRef({ keyName: name, ref: data });
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextEditor;
