import React from 'react'

export function PlusIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6875 9.375H12.125V2.8125H10.875V9.375H4.3125V10.625H10.875V17.1875H12.125V10.625H18.6875V9.375Z" fill="white"/>
    </svg>
    
  )
}
