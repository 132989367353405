import React from "react";
import EditableFieldMna from "../../../components/EditableFields/EditableField";
import { Item } from "../../../interfaces/pages/variedInterfaces";

interface WhatMattersTabProps {
  data: any;
  fetchMnaData: () => void;
}

const WhatMattersTab: React.FC<WhatMattersTabProps> = ({
  data,
  fetchMnaData,
}) => {
  const handleFetchMnaData = async () => {
    fetchMnaData();
  };
  return (
    <>
      <div className="px-3.5 pt-4 dark:bg-black text-title dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
        What Matters
      </div>
      <div className="bg-table-light dark:bg-table-dark rounded-bl-md rounded-br-md overflow-hidden">
        <div className="w-full px-2">
          {data?.whatMatters?.length > 0 ? (
            data.whatMatters.map(
              (item: Item, index: number) =>
                item && (
                  <div key={item.id} style={{ marginBottom: "1rem" }}>
                    {data.whatMatters.length > 1 && (
                      <p>
                        <strong>CreatedBy {item?.createdBy?.username} :</strong>
                      </p>
                    )}
                    <EditableFieldMna
                      data={item.note}
                      fieldtype="textEditor"
                      fieldName="whatMatters"
                      fetchMnaData={handleFetchMnaData}
                      dataResponse={data}
                      whatMatterId={item.id}
                      noteStatus={"update"}
                    />
                  </div>
                )
            )
          ) : (
            <div>
              <EditableFieldMna
                data=""
                fieldtype="textEditor"
                fieldName="whatMatters"
                fetchMnaData={handleFetchMnaData}
                dataResponse={data}
                whatMatterId={null}
                noteStatus={"newNote"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WhatMattersTab;
