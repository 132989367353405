import React from "react";
import Close from "../../../assets/svg/close.svg";

interface ContentModalProps {
  isOpen: boolean;
  content: string | undefined;
  onClose: () => void;
}

const ContentModal: React.FC<ContentModalProps> = ({ isOpen, content, onClose }) => {
  if (!isOpen || !content) return null;

  return (
    <div
      className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5`}
    >
      <div
        className="absolute inset-0 transition-opacity bg-[#222222]"
        onClick={onClose}
      ></div>
      <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
        <div className="flex justify-end p-5">
          <img
            src={Close}
            alt="close"
            onClick={onClose}
            className="button-action"
          />
        </div>
        <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
          Description
        </h2>
        <div className="relative overflow-y-auto py-4 px-[50px]">
          <ul className="list-disc">
            <li
              className="text-[#393C51] dark:text-white text-sm font-normal leading-5 capitalize mb-3"
              dangerouslySetInnerHTML={{
                __html: content || "",
              }}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContentModal;
