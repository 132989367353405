import React from 'react';

const AboutFundamental = () => {
    return (
        <div className="overflow-x-auto">
            <div className="min-w-full inline-block align-middle">
                <div className="table-container">
                    <table className="min-w-full dark:bg-primary-dark bg-white">
                        <caption className="text-left text-title font-bold pt-4 dark:bg-table-dark bg-table-light pb-2 pr-4 pl-3.5">
                            About Fundamental
                        </caption>
                        <tbody>
                            <tr>
                                <td className="pl-4 pr-3.5 py-2 text-gray-500">Date Published:</td>
                                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium">04.03.2024</td>
                            </tr>
                            <tr>
                                <td className="pl-4 pr-3.5 py-2 text-gray-500">Source Type:</td>
                                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium">Article</td>
                            </tr>
                            <tr>
                                <td className="pl-4 pr-3.5 py-2 text-gray-500">Author:</td>
                                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium">Amily Smith</td>
                            </tr>
                            <tr>
                                <td className="pl-4 pr-3.5 py-2 text-gray-500">Net Debit:</td>
                                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium">3,799.8</td>
                            </tr>
                            <tr>
                                <td className="pl-4 pr-3.5 pt-2 pb-4 text-gray-500">Payment Method:</td>
                                <td className="pl-4 pr-3.5 pt-2 pb-4 text-[#000817] dark:text-white text-base font-medium">American Express</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AboutFundamental;
