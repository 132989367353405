import React from "react";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";

interface UpcomingEvent {
  dateEventModifier: string;
  eventTypeName: string;
  targetId: string | number;
  targetName: string;
  mnaName: string;
  mnaId: string;
  dateEventTypeId: string;
  dateEventTypeName: string;
  comment?: string;
}

interface UpcomingEventsResponse {
  content: UpcomingEvent[];
}

interface UpcomingEventsTableProps {
  upcomingEvents: UpcomingEventsResponse;
  handleMouseEnter: any;
  handleMouseLeave: any;
}

const UpcomingEventsTable: React.FC<UpcomingEventsTableProps> = ({
  upcomingEvents,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const navigate = useNavigate();

  return (
    <div className="-m-1.5 overflow-x-auto">
      <div className="p-1.5 min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-table-dark bg-table-light">
            <caption className="text-left heading-bold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
              Upcoming Events
            </caption>
            <thead className="bg-table-sub-light dark:bg-black dark:text-white">
              <tr>
                <th
                  scope="col"
                  className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                >
                  Date Event Type
                </th>
                <th
                  scope="col"
                  className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                >
                  MNA Name
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#DCDBEE] align-top">
              {upcomingEvents.content?.map(
                (upcoming: UpcomingEvent, index: number) => (
                  <tr key={index}>
                    <td
                      className="px-[30px] py-5 text-regular break-words"
                      onMouseEnter={(e) =>
                        handleMouseEnter(upcoming, {
                          x: e.clientX,
                          y: e.clientY,
                        })
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {`${moment(
                        upcoming.dateEventModifier.split(" ")[0]
                      ).format("MM/DD/YYYY")} ${upcoming.dateEventModifier
                        .split(" ")
                        .slice(1)
                        .join(" ")}`}
                    </td>
                    <td
                      className="px-[30px] py-5 text-regular break-words"
                      onMouseEnter={(e) =>
                        handleMouseEnter(upcoming, {
                          x: e.clientX,
                          y: e.clientY,
                        })
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {upcoming.eventTypeName}
                    </td>
                    <td
                      className="px-[30px] py-5 text-regular-bold cursor-pointer break-words"
                      onClick={() => navigate(`/mna/${upcoming?.targetId}`)}
                    >
                      <Link
                        className="text-secondary"
                        to={`/mna/${upcoming?.targetId}`}
                      >
                        {" "}
                        {upcoming.targetName}
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsTable;
