// DatePickerMiniForm.tsx
import React, { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import CalendarIcon from '../../../assets/svg/calendar.svg';

interface DatePickerMiniFormProps {
  selectedDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  formik: any;
  datePickerRef: React.RefObject<ReactDatePicker>;
}

export default function DatePickerMna({
  selectedDate,
  handleDateChange,
  formik,
  datePickerRef,
}: DatePickerMiniFormProps) {
  return (
    <>
      <ReactDatePicker
        autoComplete="off"
        ref={datePickerRef}
        selected={selectedDate}
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        name="dateAnnounced"
        className={`dark:text-white dark:bg-primary-dark border border-[#eeeeee] rounded-md text-left px-4 text-base text-[#7B7B7B] h-12 font-light w-full outline-none ${
          formik.errors.dateAnnounced && formik.touched.dateAnnounced
            ? 'border border-[#ff6464]'
            : ''
        }`}
        dateFormat="MM/dd/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <img
        src={CalendarIcon}
        alt="Calendar Icon"
        className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none w-5 h-5"
        onClick={() => datePickerRef.current?.setFocus()}
      />
      </>
  );
}
