import axios, { AxiosInstance } from "axios";
import { Configuration } from "../../openapi";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setAuthorizationHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers = config.headers || {}; 
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response && (error.response.status === 401 || error.response.data?.exception === "TokenExpiredException")) {
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          const userId = localStorage.getItem("userId");

          if (refreshToken && userId) {
            const response = await axiosInstance.post("/refresh-token", {
              refreshToken,
              userId,
            });

            const { accessToken, refreshToken: newRefreshToken } = response.data;
            localStorage.setItem("token", accessToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (error: any) {
          if (error.response && error.response.data.message.includes("Refresh token was expired")) {
            window.location.href = "/";
          } else {
            console.error("An error occurred while refreshing the token:", error);
          }
        }
      }
      return Promise.reject(error);
    }
);

export const apiConfig = (): Configuration => {
  setAuthorizationHeader();

  const customConfig: Configuration = {
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: axiosInstance.defaults,
    isJsonMime: (mime: string) => {
      const jsonMime: RegExp = new RegExp('^(application/json|[^;/ \\t]+/[^;/ \\t]+[+]json)[ \\t]*(;.*)?$', 'i');
      return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    },
  };

  return customConfig;
};
