import React from 'react'
import EditableFieldMna from '../../../components/EditableFields/EditableField';
import moment from 'moment';
import { addBrokerClosingDateLocally } from '../../../components/EditableFields/EditableFieldsUtilities/editableFieldsUtils';
import { Item } from '../../../interfaces/pages/variedInterfaces';


interface DatesProp {
    data: any;
    setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
    setData: React.Dispatch<React.SetStateAction<any>>;
    fetchMnaData: () => void;
    safeId: any;
}
export default function Dates({ data, setFetchRequired, setData, fetchMnaData, safeId}: DatesProp) {
    const handleFetchMnaData = async () => {
        fetchMnaData();
      };
  return (
    <div className="border border-[#DCDBEE] rounded-md overflow-visible p-4">
    <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
      <caption className="text-left heading-bold text-title pb-2 bg-table-light dark:bg-table-dark">
        Dates
      </caption>
      <tbody className="">
        <tr className="w-full">
          <td className="align-top w-1/2">
            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
              Out Dates:
            </p>
          </td>
          <td className="flex w-full">
            <div
              className={`flex ${
                data.outDates?.length > 1
                  ? "flex-col"
                  : "flex-row"
              } w-full`}
            >
              {data.outDates?.map(
                (item: Item, index: number) => (
                  <div
                    key={`out-dates${index}`}
                    className="flex w-full"
                  >
                    <div className="w-1/2">
                      <EditableFieldMna
                        fieldtype="text"
                        fieldName="outDates"
                        data={item?.comment}
                        fullData={data}
                        dateIndex={index}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                    <div className="w-1/2">
                      <EditableFieldMna
                        data={
                          item?.dateEvent
                            ? moment(item.dateEvent).isValid()
                              ? moment(item.dateEvent).format(
                                  "MM/DD/YYYY"
                                )
                              : ""
                            : ""
                        }
                        fieldtype="date"
                        fieldName="outDates"
                        dateIndex={index}
                        fullData={data}
                        dateItemValue={item.dateEvent}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </td>
        </tr>
        <tr className="w-full">
          <td></td>

          <td className="flex justify-end w-full">
            {" "}
            <button
              type="button"
              data-testid="AddNewPlus"
              onClick={() => {
                addBrokerClosingDateLocally(
                  "outDates",
                  safeId,
                  setData
                );
              }}
              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                data.outDates?.length === 0 ? "" : "my-2"
              }`}
            >
              Add new
            </button>
          </td>
        </tr>
        <tr className="w-full">
          <td className="align-top w-1/2">
            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
              Broker Closing Estimate Date:
            </p>
          </td>
          <td className="flex w-full">
            <div
              className={`flex ${
                data.brokerClosingDates?.length > 1
                  ? "flex-col"
                  : "flex-row"
              } w-full`}
            >
              {data.brokerClosingDates?.map(
                (item: Item, index: number) => (
                  <div
                    key={`broker-closing${index}`}
                    className="flex w-full"
                  >
                    <div className="w-1/2">
                      <EditableFieldMna
                        fieldtype="text"
                        fieldName="brokerClosingDates"
                        data={item?.comment}
                        fullData={data}
                        dateIndex={index}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                    <div className="w-1/2">
                      <EditableFieldMna
                        data={
                          item?.dateEvent
                            ? moment(item.dateEvent).isValid()
                              ? moment(item.dateEvent).format(
                                  "MM/DD/YYYY"
                                )
                              : ""
                            : ""
                        }
                        fieldtype="date"
                        fieldName="brokerClosingDates"
                        dateIndex={index}
                        fullData={data}
                        dateItemValue={item.dateEvent}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </td>
        </tr>
        <tr className="w-full">
          <td></td>
          <td className="flex justify-end w-full">
            <button
              type="button"
              data-testid="AddNewPlus"
              onClick={() => {
                addBrokerClosingDateLocally(
                  "brokerClosingDates",
                  safeId,
                  setData
                );
              }}
              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                data.brokerClosingDates?.length === 0
                  ? ""
                  : "my-2"
              }`}
            >
              Add new
            </button>
          </td>
        </tr>
        <tr className="w-full">
          <td className="align-top w-1/2">
            {" "}
            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
              Financing Date:
            </p>
          </td>
          <td className="flex w-full">
            <div
              className={`flex ${
                data.financingDates?.length > 1
                  ? "flex-col"
                  : "flex-row"
              } w-full`}
            >
              {data.financingDates?.map(
                (item: Item, index: number) => (
                  <div
                    key={`financingDatess${index}`}
                    className="flex w-full"
                  >
                    <div className="w-1/2 ">
                      <EditableFieldMna
                        fieldtype="text"
                        fieldName="financingDates"
                        data={item?.comment}
                        fullData={data}
                        dateIndex={index}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                    <div className="w-1/2">
                      <EditableFieldMna
                        data={
                          item?.dateEvent
                            ? moment(item.dateEvent).isValid()
                              ? moment(item.dateEvent).format(
                                  "MM/DD/YYYY"
                                )
                              : ""
                            : ""
                        }
                        fieldtype="date"
                        fieldName="financingDates"
                        dateIndex={index}
                        fullData={data}
                        dateItemValue={item.dateEvent}
                        setFetchMnaDetails={setFetchRequired}
                        setLocalData={setData}
                        fetchMnaData={handleFetchMnaData}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </td>
        </tr>
        <tr className="w-full">
          <td></td>
          <td className="flex justify-end w-full">
            {" "}
            <button
              type="button"
              data-testid="AddNewPlus"
              onClick={() => {
                addBrokerClosingDateLocally(
                  "financingDates",
                  safeId,
                  setData
                );
              }}
              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                data.financingDates?.length === 0 ? "" : "my-2"
              }`}
            >
              Add new
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}
