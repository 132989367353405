import React from "react";
import EditableField from "../../../components/EditableFields/EditableField";

interface ProcessTabProps {
  data: any;
  fetchRegulatoryData: () => void;
}

const ProcessTab: React.FC<ProcessTabProps> = ({
  data,
  fetchRegulatoryData,
}) => {
    const handleFetcRegulatoryData = async () => {
        fetchRegulatoryData();
    }
  return (
    <>
      <div className=" px-3.5 pt-4 pb-2 bg-table-light dark:bg-table-dark text-title text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
        Process
      </div>
      <div className="px-2 pb-2">
        <div className="px-2 pb-2">
          <EditableField
            data={data?.process}
            fieldtype="textEditor"
            fieldName="process"
            // fetchRegulatoryData={fetchRegulatoryData}
            fetchMnaData={handleFetcRegulatoryData}
            isRegulatory={true}
          />
        </div>
      </div>
    </>
  );
};

export default ProcessTab;
