import { FormProps } from "../../../interfaces/pages/AddEditMna";

// Define initial form values
export const initialValues: FormProps = {
  name: "",
  categoryId: null,
  subCategoryId: null,
  description: "",
  competitors:"",
  customers:"",
  acquirer: "",
  target: "",
  tickerAcquirer: "",
  tickerTarget: "",
  sectorAcquirer: "",
  sectorTarget: "",
  incorpAcquirer: "",
  incorpTarget: "",
  hqAcquirer: "",
  hqTarget: "",
  geoExposureAcquirer: "",
  geoExposureTarget: "",
  financialAdvisorForAcquirer: "",
  financialAdvisorForTarget: "",
  legalCounselForAcquirer: "",
  legalCounselForTarget: "",
  whatMatters: [{ note: "", mnaId:  "0" }],
  premium: "",
  terms: "",
  tags: [],
  files: { add: [], delete: [] },
  dateAnnounced: new Date(),
  dateClosing: null,
  closingLanguage: "",
  brokerClosingDates: [
    {
      dateEvent: null,
      comment: "",
      mnaId: "0",
    },
  ],
  financingDates: [
    {
      dateEvent: null,
      comment: "",
      mnaId:  "0",
    },
  ],
  terminationFeeAcquirer: "",
  terminationFeeTarget: "",
  outDates: [
    {
      dateEvent: null,
      comment: "",
      mnaId:  "0",
    },
  ],
  requiredToLitigate: null,
  requiredToLitigateComment: "",
  appraisalRights: "",
  specificPerformance: "",
  links: [
    {
      linkCategoryId: "",
      link: "",
      mnaId:  "0",
    },
  ],
  features: [
    {
      name: "",
      value: "",
      mnaId:  "0",
    },
  ],
  conditions: [
    {
      statusId: "",
      conditionCategoryId: "",
      commentary: "",
      filed: null,
      expectedConfirmed: null,
      categoryOtherComment: "",
      mnaId:  "0",
    },
  ],
  txDocSummaries: [{ summary: "", typeId: "", mnaId:  "0" }],
};
