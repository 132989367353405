import React from "react";

interface SaveButtonProps {
  type: "button" | "submit" | "reset"; 
}

const SaveButtonMna: React.FC<SaveButtonProps> = ({ type }) => {
  return (
    <button
      type={type}
      className="bg-[#3B97FF] text-[#fff] rounded-md h-12 flex items-center justify-center w-1/6 font-extrabold text-sm"
    >
      Save
    </button>
  );
};

export default SaveButtonMna;
