import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { getApiData } from "../../../controller/getApiDataConfiguration";
import { Sort } from "../../../interfaces/pages/variedInterfaces";


export const fetchMnaListService = async (
  pageIndex: number,
  pageSize: number,
  sortBy: Sort[],
  search: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  try {
    const api = new getApiData();
    return await api.getMnaDomainApi(search, pageIndex, pageSize, sortBy);
  } catch (error) {
    expiredTokenValidation(error);
  } finally {
    setLoading(false);
  }
};