import React from 'react';
import ReactDOM from 'react-dom';

interface TooltipProps {
    text: string;
    targetRef: React.RefObject<HTMLDivElement>;
}

const Tooltip: React.FC<TooltipProps> = ({ text, targetRef }) => {
    if (!targetRef.current) return null;

    const bounds = targetRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
        <div
            className="fixed w-[200px] break-words whitespace-normal z-[9999] p-2 bg-[#3B97FF] border-[1px] border-[#DCDBEE] text-white rounded-md shadow-lg"
            style={{
                left: bounds.left + (bounds.width / 2) - 100,
                top: bounds.bottom + 5,
            }}
        >
            {text}
        </div>,
        document.body
    );
};

export default Tooltip;
