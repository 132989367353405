const fundamentalInfo = [
  { label: "Name", input1Name: "target", input2Name: "acquirer" },
  {
    label: "Ticker",
    input1Name: "tickerTarget",
    input2Name: "tickerAcquirer",
  },
  {
    label: "Sector",
    input1Name: "sectorTarget",
    input2Name: "sectorAcquirer",
  },
  {
    label: "Incorporation",
    input1Name: "incorpTarget",
    input2Name: "incorpAcquirer",
  },
  { label: "Headquarters", input1Name: "hqTarget", input2Name: "hqAcquirer" },
  {
    label: "Geographic Exposure",
    input1Name: "geoExposureTarget",
    input2Name: "geoExposureAcquirer",
  },
  {
    label: "Financial Advisors",
    input1Name: "financialAdvisorForTarget",
    input2Name: "financialAdvisorForAcquirer",
  },
  {
    label: "Break Fee",
    input1Name: "terminationFeeTarget",
    input2Name: "terminationFeeAcquirer",
  },
  {
    label: "Legal Counsel",
    input1Name: "legalCounselForTarget",
    input2Name: "legalCounselForAcquirer",
  },
];

// Titles for every form step
const formStepsTitles = [
  "Company Information",
  "Basic Info",
  "What Matters",
  "MNA Basics",
  "Features",
  "Links",
  "Conditions",
  "Transaction Document Summaries",
];

export { fundamentalInfo, formStepsTitles };
