import React from 'react';
import { toast } from 'react-hot-toast';
import Accept from '../../assets/svg/accept.svg'
import Cancel from '../../assets/svg/icons-cancel.svg'

type AlertType = 'error' | 'success' | 'warning' | 'info';

interface AlertProps {
    message: string;
    type: AlertType;
}

const Alert: React.FC<AlertProps> = ({ message, type }) => {

    const messageText = message.match(/^\d{3} [A-Za-z]+/)
        ? message.split('"').slice(1).join(' ') 
        : message;

    return (
        <div
            className={`max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 animate-enter bg-table-light`}
        >
            <div className="flex-1 w-0 p-4">
                <div className="flex items-center">
                    <div className="flex-shrink-0 pt-0.5">
                        {type === 'error' && <img src={Cancel} alt='accept' style={{width: '50px', height: '50px'}}/>}
                        {type === 'success' && <img src={Accept} alt='accept' style={{width: '50px', height: '50px'}}/>}
                    </div>
                    <div className="ml-3 flex-1 flex justify-center items-center ">
                        <p className={`text-black`}>{messageText}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const showAlert = ({ message, type }: AlertProps) => {
    console.log('Alert parameters:', message, type);
    toast.custom((t) => <Alert {...{ message, type }} />, {
        duration: 4000,
    });
};

export { Alert, showAlert };
