import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { getApiData } from "../../../controller/getApiDataConfiguration";

export const NoteServices = async (
  search: string,
  pageIndex: number,
  pageSize: number,
  sortBy: any
) => {
  try {
    const api = new getApiData();
    return await api.getNoteDomainApi(search, pageIndex, pageSize, sortBy);
  } catch (error) {
    expiredTokenValidation(error);
    throw error; // Re-throw the error if further handling is needed
  }
};