import { FileDomainApi } from "../../../openapi";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { getSortArray } from "../../../helpers/getSortArray";
import { Sort } from "../../../interfaces/pages/variedInterfaces";

const api = new FileDomainApi(apiConfig());

export const fetchFiles = async (
  search: string,
  pageIndex: number,
  pageSize: number,
  sortBy: Sort[]
) => {
  try {
    return search
      ? await api.findFiles(
        `filename:${search}`,
        undefined,
        undefined,
        undefined
      )
      : await api.findFiles("", pageIndex, pageSize, getSortArray(sortBy));
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const downloadFilesApi = async (id: string, type: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const response = await api.downloadFile(id);
    const imageUrl: any = response.config.url;

    const imageResponse = await fetch(imageUrl, { headers });

    if (imageResponse.ok) {
      const imageBlob = await imageResponse.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(imageBlob);

      const parts = type.split(".");
      const fileName = parts[0];
      downloadLink.download = `${fileName}.${parts[1] || "png"}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
