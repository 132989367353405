import { FundamentalResponse } from "../openapi";

function assingTargetValues(fundamentalTarget: FundamentalResponse, values: any) {
  const targetObject: any = {};
  targetObject.tickerTarget = fundamentalTarget.ticker;
  targetObject.sectorTarget = fundamentalTarget.sectorId;
  targetObject.incorpTarget = fundamentalTarget.incorp || values['incorpTarget'];
  targetObject.hqTarget = fundamentalTarget.hq || values['hqTarget'];;
  targetObject.geoExposureTarget = fundamentalTarget.geoExposure || values['geoExposureTarget'];;
  return targetObject;
}

function assingAcquirerValues(fundamentalAcquirer: FundamentalResponse, values: any) {
  const acquirerObject: any = {};
  acquirerObject.tickerAcquirer = fundamentalAcquirer.ticker;
  acquirerObject.sectorAcquirer = fundamentalAcquirer.sectorId;
  acquirerObject.incorpAcquirer = fundamentalAcquirer.incorp || values['incorpAcquirer'];
  acquirerObject.hqAcquirer = fundamentalAcquirer.hq || values['hqAcquirer'];
  acquirerObject.geoExposureAcquirer = fundamentalAcquirer.geoExposure || values['geoExposureAcquirer'];
  return acquirerObject;
}

export { assingTargetValues, assingAcquirerValues };
