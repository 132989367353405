import {
  MnaCategoryDomainApi,
  FundamentalDomainApi,
  SectorDomainApi,
  MnaViewApi,
} from "../../../openapi";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { MnaToCreate, FundamentalContent } from "./servicesTypes";

export const fetchCategorylist = async (
  setCategoryList: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >
) => {
  try {
    const api = new MnaCategoryDomainApi(apiConfig());
    const response = await api.getMnaCategories("", 0, 20, undefined);
    const filteredOptions = response?.data?.content!.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    setCategoryList(filteredOptions);
  } catch (error) {
    expiredTokenValidation(error);
  }
};

export const createMnaView = async (mnaToCreate: MnaToCreate) => {
  const api = new MnaViewApi(apiConfig());
  return await api.createViewMna(mnaToCreate);
};

export async function getFundamentalById(id: string) {
  const api = new FundamentalDomainApi(apiConfig());
  const fundamentalResponse = await api.getFundamentalById(id);
  return fundamentalResponse.data;
}

const pageable = { page: 0, size: 20, sort: ["asc"] };
export async function fetchFundamentalByQuery(
  value: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<{ value: number; label: string }[]> {
  const api = new FundamentalDomainApi(apiConfig());

  setLoading(true);
  try {
    const fundamentalsResponse = await api.searchFundamentalByNameOrTicker(
      value,
      pageable
    );
    const fundamentalsContent = (
      fundamentalsResponse.data.content as FundamentalContent[]
    )?.map((content) => ({
      value: content.id || 0,
      label: `${content.ticker ? content.ticker + "-" : ""} ${content.name}`,
    }));
    return fundamentalsContent || [{ value: 0, label: "" }];
  } catch (error) {
    console.error("Error fetching fundamentals:", error);
    return [{ value: 0, label: "Error loading data" }];
  } finally {
    setLoading(false);
  }
}

export const fetchSectorlist = async (
  setSectorList: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >
) => {
  try {
    const api = new SectorDomainApi(apiConfig());
    const response = await api.getSectors();
    const filteredOptions = response?.data?.content!.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    setSectorList(filteredOptions);
  } catch (error) {
    expiredTokenValidation(error);
  }
};
