// React
import React, { useCallback, useState } from "react";
// Components
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table2 from "../../components/Tables/AlertTable";
// Types
import { Sort } from "../../interfaces/pages/variedInterfaces";
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Images
import alertIcon from "../../assets/svg/blue-alert.svg";
import { fetchAlerts } from "./services/alertServices";
import AlertContentModal from "./components/AlertContentModal";

const AlertList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();

  const fetchAlertList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      return await fetchAlerts(pageIndex, pageSize, sortBy);
    },
    []
  );

  const columnsInstance = new Columns();

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[30px] ${isMenuOpen ? "pl-[314px]" : "pl-[90px]"
          } duration-500`}
      >
        <Breadcrumbs breadcrumbs={["Alerts"]} icon={alertIcon} />
        <div className="mt-[31px]">
          <Table2
            columns={columnsInstance.AlertList()}
            fetchData={fetchAlertList}
            additionalDataKey="content"
            openModal={openModal}
          />
        </div>
      </div>
      <AlertContentModal
        isOpen={isModalOpen}
        content={content}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default AlertList;