import { apiConfig, axiosInstance } from "../../../components/ConfigurationApi/Configuration";
import { DataMigrationDomainApiAxiosParamCreator, FundamentalDomainApiAxiosParamCreator, MnaDomainApiAxiosParamCreator, NoteDomainApiAxiosParamCreator, RegulatoryDomainApiAxiosParamCreator } from "../../../openapi";
//svgs
import mnasIcon from "../../../assets/svg/mnas-data-migration.svg";
import notesIcon from "../../../assets/svg/notes-data-migration.svg";
import regulatoryIcon from "../../../assets/svg/mnas-data-migration.svg";
import fundamentalsIcon from "../../../assets/svg/regulatory-data-migration.svg";

const configuration = apiConfig();
const mnaApi = MnaDomainApiAxiosParamCreator(configuration);
const noteApi = NoteDomainApiAxiosParamCreator(configuration);
const regulatoryApi = RegulatoryDomainApiAxiosParamCreator(configuration);
const fundamentalsApi = FundamentalDomainApiAxiosParamCreator(configuration);
const dataMigrationApi = DataMigrationDomainApiAxiosParamCreator(configuration);

interface MigrationItem {
  id: string;
  title: string;
  icon: string;
  apiFunction?: (options: any) => Promise<any>;
  statusFunction?: () => Promise<any>;
}


export const MigrationControl:MigrationItem[] = [
  {
    id: "1",
    title: "MNAs",
    icon: mnasIcon,
    apiFunction: async (options: any) => {
      const requestConfig = await mnaApi.migrateMnaToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "2",
    title: "Notes",
    icon: notesIcon,
    apiFunction: async (options: any) => {
      const requestConfig = await noteApi.migrateNoteToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "3",
    title: "Regulatory Library",
    icon: regulatoryIcon,
    apiFunction: async (options: any) => {
      const requestConfig = await regulatoryApi.migrateRegulatorsToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: fundamentalsIcon,
    apiFunction: async (options: any) => {
      const requestConfig = await fundamentalsApi.migrateFundamentals(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
];


export const MigrationStatus = [
  {
    id: "1",
    title: "MNAs",
    icon: mnasIcon,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.mnaMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "2",
    title: "Notes",
    icon: notesIcon,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.notesMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "3",
    title: "Regulatory Library",
    icon: regulatoryIcon,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.regulatoryMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: fundamentalsIcon,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.fundamentalsMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
];
