// React
import React from "react";
// Third party
import { Link, useLocation, useParams } from "react-router-dom";
// Images
import { PlusIcon } from "../../assets/icons";

interface BreadcrumbsProps {
  breadcrumbs: string[];
  icon?: string;
  addItem?: () => void;
  updateItem?: () => void;
  isSort?: boolean;
  options?: any[];
  selectedOption?: string;
  handleItemClick?: (option: string) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  icon,
  isSort,
  options,
  selectedOption,
  addItem,
  updateItem,
  handleItemClick,
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div className=" flex items-center justify-between gap-[30px] flex-wrap">
      <ol className="list-none p-0 inline-flex text-[#181825]';">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className="flex items-center gap-4">
            {index === 0 && icon && (
              <img
                src={icon}
                alt="icon"
                className="dark:brightness-[3.5] brightness-0 hidden"
              />
            )}
            {breadcrumbs.length === 1 ? (
              <div>
                <Link
                  to={pathname}
                  className="text-black dark:text-white text-2xl font-extrabold"
                >
                  {breadcrumb}
                </Link>
              </div>
            ) : (
              <div className={"flex gap-3"}>
                {index === 0 && (
                  <>
                    <span className="text-title dark:text-white text-2xl font-extrabold">
                      {breadcrumb}
                    </span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={"w-8 mr-3"}
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M4 12H20M20 12L16 8M20 12L16 16"
                          stroke="#B6B9C5"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </>
                )}
                {index >= 1 && (
                  <div>
                    <span className="text-title text-2xl font-extrabold dark:text-white uppercase">
                      {breadcrumb}
                    </span>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ol>
      <div className="flex items-center gap-[30px]">
        <div className="flex justify-between items-center gap-2 flex-wrap">
          {[
            `/mna/${id}`,
            `/old-mna/${id}`,
            `/fundamental/${id}`,
            `/notes/${id}`,
          ].includes(pathname) && (
              <div onClick={updateItem}>
                <div className="bg-green-primary rounded-md py-2 px-3 border border-[#E6E6F9] bg-gray-button-dark cursor-pointer bg-dark:bg-button-dark">
                  <svg
                    className={"w-6"}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            )}
          {[
            "/notes",
            "/mnas",
            `/mna/${id}`,
            "/company",
            "/fundamental",
            "/old-mnas",
            `/old-mna/${id}`,
            `/fundamental/${id}`,
            "/regulatory",
            `/regulatory/${id}`,
          ].includes(pathname) && (
              <div
                onClick={addItem}
                className="flex items-center gap-4 text-white font-semibold rounded-md px-[13.5px] py-[6.5px] border border-[#E6E6F9] cursor-pointer bg-[#3B97FF] dark:bg-button-dark h-8 text-sm"
              >
                <span>Add new</span>
                <PlusIcon />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
