// Components
import InputField from "../../CommonInputs";
import DeleteTooltip from "../../DeleteTooltip";
// Types
import { StepBase } from "..";
import { useParams } from "react-router-dom";
import TextEditor from "../../TextEditor";
import { useRef } from "react";

import ReactQuill from "react-quill";

interface StepFourProps extends StepBase {
  Minus: string;
  Plus: string;
}

export default function StepFour(props: StepFourProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
    onEditorRef,
  } = props;
  const { id } = useParams();
  const $closingLanguage = useRef<ReactQuill>(null);

  const brokerClosingDates =
    formik.values.brokerClosingDates.length > 0
      ? formik.values.brokerClosingDates
      : [{ dateEvent: null, comment: "", mnaId: "0" }];
  const financingDates =
    formik.values.financingDates.length > 0
      ? formik.values.financingDates
      : [{ dateEvent: null, comment: "", mnaId: "0" }];

  const outDates =
    formik.values.outDates.length > 0
      ? formik.values.outDates
      : [{ dateEvent: null, comment: "", mnaId: "0" }];

  const handleAddOutDates = () => {
    formik.setValues({
      ...formik.values,
      outDates: [
        ...formik.values.outDates,
        {
          dateEvent: null,
          comment: "",
          mnaId: id,
        },
      ],
    });
  };

  const handleAddBrokerClosingDates = () => {
    formik.setValues({
      ...formik.values,
      brokerClosingDates: [
        ...formik.values.brokerClosingDates,
        {
          dateEvent: null,
          comment: "",
          mnaId: id,
        },
      ],
    });
  };

  const handleAddFinancingDates = () => {
    formik.setValues({
      ...formik.values,
      financingDates: [
        ...formik.values.financingDates,
        {
          dateEvent: null,
          comment: "",
          mnaId: id,
        },
      ],
    });
  };

  const handleButtonClick = (buttonValue: any) => {
    formik.setFieldValue(
      "requiredToLitigate",
      formik.values.requiredToLitigate === buttonValue ? null : buttonValue
    );
  };

  return (
    <>
      {/* MNA Basics */}
      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row">
            {/* Terms */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Terms"
                id="terms"
                name="terms"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Mna Value */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="MNA Value"
                id="size"
                name="size"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Premium */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Premium"
                id="premium"
                name="premium"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
          <div className="w-full xl:w-full ">
            <TextEditor
              formik={formik}
              id="closingLanguage"
              name="closingLanguage"
              quillRef={$closingLanguage}
              label="Closing Language"
              isDynamic
              value={formik.values.closingLanguage|| ""} 
              serverError={serverError || ""}
              onEditorRef={onEditorRef}
            />
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            {/* Required to Litigate */}
            <div className="w-full xl:w-1/2">
              <div className="flex justify-between items-center">
                <label
                  htmlFor="Content"
                  className="text-base text-[#393C51] dark:text-white font-medium mb-2 flex gap-1"
                >
                  Required to Litigate
                </label>
              </div>
              <div className="flex space-x-4 justify-between">
                <button
                  onClick={() => handleButtonClick(true)}
                  type="button"
                  className={`w-full px-6 py-3 rounded-full font-semibold transition-all duration-300 border ${
                    formik.values.requiredToLitigate === true
                      ? "bg-success text-white shadow-lg transform scale-105 border-success"
                      : "border-[#C3C1DF] text-secondary bg-[#FAFAFE] dark:bg-button-dark dark:text-white hover:bg-success hover:text-white hover:border-success"
                  }`}
                >
                  True
                </button>
                <button
                  onClick={() => handleButtonClick(false)}
                  type="button"
                  className={`w-full px-6 py-3 rounded-full font-semibold transition-all duration-300 border ${
                    formik.values.requiredToLitigate === false
                      ? "bg-danger text-white shadow-lg transform scale-105 border-danger"
                      : "border-[#C3C1DF] text-secondary bg-[#FAFAFE] dark:bg-button-dark dark:text-white hover:bg-danger hover:text-white hover:border-danger"
                  }`}
                >
                  False
                </button>
              </div>
            </div>

            {/* Required to Litigate Comment */}
            <div className="w-full xl:w-1/2">
              <InputField
                label="Required to Litigate Comment"
                id="requiredToLitigateComment"
                name="requiredToLitigateComment"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last
              />
            </div>
          </div>

          {/* Appraisal Rights and Specific Performance Fields */}
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/2">
              <InputField
                label="Appraisal Rights"
                id="appraisalRights"
                name="appraisalRights"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>
            <div className="w-full xl:w-1/2">
              <InputField
                label="Specific Performance"
                id="specificPerformance"
                name="specificPerformance"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>

          {/* Broker Closing Dates */}
          <div className="mt-[30px]">
            {brokerClosingDates.map((outDate: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center relative ${
                  index === formik.values.brokerClosingDates?.length - 1
                    ? " "
                    : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Broker Closing Estimate Date"
                    id={`brokerClosingDates[${index}].dateEvent`}
                    name={`brokerClosingDates[${index}].dateEvent`}
                    type="date"
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.dateEvent}
                  />
                </div>

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Comment"
                    id={`brokerClosingDates[${index}].comment`}
                    name={`brokerClosingDates[${index}].comment`}
                    type="text"
                    placeholder="Enter here..."
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.comment}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  <button
                    onClick={() => {
                      setDeleteID(index);
                      setAssociation("brokerClosingDates");
                      setDeleteModal(true);
                    }}
                    type="button"
                    className="text-secondary text-base font-medium flex items-center gap-[6px]"
                  >
                    <img src={Minus} alt="minus" /> Remove
                  </button>
                  {deleteID === index &&
                    association === "brokerClosingDates" && (
                      <DeleteTooltip
                        className="bottom-10"
                        tooltip={deleteModal}
                        setTooltip={setDeleteModal}
                        onDeleteItem={handleRemoveAssociation}
                        top
                      />
                    )}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                data-testid="AddNewPlus"
                onClick={handleAddBrokerClosingDates}
                className="text-secondary text-lg py-4 font-bold flex items-center justify-center border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>

          {/* Financing Dates */}
          <div className="mt-[30px]">
            {financingDates.map((outDate: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center relative ${
                  index === formik.values.financingDates?.length - 1
                    ? " "
                    : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Financing Date"
                    id={`financingDates[${index}].dateEvent`}
                    name={`financingDates[${index}].dateEvent`}
                    type="date"
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.dateEvent}
                  />
                </div>

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Comment"
                    id={`financingDates[${index}].comment`}
                    name={`financingDates[${index}].comment`}
                    type="text"
                    placeholder="Enter here..."
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.comment}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  <button
                    onClick={() => {
                      setDeleteID(index);
                      setAssociation("financingDates");
                      setDeleteModal(true);
                    }}
                    type="button"
                    className="text-secondary text-base font-medium flex items-center gap-[6px]"
                  >
                    <img src={Minus} alt="minus" /> Remove
                  </button>
                  {deleteID === index && association === "financingDates" && (
                    <DeleteTooltip
                      className="bottom-10"
                      tooltip={deleteModal}
                      setTooltip={setDeleteModal}
                      onDeleteItem={handleRemoveAssociation}
                      top
                    />
                  )}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                onClick={handleAddFinancingDates}
                className="text-secondary text-lg py-4 font-bold flex items-center justify-center border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
                data-testid="plusAddnew"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>

          {/* Out Dates */}
          <div className="mt-[30px]">
            {outDates.map((outDate: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center relative ${
                  index === formik.values.outDates?.length - 1
                    ? " "
                    : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Out Date"
                    id={`outDates[${index}].dateEvent`}
                    name={`outDates${index}.dateEvent`}
                    type="date"
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.dateEvent}
                  />
                </div>

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Comment"
                    id={`outDates[${index}].comment`}
                    name={`outDates[${index}].comment`}
                    type="text"
                    placeholder="Enter here..."
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.comment}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  <button
                    onClick={() => {
                      setDeleteID(index);
                      setAssociation("outDates");
                      setDeleteModal(true);
                    }}
                    type="button"
                    className="text-secondary text-base font-medium flex items-center gap-[6px]"
                  >
                    <img src={Minus} alt="minus" /> Remove
                  </button>
                  {deleteID === index && association === "outDates" && (
                    <DeleteTooltip
                      className="bottom-10"
                      tooltip={deleteModal}
                      setTooltip={setDeleteModal}
                      onDeleteItem={handleRemoveAssociation}
                      top
                    />
                  )}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                onClick={handleAddOutDates}
                className="text-secondary text-lg py-4 font-bold flex items-center justify-center border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
