// DateDetails.tsx
import React from "react";
import moment from "moment";

interface User {
  username?: string;
}

interface NotesData {
  datePublished?: string;
  dateCreated?: string;
  createdBy?: User;
  dateModified?: string;
  lastModifiedBy?: User;
}

interface DateDetailsProps {
  notesData: NotesData;
}

const DateDetails: React.FC<DateDetailsProps> = ({ notesData }) => {
  return (
    <div className="flex justify-end">
      <span className="group relative cursor-pointer">
        {/* Hover Tooltip */}
        <div className="absolute bottom-10 lg:-bottom-9 lg:right-3 -translate-x-[50%] hidden group-hover:block w-[350px]">
          <div className="bg-[#fff] text-[#393C51] dark:text-white dark:bg-primary-dark transition-all text-sm px-[14px] pt-[12px] pb-[17px] rounded-[10px] border border-[#4185EE]">
            <ul className="max-w-md space-y-1 text-dark list-disc list-inside dark:text-white">
              <li>
                Date Published:{" "}
                {notesData?.datePublished
                  ? moment(notesData.datePublished).format(
                    "MM/DD/yyyy hh:mm a"
                  )
                  : "N/A"}
              </li>
              <li>
                Date Created:{" "}
                {notesData?.dateCreated
                  ? moment(notesData.dateCreated).format(
                    "MM/DD/yyyy hh:mm a"
                  )
                  : "N/A"}{" "}
                {notesData?.createdBy?.username && `by ${notesData.createdBy.username}`}
              </li>
              <li>
                Date Modified:{" "}
                {notesData?.dateModified
                  ? moment(notesData.dateModified).format(
                    "MM/DD/yyyy hh:mm a"
                  )
                  : "N/A"}{" "}
                {notesData?.lastModifiedBy?.username &&
                  `by ${notesData.lastModifiedBy.username}`}
              </li>
            </ul>
            <div className="bottom-0 right-[39px] -translate-x-1/2 translate-y-1/2 absolute lg:bottom-8 lg:right-0 rotate-[135deg] transform lg:translate-x-1/2 lg:-translate-y-1/2 lg:rotate-45 w-4 h-4 bg-white border-r border-t border-[#4185EE] dark:bg-primary-dark"></div>
          </div>
        </div>

        {/* Display Date */}
        <h2 className="text-[#888888] dark:text-primary-light font-medium text-sm leading-6 lg:mt-0 lg:text-end">
          {notesData?.datePublished
            ? moment(notesData.datePublished).format("MM/DD/yyyy hh:mm a")
            : "N/A"}
        </h2>
      </span>
    </div>
  );
};

export default DateDetails;
