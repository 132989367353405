
import { MnaDomainApi } from "../../../openapi";
import { apiConfig } from "../../ConfigurationApi/Configuration";

interface BrokerClosingDate {
  dateEvent: string | null;
  comment: string;
  mnaId: string;
  isNew?: boolean;
}

interface PatchValue {
  add: BrokerClosingDate[];
}

interface JsonPatch {
  op: string;
  path: string;
  value: PatchValue;
}

export const addBrokerClosingDateLocally = (
  fieldName: string,
  id: string,
  setData: React.Dispatch<React.SetStateAction<Record<string, BrokerClosingDate[]>>>
) => {
  setData((prevData) => ({
    ...prevData,
    [fieldName]: [
      ...prevData[fieldName],
      { dateEvent: null, comment: "", mnaId: id, isNew: true },
    ],
  }));
};

export const editClosingDateLocally = (
  fieldName: string,
  index: number,
  updatedValue: string,
  setData: React.Dispatch<React.SetStateAction<Record<string, BrokerClosingDate[]>>>
) => {
  setData((prevData) => {
    const updatedField = [...prevData[fieldName]];
    updatedField[index] = {
      ...updatedField[index],
      dateEvent: updatedValue,
    };

    return {
      ...prevData,
      [fieldName]: updatedField,
    };
  });
};

export const sendUpdatedDataToServer = async (
  fieldName: string,
  data: Record<string, BrokerClosingDate[]>,
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setData: React.Dispatch<React.SetStateAction<Record<string, BrokerClosingDate[]>>>,
  dateEventValue: string
) => {
  const newEntries = data[fieldName].filter((item) => item.isNew);

  if (newEntries.length === 0) {
    return;
  }

  const updatedData: BrokerClosingDate[] = newEntries.map((entry) => ({
    version: 0,
    dateEventTypeId: 3,
    dateEvent: dateEventValue,
    comment: entry.comment,
    mnaId: id,
  }));

  const patchValue: PatchValue = {
    add: updatedData,
  };

  const jsonPatch: JsonPatch[] = [
    {
      op: "replace",
      path: `/${fieldName}`,
      value: patchValue,
    },
  ];

  try {
    const api = new MnaDomainApi(apiConfig());
    if (id) {
      await api.partiallyUpdateMna(String(id), jsonPatch);
      setData((prevData) => {
        const updatedField = prevData[fieldName].map((item) => ({
          ...item,
          isNew: false,
        }));

        return {
          ...prevData,
          [fieldName]: updatedField,
        };
      });
    }
  }catch(error){
    console.log(error)
  }
};