import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faHandshakeSimple, faLineChart} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const MnaIcon = () => {
    return (
        <div
            className={"h-[20px] w-[20px] flex items-center justify-center rounded-full bg-[#0BBC9C]"}>
            <FontAwesomeIcon icon={faHandshakeSimple}
                             className={"text-white text-sm"}/>
        </div>
    )
}

export const FundamentalIcon = () => {
    return (
        <div
            className={"h-[20px] w-[20px] flex items-center justify-center rounded-full bg-[#00008B]"}>
            <FontAwesomeIcon icon={faLineChart}
                             className={"text-white text-sm"}/>
        </div>
    )
}

export const NotesIcon = () => {
    return (
        <div
            className={"h-[20px] w-[20px] flex items-center justify-center rounded-full bg-[#FED800]"}>
            <FontAwesomeIcon icon={faCopy} className={"text-white text-sm"}/>
        </div>
    )
}