import { useEffect, useState } from "react";
import { ConditionCategoryDomainApi, Configuration } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";



export const useConditionCategories = () => {
    const [conditionCategories, setConditionCategories] = useState<any[]>([]);
    const [loadingConditions, setLoadingConditions] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_API_URL,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    });

    const fetchConditionCategories = async () => {
        setLoadingConditions(true);
        setError(null);

        try {
            const conditionCategoryApi = new ConditionCategoryDomainApi(configuration);
            const response = await conditionCategoryApi.getConditionCategories("", 0, undefined);
            const categories = response.data.content?.map((item: any) => ({
                id: item.id,
                name: item.name,
            }));

            setConditionCategories(categories || []);
        } catch (err: any) {
            console.error("Error fetching Condition Categories:", err);
            expiredTokenValidation(err);
            setError("Failed to load Condition Categories");
        } finally {
            setLoadingConditions(false);
        }
    };

    useEffect(() => {
        fetchConditionCategories();
    }, []);

    return {
        conditionCategories,
        loadingConditions,
        error,
        refetch: fetchConditionCategories,
    };
};
