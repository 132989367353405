export const convertPictureToDiv = (htmlContent: string) => {
  const pictureRegex = /<picture>(.*?)<\/picture>/g;

  return htmlContent.replace(pictureRegex, (match, content) => {
    const sourceTags = Array.from(
      content.matchAll(/<source[^>]*srcset="([^"]*)"[^>]*>/g)
    );
    const imgTagMatch = content.match(/<img[^>]*src="([^"]*)"[^>]*>/);

    const srcSetArray = sourceTags
      .map((tag: any) => tag[1].replace(/&amp;/g, "&"))
      .filter((srcSet) => srcSet);

    const fallbackImage = imgTagMatch
      ? imgTagMatch[1].replace(/&amp;/g, "&")
      : "";

    const srcSetString = srcSetArray.join(", ");
    if (!srcSetString && !fallbackImage) {
      return "";
    }

    return `
      <div class="image-container">
        <img
          src="${fallbackImage}"
          sizes="(min-width: 600px) 600px, 100vw"
          alt="Description of the image"
          style="border: 1px solid rgb(15, 15, 15); height: auto; max-width: 100%; width: auto;"
        />
      </div>
    `;
  });
};
