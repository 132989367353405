
const DomainList = ({ domains }: { domains: any[] }) => (
  <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
    {domains?.map((domain: any, index: number) => (
      <h2 key={index} className="text-md lg:text-lg text-primary-dark dark:text-primary-light font-bold">
        {domain?.domainName === 'Fundamental' || domain?.domainName === 'MNA' ? (
          <a
            href={domain?.domainName === 'Fundamental' ? `/fundamental/${domain?.id}` : `/mna/${domain?.id}`}
            className="text-[#353535]"
          >
            {domain.name}
          </a>
        ) : (
          <button type="button" className="text-[#353535] underline cursor-default">
            {domain.name}
          </button>
        )}
        {`${index !== domains.length - 1 ? ',' : ''}`}&nbsp;
      </h2>
    ))}
  </div>
);

export default DomainList;
