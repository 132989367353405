import React from 'react';
import {Mna} from "../../../interfaces/pages/variedInterfaces";
import {Link} from "react-router-dom";
import moment from "moment/moment";

interface SecondListGroupProps{
    data: any
}

const SecondListGroup: React.FC<SecondListGroupProps> = ({data}) =>  {
    return (
        <div className="mt-6">
            <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="table-container">
                        <table className="min-w-full dark:bg-table-dark bg-table-light">
                            <caption
                                className="text-left heading-bold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                                MNAs - Recently Modified
                            </caption>
                            <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                                >
                                    Mna Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                                >
                                    Category
                                </th>
                                <th
                                    scope="col"
                                    className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                                >
                                    Date Modified
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-[#DCDBEE] align-top">
                            {data.mnas?.map((mna: Mna, index: number) => (
                                <tr key={index}>
                                    <td className="px-[30px] py-5 text-regular-bold cursor-pointer break-words">
                                        <Link
                                            className="text-secondary"
                                            to={`/mna/${mna?.id}`}
                                        >
                                            {mna?.name}
                                        </Link>
                                    </td>
                                    <td className="px-[30px] py-5 text-regular break-words">
                                        {mna.categoryName}
                                    </td>
                                    <td className="px-[30px] py-5 text-regular break-words">
                                        {moment(mna.dateModified).format(
                                            "MM/DD/YYYY hh:mm A"
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondListGroup;
