import React from "react";

const Management = () => {
  return (
    <div className="table-container dark:bg-table-dark bg-table-light">
      <table className="min-w-full">
        <caption className="text-left heading-bold text-title pt-[16px] pb-2 px-3.5">
          Management
        </caption>
        <tbody>
          <tr>
            <td className="pl-4 pr-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-4 pb-2 text-[#D70000] text-sm font-medium text-center">
              Vanguard
            </td>
            <td className="pl-4 pr-4 pb-2 text-[#007B1B] text-sm font-medium text-right">
              ATVI
            </td>
          </tr>
          <tr>
            <td className="pl-4 pr-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              8.30%
            </td>
            <td className="px-4 pb-2 text-[#D70000] text-sm font-medium text-center">
              Vanguard
            </td>
            <td className="pl-4 pr-4 pb-2 text-[#007B1B] text-sm font-medium text-right">
              ATVI
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Management;
